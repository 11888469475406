import IconButton from "@mui/material/IconButton";
import MuiSnackbar from "@mui/material/Snackbar";
import { useCallback } from "react";
import { useTheme } from "styled-components";

import CloseIcon from "~/assets/icons/x.svg";

const Snackbar: React.FC<{
  open: boolean;
  onClose(): void;
  bottomOffset?: number;
  variant?: "error" | "info";
  css?: JSX.IntrinsicAttributes["css"];
}> = ({
  open,
  onClose,
  bottomOffset,
  variant,
  children,
  css: cssOverrides,
}) => {
  const theme = useTheme();

  const handleClose = useCallback(
    (_, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      onClose();
    },
    [onClose]
  );

  return (
    <MuiSnackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      css={`
        ${bottomOffset ? ` bottom: ${bottomOffset}px !important; ` : ""}
        ${cssOverrides}
      `}
    >
      <div
        css={{
          borderRadius: 4,
          backgroundColor: {
            error: theme.colors.sourWatermelon,
            info: theme.colors.speech,
          }[variant || "error"],
          color: theme.colors.white,
          padding: "0.8rem 1rem",
          display: "flex",
          fontSize: "1rem",
          lineHeight: "1.5rem",
          fontWeight: theme.weights.regular,
        }}
      >
        <span css={{ paddingRight: "1rem" }}>{children}</span>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          css={`
            height: 1.5rem;
          `}
        >
          <CloseIcon css={{ width: "1rem" }} />
        </IconButton>
      </div>
    </MuiSnackbar>
  );
};

export default Snackbar;
