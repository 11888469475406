import { useQuery as useQueryBase } from "@apollo/client";
import type { OperationVariables } from "@apollo/client/core";
import type {
  QueryHookOptions,
  QueryResult,
} from "@apollo/client/react/types/types";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type { DocumentNode } from "graphql";

/*
 * This is a wrapper around useQuery that makes "ssr" false by default.
 * https://github.com/apollographql/apollo-feature-requests/issues/175
 */
export const useQuery = <TData = unknown, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  { ssr, ...options }: QueryHookOptions<TData, TVariables> = {}
): QueryResult<TData, TVariables> =>
  useQueryBase<TData, TVariables>(query, {
    ssr: typeof ssr === "undefined" ? false : ssr,
    ...options,
  });
