import { noop, pick } from "lodash";
import { usePostHogContext } from "posthog-js/react";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useLocalCohortId } from "~/hooks/useLocalCohortId";

import { useUserContext } from "../UserContext";

type IAnalyticsCtx = {
  identify(id?: string, payload?: Record<string, unknown>): void;
  page(
    id?: string,
    payload?: Record<string, unknown>,
    options?: Record<string, unknown>
  ): void;
  track(
    id?: string,
    payload?: Record<string, unknown>,
    options?: Record<string, unknown>
  ): void;
  group(
    id?: string,
    payload?: Record<string, unknown>,
    options?: Record<string, unknown>
  ): void;
};

const AnalyticsContext = createContext<IAnalyticsCtx>({
  identify: noop,
  page: noop,
  track: noop,
  group: noop,
});

type AnalyticsCall = {
  method: keyof IAnalyticsCtx;
  args: [string | undefined, Record<string, unknown> | undefined];
};

let callQueue: Array<AnalyticsCall> = [];
let lastGroup = ""; // Note: "group" in this case refers to a Segment Group (corresponding to a Murmur Workspace), not a Murmur Group

export const AnalyticsProvider: React.FC<{
  apiKey: string;
  children: ReactNode;
}> = ({ apiKey, children }) => {
  const [loadedScript, setLoadedScript] = useState<boolean>(false);
  const [ctx, setCtx] = useState<IAnalyticsCtx>({
    identify: useCallback((...args: AnalyticsCall["args"]) => {
      callQueue.push({ method: "identify", args });
    }, []),
    page: useCallback((...args: AnalyticsCall["args"]) => {
      callQueue.push({ method: "page", args });
    }, []),
    track: useCallback((...args: AnalyticsCall["args"]) => {
      callQueue.push({ method: "track", args });
    }, []),
    group: useCallback((...args: AnalyticsCall["args"]) => {
      callQueue.push({ method: "group", args });
    }, []),
  });

  const { user } = useUserContext();
  const localCohortId = useLocalCohortId();

  const { client: posthog } = usePostHogContext();

  const getCohortId = useCallback(
    (workspaceId?: string) => {
      const memberWorkspace = user.workspaces?.find(
        ({ _id }) => _id === (workspaceId || lastGroup)
      );

      if (memberWorkspace) {
        return memberWorkspace.cohortId;
      }

      const guestWorkspace = user.guestOfWorkspaces?.find(
        ({ _id }) => _id === (workspaceId || lastGroup)
      );

      if (guestWorkspace) {
        return guestWorkspace.cohortId;
      }

      return localCohortId || workspaceId;
    },
    [user, localCohortId]
  );

  const loadScript = useCallback(() => {
    if (loadedScript) {
      return;
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${apiKey}/analytics.min.js`;
    script.onload = async () => {
      const client = await new Promise<IAnalyticsCtx>((resolve) => {
        const maybeResolveClient = () => {
          const client = ((window as unknown) as { analytics: IAnalyticsCtx })
            .analytics;
          if (client) {
            resolve(client);
          } else {
            setTimeout(maybeResolveClient, 100);
          }
        };
        maybeResolveClient();
      });

      client.identify(user._id, pick(user, "email", "displayName"));
      posthog?.identify(user._id, pick(user, "email", "displayName"));

      callQueue.forEach((call) => {
        client[call.method](...call.args);
        if (call.method === "group") {
          lastGroup = call.args[0] || lastGroup;
        }
      });
      callQueue = [];

      setCtx({
        identify(id: string, payload?: Record<string, unknown>) {
          const cohortId = getCohortId();
          client.identify(id, { cohortId, ...(payload || {}) });
        },
        page(id: string, payload?: Record<string, unknown>) {
          const cohortId = getCohortId();
          const context = { groupId: lastGroup };
          client.page(
            id,
            { workspaceId: lastGroup, cohortId, ...payload },
            { context }
          );
        },
        track(id: string, payload?: Record<string, unknown>) {
          const cohortId = getCohortId();
          const context = { groupId: lastGroup };
          client.track(
            id,
            { workspaceId: lastGroup, cohortId, ...payload },
            { context }
          );
        },
        group(id: string, payload?: Record<string, unknown>) {
          const cohortId = getCohortId(id);
          lastGroup = id;
          client.group(id, { cohortId, ...(payload || {}) });
        },
      });
    };
    // Insert next to the first script element.
    const first = document.getElementsByTagName("script")[0];
    first?.parentNode?.insertBefore(script, first);
    setLoadedScript(true);
  }, [apiKey, user, loadedScript, setLoadedScript, getCohortId, posthog]);

  useEffect(() => {
    if (apiKey) {
      loadScript();
    } else {
      console.warn("No API key, no Segment");
    }
  }, [loadScript, apiKey]);

  return (
    <AnalyticsContext.Provider value={ctx}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
