import styled from "styled-components";

export const Container = styled.main`
  background: transparent;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  text-align: center;
  place-items: center center;
`;
