import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import { fonts } from "./fonts";

export const breakpoints = {
  xs: {
    min: "550px",
    max: "700px",
  },
  sm: {
    min: "701px",
    max: "700px",
  },
  lg: {
    min: "701px",
    max: "700px",
  },
};

export type Breakpoint = keyof typeof breakpoints;

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  ${fonts};

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;

    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
      * {
        animation-duration: 0.001ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.001ms !important;
      }
    }
  }

  body {
    background: ${({ theme }) => theme.colors.moonLight};
    font-family: ${({ theme }) => theme.fontFamily.graphik};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body * {
    font-family: ${({ theme }) => theme.fontFamily.graphik};
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: ${({ theme }) => theme.weights.medium};
    &.semibold {
      font-weight: ${({ theme }) => theme.weights.semiBold};
    }
  }


  .ProseMirror ::selection {
    background-color: ${({ theme }) => `${theme.colors.speechLight}`};
   }

  .Canny_BadgeContainer .Canny_Badge {
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.speech};
    padding: 5px;
    border: 1px solid white;
  }
  
  #WEB3_CONNECT_MODAL_ID {
    z-index: 10000;
    position: relative;
  }
`;
