import { useTheme } from "styled-components";

export const SupportLink = () => {
  const supportLinkEmail = `mailto:support@murmur.com?subject=Requesting%20Murmur%20app%20support&body=Hey%20Murmur%20team!%0D%0A%0D%0AI%20need%20some%20help%20with%20the%20following%20problem%3A%0D%0A%0D%0A%3CINSERT%20YOUR%20PROBLEM%20HERE%3E%0D%0A%0D%0AThanks!`;
  const theme = useTheme();

  return (
    <a
      css={{
        backgroundColor: theme.colors.speech,
        borderRadius: 4,
        color: theme.colors.white,
        padding: ".75rem .5rem",
        textDecoration: "none",
      }}
      href={supportLinkEmail}
    >
      Request Help
    </a>
  );
};
