import { MotionConfig } from "framer-motion";
import { usePostHog } from "next-use-posthog";
import { AppProps } from "next/app";
import Head from "next/head";
import { FC, useEffect } from "react";
import { Globals as ReactSpringGlobals } from "react-spring";

import devFavicon from "~/assets/images/logo-orange.png?size=32";
import prodFavicon from "~/assets/images/logo.png?size=32";
import { MaintenanceMode } from "~/browser/maintenance/pages/MaintenanceMode";
import CannyContainer from "~/components/Canny";
import GlobalProviders from "~/components/GlobalProviders";
import { useReducedMotion } from "~/hooks/useReducedMotion";
import { GlobalStyle } from "~/styles/global";

const favicon = process.env.NEXT_PUBLIC_IS_PRODUCTION
  ? prodFavicon.src
  : devFavicon.src;

const MurmurApp: FC<AppProps> = ({
  Component,
  pageProps: { metaTags, ...pageProps },
}) => {
  const reduceMotion = useReducedMotion();

  useEffect(() => {
    ReactSpringGlobals.assign({ skipAnimation: reduceMotion });
  }, [reduceMotion]);

  usePostHog(process.env.NEXT_PUBLIC_APP_POSTHOG_WRITE_KEY, {
    api_host: "https://app.posthog.com",
    loaded: (posthog) => {
      if (
        process.env.NODE_ENV === "development" &&
        !process.env.NEXT_PUBLIC_TRACK_ENABLED
      ) {
        posthog.opt_out_capturing();
      }
    },
  });

  const isMaintenanceMode =
    process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "scheduled" ||
    process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "unscheduled";
  const forcePastMaintenanceMode =
    typeof window === "undefined"
      ? false
      : window.localStorage.getItem("forcePastMaintenanceMode") === "true";
  const showMaintenanceMode = isMaintenanceMode && !forcePastMaintenanceMode;

  return (
    <>
      <Head>
        <link rel="shortcut icon" href={`${favicon}`} />
        {metaTags &&
          Object.entries(metaTags as { [k: string]: string }).map((entry, k) =>
            entry[0] === "title" ? (
              <title>{entry[1]}</title>
            ) : (
              <meta key={k} property={entry[0]} content={entry[1]} />
            )
          )}
      </Head>

      <GlobalProviders>
        <GlobalStyle />
        <MotionConfig reducedMotion="user">
          {showMaintenanceMode ? (
            <MaintenanceMode />
          ) : (
            <Component {...pageProps} />
          )}
        </MotionConfig>
        <CannyContainer />
      </GlobalProviders>
    </>
  );
};

export default MurmurApp;
