// hex opacity converter: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
export const light = {
  colors: {
    badTomato: "#B53C00",
    blueLagoon: "#044F6C",
    blue30: "#6A6DCC",
    cinnabar: "#DF523D",
    cinnabarLight: "#fbece9",
    dodgerBlue: "#0A84FF",
    darkSilver: "#636161",
    dodgerBlue10Darker: "#006BE6",
    dodgerBlue20Darker: "#0051CC",
    gravel: "#544D55",
    honeydew: "#83F0B8",
    honeydewLight: "#C6EEDA",
    honeydewDark: "#00572A",
    linen: "#F9F2EB",
    malibu: "#ABD9F7",
    malibuLight: "#C7E7FC",
    malibuPorcelain: "#D2E8F6",
    marigoldYellow: "#FCE370",
    marigoldYellowMedium: "#FCEB9A",
    marigoldYellowLight: "#FDF1B7",
    marigoldYellowExtraLight: "#FDF6D3",
    midnight: "#030057",
    moon25: "#F2EEE859",
    moon: "#F2EEE8",
    moonMedium: "#E5E2D8",
    moonSemimedium: "#89867d",
    moonLight: "#FBF8F3",
    moonDark: "#787671",
    murpleEvenLighter: "#E6E6FC",
    murpleExtraLight: "#BBBDFA",
    murpleLight: "#9397FF",
    murple10Darker: "#2E33C5",
    oceanGreen: "#66CCA8",
    oceanGreenLight: "#CDEEE2",
    oil: "#211C12",
    oliveBrown: "#625306",
    peachy: "#FCAF70",
    peachyDark: "#FF6F4D",
    peachyExtraLight: "#F8E1CF",
    peachyLight: "#FDCBA2",
    periwinkle: "#83A0F0",
    periwinkleLight: "#B6CAFF",
    periwinkleDark: "#142682",
    pumpkin: "#E87C1F",
    rust: "#662C1F",
    saltBox: "#6A616B",
    rollingStone: "#7A7C7D",
    sinbad: "#9BDED6",
    sinbadLight: "#CDEEEA",
    sinbadExtraLight: "#E1F4F3",
    navy: "#1C1E59",
    silver: "#7D7A7A",
    speech: "#474CDE",
    speech10: "#474CDE1A",
    speech10Solid: "#EDEDFC",
    speech25: "#474CDE59",
    speech75: "#474CDEBF",
    speechLight: "#D1D2F7",
    speechPurple: "#A0A4EA",
    someOtherBlue: "#7E9AE6",
    sorbet: "#FCC981",
    sorbetLight: "#FDD8A4",
    sorbetPorcelain: "#FAE0BC",
    sourWatermelon: "#D52941",
    sunshine: "#FFEA82",
    sunshineLight: "#FFF0A8",
    sunshinePorcelain: "#FCF0BC",
    white: "#FFFFFF",
  },
  weights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  fontFamily: {
    graphik:
      '"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;',
  },
};
