import { useRouter } from "next/router";
import { useEffect } from "react";

import { autoAccessCohorts } from "~/shared/utils/autoAccessCohorts";

import useLocalStorage from "./useLocalStorage";

export const useLocalCohortId = () => {
  const { query } = useRouter();
  const cohortIdFromQuery = autoAccessCohorts[query.betaInvitation as string];
  const [cohortId, setCohortId] = useLocalStorage(
    "cohortId",
    cohortIdFromQuery || ""
  );

  useEffect(() => {
    if (cohortIdFromQuery) {
      setCohortId(cohortIdFromQuery);
    }
  }, [cohortIdFromQuery, setCohortId]);

  return cohortId;
};
