export const autoAccessCohorts: { [key: string]: string } = {
  "662607015": "auto_access",
  rum12mur: "auto_access",
  "1006": "oct_6",
  "1013": "oct_13",
  "10eng18": "engineering",
  "1020": "oct_20",
  "1027": "oct_27",
  "1103": "nov_3",
  "1110": "nov_10",
  "1117": "nov_17",
  ...(process.env.AUTO_ACCESS_TOKENS?.split(",")?.reduce(
    (acc, token) => ({ ...acc, [token]: token }),
    {}
  ) || {}),
};
