import { pick } from "lodash";

import type {
  NotificationType,
  UserWithSessionFragment,
} from "~/graphql/generated";
import type { WithoutNulls } from "~/utils/withoutNulls";

const normalizeWorkspaceInvite = (
  workspaceInvite: UserWithSessionFragment["workspaceInvitations"][number]
) => ({
  ...pick(workspaceInvite, ["_id", "workspace"]),
  workspaceGuest: workspaceInvite.workspaceGuest ?? false,
});

const normalizeProposalInvite = (
  proposalInvite: UserWithSessionFragment["proposalInvitations"][number]
) => ({
  ...pick(proposalInvite, ["linkId", "workspace"]),
  workspaceGuest: proposalInvite.workspaceGuest ?? false,
});

const normalizeNotificationPreferences = (
  notificationPreferences: WithoutNulls<
    Array<{
      type: NotificationType;
      enabled: boolean;
      workspace: {
        _id: string;
      };
    }>
  >
) => {
  return notificationPreferences.map(({ enabled, type, workspace }) => ({
    enabled,
    type,
    workspace,
  }));
};

export const normalizeUser = (input: WithoutNulls<UserWithSessionFragment>) =>
  ({
    email: input.email ?? "",
    displayName: input.displayName ?? "",
    _id: input._id,
    workspaces: input.workspaces ?? [],
    guestOfWorkspaces: input.guestOfWorkspaces ?? [],
    workspaceInvitations: input.workspaceInvitations.map(
      normalizeWorkspaceInvite
    ),
    proposalInvitations: input.proposalInvitations.map(normalizeProposalInvite),
    joined: new Date(input.joined),
    notificationPreferences: input.notificationPreferences
      ? normalizeNotificationPreferences(input.notificationPreferences)
      : [],
    preferences: input.preferences,
    featuresSeen: input.featuresSeen,
    avatar: input.avatar ?? "",
    sessionExpiresAt: input.sessionExpiresAt,
    walletId: input.walletId,
    checklists: input.checklists ?? [],
  } as const);

export type User = ReturnType<typeof normalizeUser>;
