import { useCallback, useEffect, useState } from "react";

type UpdaterFn<T> = (currentState: T) => T;

type Result<T> = [
  state: T,
  wrappedSetState: (update: T | UpdaterFn<T>) => void
];

export default function useLocalStorage<T>(
  key: string,
  initialValue: T
): Result<T> {
  const [state, setState] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);

      return initialValue;
    }
  });

  const wrappedSetState = useCallback(
    (update) => {
      try {
        setState((currentState) => {
          const nextState =
            typeof update === "function" ? update(currentState) : update;

          window.localStorage.setItem(key, JSON.stringify(nextState));
          return nextState;
        });
      } catch (error) {
        console.error(error);
      }
    },
    [key]
  );

  const onStorageEvent = useCallback(
    (e: StorageEvent) => {
      if (e.key === key && e.newValue != e.oldValue) {
        let nextState = null;
        try {
          nextState = JSON.parse(e.newValue || "null");
        } catch (err) {
          console.error("Error parsing value from localStorage event", err);
        }
        setState(nextState);
      }
    },
    [key]
  );

  useEffect(() => {
    window.addEventListener("storage", onStorageEvent);
    return () => window.removeEventListener("storage", onStorageEvent);
  }, [onStorageEvent]);

  return [state, wrappedSetState];
}
