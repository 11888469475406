import { FC, memo } from "react";
import React from "react";

import LoadingIndicator from "~/components/LoadingIndicator";

import { Container } from "./styles";

const WaitRoom: FC = () => {
  return (
    <Container>
      <LoadingIndicator />
    </Container>
  );
};

export default memo(WaitRoom);
