import { createTheme } from "@mui/material";

import "styled-components";
import { dark } from "./dark";
import { light } from "./light";

export const themes = {
  dark,
  light,
};

export type Theme = typeof light;

declare module "styled-components" {
  // We need this to be an interface - a type will error.
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

const typography = {
  fontFamily: light.fontFamily.graphik,
  fontSize: 16,
  fontWeightLight: light.weights.regular,
  fontWeightRegular: light.weights.regular,
  fontWeightMedium: light.weights.medium,
};

// v5 overrides
const components = {};

export const mui5Theme = createTheme({
  typography,
  components,
});
