import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import { USER_WITH_SESSION_FRAGMENT } from "~/graphql/fragments/user";
import type {
  GetAuthenticatedUserQuery,
  GetAuthenticatedUserQueryVariables,
} from "~/graphql/generated";

export const GET_AUTHENTICATED_USER: TypedDocumentNode<
  GetAuthenticatedUserQuery,
  GetAuthenticatedUserQueryVariables
> = gql`
  query GetAuthenticatedUser {
    authenticatedUser {
      ...UserWithSession
    }
  }

  ${USER_WITH_SESSION_FRAGMENT}
`;
