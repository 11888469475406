import { QueryHookOptions, useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";

import type {
  GetAuthenticatedUserQuery,
  UpdateUserInput,
} from "~/graphql/generated";
import { UPDATE_AUTHENTICATED_USER } from "~/graphql/mutations/updateAuthenticatedUser";
import { GET_AUTHENTICATED_USER } from "~/graphql/queries/user/getAuthenticatedUser";
import { useQuery } from "~/hooks/useQuery";
import { normalizeUser } from "~/model/user";
import { withoutNulls } from "~/utils/withoutNulls";

export const useAuthenticatedUser = ({
  fetchPolicy = "cache-and-network",
  ...options
}: QueryHookOptions = {}) => {
  const {
    loading,
    data: raw,
    error,
    refetch,
  } = useQuery<GetAuthenticatedUserQuery>(GET_AUTHENTICATED_USER, {
    ...options,
    fetchPolicy,
  });

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.authenticatedUser);
    return data && normalizeUser(data);
  }, [raw]);

  return {
    loading,
    data: normalized,
    error,
    refetch,
  };
};

export const useAuthenticatedUserActions = () => {
  const [performUpdate, loading] = useMutation(UPDATE_AUTHENTICATED_USER);

  const update = useCallback(
    async (input: UpdateUserInput) => {
      const { data } = await performUpdate({
        variables: input,
      });

      return data?.updateAuthenticatedUser;
    },
    [performUpdate]
  );

  return { update, loading };
};
