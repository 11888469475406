export type WithoutNulls<T> = T extends Record<
  string | number | symbol,
  unknown
>
  ? {
      [TKey in keyof T]: WithoutNulls<T[TKey]>;
    }
  : T extends null
  ? undefined
  : T;

/**
 * Transforms all null values to undefined in an object or primitive type.
 */
export function withoutNulls<T>(input: T): WithoutNulls<T> {
  if (input === null) {
    return (undefined as unknown) as WithoutNulls<T>;
  }

  if (typeof input === "object") {
    if (Array.isArray(input)) {
      return (input.map(withoutNulls) as unknown) as WithoutNulls<T>;
    }

    return Object.entries(input).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: withoutNulls(value),
      }),
      {} as WithoutNulls<T>
    );
  }

  return (input as unknown) as WithoutNulls<T>;
}
