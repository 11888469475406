import type { FC } from "react";

const Emoji: FC<{
  label?: string;
  symbol: string;
  size?: number | string;
}> = ({ label = "", symbol, size = 29, ...rest }) => {
  return (
    <span
      contentEditable={false}
      css={{ fontSize: size }}
      role="img"
      aria-label={label}
      aria-hidden={label ? "false" : "true"}
      {...rest}
    >
      {symbol}
    </span>
  );
};

export default Emoji;
