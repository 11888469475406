import type { ApolloError } from "@apollo/client";

export function hasAuthenticationError({ error }: { error: ApolloError }) {
  return error.graphQLErrors.some(
    ({ extensions }) => extensions?.code === "UNAUTHENTICATED"
  );
}

export function hasAuthorizationError({ error }: { error: ApolloError }) {
  return error.graphQLErrors.some((err) => err.message.includes("Not allowed"));
}
