import { useEffect } from "react";

import { useUserContext } from "~/contexts/UserContext";

function loadCanny() {
  // from https://developers.canny.io/install
  const i = "canny-jssdk";
  const s = "script";
  if (!window) return;

  function l() {
    if (!document.getElementById(i)) {
      const f = document.getElementsByTagName(s)[0],
        e = document.createElement(s);
      (e.type = "text/javascript"),
        (e.async = !0),
        (e.src = "https://canny.io/sdk.js"),
        f.parentNode?.insertBefore(e, f);
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore 3rd party implementation
  if ("function" != typeof window.Canny) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore 3rd party implementation
    const c = function (...rest) {
      c.q.push(rest);
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore 3rd party implementation
    (c.q = []),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore 3rd party implementation
      (window.Canny = c),
      "complete" === document.readyState
        ? l()
        : window.attachEvent
        ? window.attachEvent("onload", l)
        : window.addEventListener("load", l, !1);
  }
}

const APP_ID = "607110cc4c231e2b2f389b8d";

const CannyContainer = () => {
  const { user } = useUserContext();

  useEffect(() => {
    loadCanny();
    if (user._id) {
      // ideally we'd only do this if the user's info hasn't been sent to Canny yet
      // but Canny has a mechanism that short circuits if this has happened recently that makes this safe.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore 3rd party implementation
      window.Canny("identify", {
        appID: APP_ID,
        user: {
          email: user.email,
          name: user.displayName ?? "",
          id: user._id,
          avatarURL: user.avatar,
          created: user.joined ? user.joined.toISOString() : undefined,
        },
      });
    }
  }, [user]);

  return null;
};

export default CannyContainer;
