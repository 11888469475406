import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: Date;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: Record<string, unknown>;
  /** Mongo object id scalar type */
  ObjectId: any;
  /** Governed objects */
  GovernedScalar: any;
  /** Participant Roles */
  ParticipantRoleScalar: any;
  /** Simple representation of group _id and name */
  GroupNameScalar: any;
};

export type Query = {
  __typename?: "Query";
  activities: Array<Activity>;
  agreements: Array<Agreement>;
  groupAgreements: Array<Agreement>;
  activeAgreementsCount: AgreementWithNeedsActionCount;
  publicAgreements: Array<Agreement>;
  agreement: Agreement;
  archivedAgreement: Agreement;
  archivedAgreements: Array<Agreement>;
  drafts: Array<Draft>;
  draftsCount: AgreementCount;
  draft: Draft;
  proposalEditsForAgreement: Array<ProposalEdit>;
  proposals: Array<Proposal>;
  groupProposals: Array<Proposal>;
  proposal: Proposal;
  proposalsCount: AgreementWithNeedsActionCount;
  templates: Array<Draft>;
  allTemplates: Array<Draft>;
  templatesCount: AgreementCount;
  template: Draft;
  getCheckIn: CheckIn;
  getDailyPrompts: Array<Prompt>;
  getPromptsFiltered: Array<Prompt>;
  getAllPrompts: Array<Prompt>;
  getPromptResponsesFeed: PromptResponsesPage;
  getPromptResponsesForUser: Array<PromptResponse>;
  getTodaysPromptResponseForUser?: Maybe<PromptResponse>;
  getSharedPromptResponses: Array<PromptResponse>;
  collections: Array<Collection>;
  allCollections: Array<Collection>;
  allCollectionsCount: CollectionCount;
  creatorProfiles: Array<CreatorProfile>;
  publicCreatorProfiles: Array<CreatorProfile>;
  feedback: FeedbackCycle;
  group: Group;
  userWorkspaceGroups: Array<Group>;
  workspaceGroups: Array<Group>;
  groupProposalAndAgreementCount: GroupAgreementCounts;
  interactionsByTypeAndProperty: Array<Interaction>;
  notifications: Array<Notification>;
  adminWorkspaces: Array<WorkspaceSummary>;
  allWorkspaceGroups: Array<Group>;
  oops: Group;
  previewWorkspacesMerge?: Maybe<WorkspaceMergeData>;
  linkInvitation: LinkInvitation;
  proposalLinkInvitation: ProposalLinkInvitationResponse;
  authenticatedUser?: Maybe<UserWithSession>;
  workspace: Workspace;
  workspaceInvitationLink: LinkInvitation;
};

export type QueryActivitiesArgs = {
  params: ActivityParams;
};

export type QueryAgreementsArgs = {
  params: AgreementsParams;
};

export type QueryGroupAgreementsArgs = {
  groupId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type QueryActiveAgreementsCountArgs = {
  params: AgreementsParams;
};

export type QueryPublicAgreementsArgs = {
  params?: Maybe<PublicAgreementsParams>;
};

export type QueryAgreementArgs = {
  params: AgreementParams;
};

export type QueryArchivedAgreementArgs = {
  params: ArchivedAgreementParams;
};

export type QueryArchivedAgreementsArgs = {
  params: AgreementsParams;
};

export type QueryDraftsArgs = {
  params: AgreementsParams;
};

export type QueryDraftsCountArgs = {
  params: AgreementsParams;
};

export type QueryDraftArgs = {
  params: DraftParams;
};

export type QueryProposalEditsForAgreementArgs = {
  params: ProposalEditsForAgreementParams;
};

export type QueryProposalsArgs = {
  params: AgreementsParams;
};

export type QueryGroupProposalsArgs = {
  groupId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type QueryProposalArgs = {
  params: AgreementParams;
};

export type QueryProposalsCountArgs = {
  params: AgreementsParams;
};

export type QueryTemplateArgs = {
  _id: Scalars["String"];
};

export type QueryGetCheckInArgs = {
  params: GetCheckInParams;
};

export type QueryGetDailyPromptsArgs = {
  params: GetDailyPromptsParams;
};

export type QueryGetPromptsFilteredArgs = {
  params: GetPromptsFilteredParams;
};

export type QueryGetPromptResponsesFeedArgs = {
  params: GetPromptResponsesFeedParams;
};

export type QueryGetPromptResponsesForUserArgs = {
  workspaceId: Scalars["String"];
};

export type QueryGetTodaysPromptResponseForUserArgs = {
  workspaceId: Scalars["String"];
};

export type QueryGetSharedPromptResponsesArgs = {
  workspaceId: Scalars["String"];
};

export type QueryFeedbackArgs = {
  params: FeedbackParams;
};

export type QueryGroupArgs = {
  params: GetGroupParams;
};

export type QueryUserWorkspaceGroupsArgs = {
  params: WorkspaceGroupsParams;
};

export type QueryWorkspaceGroupsArgs = {
  params: WorkspaceGroupsParams;
};

export type QueryGroupProposalAndAgreementCountArgs = {
  params: GetGroupProposalAndAgreementCountParams;
};

export type QueryInteractionsByTypeAndPropertyArgs = {
  params: InteractionsParams;
};

export type QueryNotificationsArgs = {
  params: NotificationsParams;
};

export type QueryAllWorkspaceGroupsArgs = {
  params: WorkspaceGroupsParams;
};

export type QueryPreviewWorkspacesMergeArgs = {
  params: PreviewWorkspacesMergeParams;
};

export type QueryLinkInvitationArgs = {
  params: GetLinkInvitationParams;
};

export type QueryProposalLinkInvitationArgs = {
  params: GetProposalLinkInvitationParams;
};

export type QueryWorkspaceArgs = {
  params: WorkspaceParams;
};

export type QueryWorkspaceInvitationLinkArgs = {
  params: WorkspaceInvitationLinkParams;
};

export type Activity = AgreementActivity | ProposalActivity;

export type AgreementActivity = {
  __typename?: "AgreementActivity";
  /** What kind of activity is this */
  type: ActivityType;
  /** When does the activity complete */
  endDate: Scalars["DateTime"];
  /** What agreement is associated with this activity */
  agreement: Agreement;
};

/** Types of activities for the dashboard */
export enum ActivityType {
  FeedbackRequest = "FeedbackRequest",
  AskQuestions = "AskQuestions",
  AnswerQuestions = "AnswerQuestions",
  SuggestImprovements = "SuggestImprovements",
  Improve = "Improve",
  Decide = "Decide",
  MakeItWork = "MakeItWork",
  ResolveObjections = "ResolveObjections",
  AcknowledgeRequest = "AcknowledgeRequest",
}

export type Agreement = {
  __typename?: "Agreement";
  _id: Scalars["ID"];
  proposer: User;
  workspace: Workspace;
  status: Status;
  /** Whether this agreement is a template */
  template?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  workingDocument: DocumentBase;
  visibility: Visibility;
  /** Whether this agreement shows up on Explore page and can (eventually) be indexed by search engines */
  discoverable?: Maybe<Scalars["Boolean"]>;
  /** An array of ancestors, starting with the oldest, and ending with the most recently duplicated agreement */
  provenance?: Maybe<Array<Scalars["ID"]>>;
  /** The duration for an agreement to be active */
  expirationDuration: ExpirationDuration;
  /** Frequency (in seconds) that feedback should be requested */
  feedbackFrequency: FeedbackFrequency;
  /** A category for agreement type, out of a predefined number of types. Undefined by default, and a user can set to 'Uncategorized' if they want to unset the type */
  agreementType?: Maybe<AgreementType>;
  agreementProperties?: Maybe<AgreementProperties>;
  events: Array<AgreementEvent>;
  /** All notes sent with notifications during the proposal process */
  notesForProposalProcess?: Maybe<NotesForProposalProcess>;
  version: Scalars["ID"];
  parent?: Maybe<Agreement>;
  activeVersion?: Maybe<Scalars["ID"]>;
  proposalVersion?: Maybe<Scalars["ID"]>;
  archivedVersion?: Maybe<Scalars["ID"]>;
  smartEvents?: Maybe<Array<SmartEvent>>;
  discoveryMetadata?: Maybe<DiscoveryMetadata>;
  acknowledgers: Array<Acknowledger>;
  round: Round;
  /** Date for when agreement expires. */
  expirationDate: Scalars["DateTime"];
  participants: Array<Participant>;
  activePlayer: PlayerTurn;
  interactions?: Maybe<Array<Interaction>>;
  documents?: Maybe<Array<Document>>;
  /** The timestamp of when the next action should happen to an agreement. */
  nextAdvancement: Scalars["DateTime"];
  /** The timestamp for when the next feedback cycle starts. */
  nextFeedbackCycle: Scalars["DateTime"];
  /** What finalize cycle a proposal is currently in (starting at 1). Returns null if it's not in finalize round. */
  finalizeCycle?: Maybe<Scalars["Float"]>;
  /** All of the collected feedback for the agreement while it's active. */
  feedback?: Maybe<Feedback>;
  context?: Maybe<Scalars["String"]>;
  governs: Governed;
  roles: Array<ParticipantRole>;
  seen: Scalars["Boolean"];
  needsFeedback: Scalars["Boolean"];
  needsAcknowledgment: Scalars["Boolean"];
  userParticipationRole?: Maybe<ParticipationRole>;
};

export type User = {
  __typename?: "User";
  _id: Scalars["ID"];
  email: Scalars["String"];
  joined: Scalars["DateTime"];
  walletId?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  lastActive: Scalars["DateTime"];
  workspaces: Array<Workspace>;
  guestOfWorkspaces: Array<Workspace>;
  avatar?: Maybe<Scalars["String"]>;
  notificationPreferences: Array<NotificationPreference>;
  preferences?: Maybe<UserPreferences>;
  featuresSeen?: Maybe<Array<Scalars["String"]>>;
  checklists?: Maybe<Array<Checklist>>;
  workspaceInvitations: Array<Invitation>;
  proposalInvitations: Array<ProposalInvitation>;
};

export type Workspace = {
  __typename?: "Workspace";
  _id: Scalars["ID"];
  name: Scalars["String"];
  orgType?: Maybe<OrgType>;
  logoFileId?: Maybe<Scalars["String"]>;
  cohortId?: Maybe<Scalars["String"]>;
  invitedEmails: Array<EmailInvite>;
  integrationsRequiringReinstall: Array<Scalars["String"]>;
  integrationsInstalled: Array<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  allMembersGroup: Group;
  billingAdmins: Array<User>;
  guests: Array<User>;
};

/** Types of organizations */
export enum OrgType {
  Startup = "Startup",
  Scaleup = "Scaleup",
  SmallBusiness = "SmallBusiness",
  Enterprise = "Enterprise",
  Consultancy = "Consultancy",
  Dao = "Dao",
  Cooperative = "Cooperative",
  Nonprofit = "Nonprofit",
  Other = "Other",
  Any = "Any",
}

export type EmailInvite = {
  __typename?: "EmailInvite";
  email: Scalars["String"];
  workspaceGuest: Scalars["Boolean"];
};

export type Group = {
  __typename?: "Group";
  _id: Scalars["ID"];
  name: Scalars["String"];
  icon?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  members: Array<Member>;
  visibility: GroupVisibility;
  type: GroupType;
  /** Preferences for where to send notifications feed for the group (e.g. to a discord channel) */
  notificationPreferences: Array<GroupNotificationPreference>;
  workspace: Workspace;
};

export type Member = {
  __typename?: "Member";
  _id: Scalars["ID"];
  permission: GroupRole;
  joined: Scalars["DateTime"];
  user: User;
};

export enum GroupRole {
  Admin = "Admin",
  Member = "Member",
  Guest = "Guest",
}

export enum GroupVisibility {
  System = "System",
  Workspace = "Workspace",
  MembersOnly = "MembersOnly",
}

export enum GroupType {
  WorkspaceMembership = "WorkspaceMembership",
  Standard = "Standard",
}

export type GroupNotificationPreference = {
  __typename?: "GroupNotificationPreference";
  type: GroupNotificationType;
  enabled: Scalars["Boolean"];
  /** The channel ID in the connected integration (for example, Discord channel ID) */
  channelId: Scalars["String"];
  /** The channel URL in the connected integration (for example, Discord channel ID) */
  channelUrl: Scalars["String"];
  groupId: Scalars["String"];
};

export enum GroupNotificationType {
  Discord = "discord",
  Slack = "slack",
}

export type NotificationPreference = {
  __typename?: "NotificationPreference";
  type: NotificationType;
  enabled: Scalars["Boolean"];
  /** Which of the user's Murmur workspaces is the notification preference for */
  workspace: Workspace;
  /** The corresponding User ID in the connected integration (for example, Slack user ID) */
  connectedUserId?: Maybe<Scalars["String"]>;
};

export enum NotificationType {
  Discord = "discord",
  Email = "email",
  Slack = "slack",
}

export type UserPreferences = {
  __typename?: "UserPreferences";
  lastUsedEmojiSkinTone?: Maybe<EmojiSkinTones>;
  dontShowWhatHappensWhenIProposeHelp?: Maybe<Scalars["Boolean"]>;
};

export enum EmojiSkinTones {
  Neutral = "Neutral",
  Light = "Light",
  MediumLight = "MediumLight",
  Medium = "Medium",
  MediumDark = "MediumDark",
  Dark = "Dark",
}

export type Checklist = {
  __typename?: "Checklist";
  workspaceId: Scalars["ID"];
  completedItems: Array<Scalars["String"]>;
};

export type Invitation = {
  __typename?: "Invitation";
  workspace: Workspace;
  added: Scalars["DateTime"];
  user: User;
  _id: Scalars["ID"];
  email?: Maybe<Scalars["String"]>;
  /** Indicates whether this invitation is to be a workspace guest (as opposed to a full member, or something else) */
  workspaceGuest: Scalars["Boolean"];
};

export type ProposalInvitation = {
  __typename?: "ProposalInvitation";
  workspace: Workspace;
  added: Scalars["DateTime"];
  user: User;
  _id: Scalars["ID"];
  email?: Maybe<Scalars["String"]>;
  /** Indicates whether this invitation is to be a workspace guest (as opposed to a full member, or something else) */
  workspaceGuest: Scalars["Boolean"];
  linkId: Scalars["String"];
  proposalVersion: Scalars["ID"];
  enabled: Scalars["Boolean"];
  role: ParticipationRole;
};

export enum ParticipationRole {
  Decider = "Decider",
  Advisor = "Advisor",
  Observer = "Observer",
  Proposer = "Proposer",
}

export enum Status {
  Draft = "Draft",
  Proposal = "Proposal",
  Discarded = "Discarded",
  Active = "Active",
  Archived = "Archived",
}

export type DocumentBase = {
  __typename?: "DocumentBase";
  title: Scalars["String"];
  body?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
};

export enum Visibility {
  Public = "Public",
  Organization = "Organization",
  Participants = "Participants",
}

export enum ExpirationDuration {
  TwoWeeks = "TwoWeeks",
  OneMonth = "OneMonth",
  OneQuarter = "OneQuarter",
  SixMonths = "SixMonths",
  OneYear = "OneYear",
}

export enum FeedbackFrequency {
  OneWeek = "OneWeek",
  TwoWeeks = "TwoWeeks",
  OneMonth = "OneMonth",
  OneQuarter = "OneQuarter",
  SixMonths = "SixMonths",
  OneYear = "OneYear",
}

export enum AgreementType {
  Foundational = "Foundational",
  Strategy = "Strategy",
  Process = "Process",
  Policy = "Policy",
  Norm = "Norm",
  Meeting = "Meeting",
  Decision = "Decision",
  Measure = "Measure",
  Role = "Role",
  Charter = "Charter",
  Uncategorized = "Uncategorized",
}

export type AgreementProperties = {
  __typename?: "AgreementProperties";
  predefined: PredefinedAgreementProperties;
};

export type PredefinedAgreementProperties = {
  __typename?: "PredefinedAgreementProperties";
  /** A category for agreement type, out of a predefined number of types. Undefined by default, and a user can set to 'Uncategorized' if they want to unset the type */
  agreementType?: Maybe<AgreementType>;
  /** The duration for an agreement to be active */
  expirationDuration: ExpirationDuration;
  /** Frequency (in seconds) that feedback should be requested */
  feedbackFrequency: FeedbackFrequency;
};

export type AgreementEvent = {
  __typename?: "AgreementEvent";
  event: Scalars["String"];
  timestamp: Scalars["DateTime"];
  data?: Maybe<Scalars["JSON"]>;
};

export type NotesForProposalProcess = {
  __typename?: "NotesForProposalProcess";
  /** Note sent when going from draft to Understand round */
  understand?: Maybe<ProposalNote>;
  /** Note sent when going from Understand to Improve round */
  improve?: Maybe<ProposalNote>;
  finalize?: Maybe<Array<ProposalNoteWithFinalizeCycle>>;
};

export type ProposalNote = {
  __typename?: "ProposalNote";
  content: Scalars["String"];
};

export type ProposalNoteWithFinalizeCycle = {
  __typename?: "ProposalNoteWithFinalizeCycle";
  content: Scalars["String"];
  finalizeCycle: Scalars["Float"];
};

export type SmartEvent = {
  __typename?: "SmartEvent";
  eventAction: SmartEventAction;
  trigger: SmartEventTrigger;
};

export enum SmartEventAction {
  Archive = "Archive",
  ExtendExpiration = "ExtendExpiration",
}

export enum SmartEventTrigger {
  ProposalMachine = "ProposalMachine",
}

export type DiscoveryMetadata = {
  __typename?: "DiscoveryMetadata";
  /** A list of entities that work well with this one */
  worksWellWith?: Maybe<Array<Scalars["ID"]>>;
  /** A Slate document description of the template */
  description?: Maybe<Scalars["String"]>;
  /** The plain text of the Slate description */
  descriptionText?: Maybe<Scalars["String"]>;
  /** A Slate document describing the audience for the template */
  audience?: Maybe<Scalars["String"]>;
  /** The plain text of the Slate audience */
  audienceText?: Maybe<Scalars["String"]>;
  /** The type of organization that this template is for */
  orgType: Array<OrgType>;
  /** The type of team that this template is for */
  teamType: Array<TeamType>;
  creatorProfileId?: Maybe<Scalars["ID"]>;
  creatorProfile?: Maybe<CreatorProfile>;
  worksWellWithTemplates?: Maybe<Array<Draft>>;
};

export enum TeamType {
  Customer = "Customer",
  Design = "Design",
  Engineering = "Engineering",
  Finance = "Finance",
  FounderCeo = "FounderCeo",
  Marketing = "Marketing",
  Operations = "Operations",
  People = "People",
  Product = "Product",
  RnD = "RnD",
  Sales = "Sales",
  Other = "Other",
  Any = "Any",
}

export type CreatorProfile = {
  __typename?: "CreatorProfile";
  _id: Scalars["ID"];
  name: Scalars["String"];
  bio: Scalars["String"];
  logoImageFileId?: Maybe<Scalars["String"]>;
  createdByUserId: Scalars["String"];
  twitterProfileURL?: Maybe<Scalars["String"]>;
  linkedInProfileURL?: Maybe<Scalars["String"]>;
  facebookProfileURL?: Maybe<Scalars["String"]>;
  instagramProfileURL?: Maybe<Scalars["String"]>;
  websiteURL?: Maybe<Scalars["String"]>;
  public?: Maybe<Scalars["Boolean"]>;
};

export type Draft = {
  __typename?: "Draft";
  _id: Scalars["ID"];
  proposer: User;
  workspace: Workspace;
  status: Status;
  /** Whether this agreement is a template */
  template?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  workingDocument: DocumentBase;
  visibility: Visibility;
  /** Whether this agreement shows up on Explore page and can (eventually) be indexed by search engines */
  discoverable?: Maybe<Scalars["Boolean"]>;
  /** An array of ancestors, starting with the oldest, and ending with the most recently duplicated agreement */
  provenance?: Maybe<Array<Scalars["ID"]>>;
  /** The duration for an agreement to be active */
  expirationDuration: ExpirationDuration;
  /** Frequency (in seconds) that feedback should be requested */
  feedbackFrequency: FeedbackFrequency;
  /** A category for agreement type, out of a predefined number of types. Undefined by default, and a user can set to 'Uncategorized' if they want to unset the type */
  agreementType?: Maybe<AgreementType>;
  agreementProperties?: Maybe<AgreementProperties>;
  events: Array<AgreementEvent>;
  /** All notes sent with notifications during the proposal process */
  notesForProposalProcess?: Maybe<NotesForProposalProcess>;
  version: Scalars["ID"];
  parent?: Maybe<Agreement>;
  activeVersion?: Maybe<Scalars["ID"]>;
  proposalVersion?: Maybe<Scalars["ID"]>;
  archivedVersion?: Maybe<Scalars["ID"]>;
  smartEvents?: Maybe<Array<SmartEvent>>;
  discoveryMetadata?: Maybe<DiscoveryMetadata>;
  visibleTo?: Maybe<VisibleTo>;
  /** The plain text of the document as seen by the proposer */
  proposerText?: Maybe<DocumentBase>;
  context?: Maybe<Scalars["String"]>;
  otherUsersDraftingFromParent?: Maybe<Array<SafeUser>>;
  draftURL: Scalars["String"];
  draftVisibleToWorkspace: Scalars["Boolean"];
};

export type VisibleTo = {
  __typename?: "VisibleTo";
  workspaces?: Maybe<Array<Scalars["ObjectId"]>>;
};

export type SafeUser = {
  __typename?: "SafeUser";
  _id: Scalars["ID"];
  displayName?: Maybe<Scalars["String"]>;
  avatar?: Maybe<Scalars["String"]>;
};

export type Acknowledger = {
  __typename?: "Acknowledger";
  userId: Scalars["ID"];
  user: SafeUser;
  /** Acknowledged if the date is set. */
  date?: Maybe<Scalars["DateTime"]>;
};

export enum Round {
  Understand = "Understand",
  Improve = "Improve",
  Finalize = "Finalize",
}

export type Participant = {
  __typename?: "Participant";
  userId: Scalars["ID"];
  user: SafeUser;
  role?: Maybe<ParticipationRole>;
  status?: Maybe<ParticipantStatus>;
  roundStatus?: Maybe<ParticipantRoundStatus>;
  reaction?: Maybe<Scalars["Int"]>;
  decision?: Maybe<Decision>;
  acknowledged?: Maybe<Acknowledged>;
  onBehalfOf: Governed;
};

export enum ParticipantStatus {
  Unseen = "Unseen",
  Seen = "Seen",
  Finished = "Finished",
}

export type ParticipantRoundStatus = {
  __typename?: "ParticipantRoundStatus";
  understand?: Maybe<ParticipantStatus>;
  improve?: Maybe<ParticipantStatus>;
  finalize?: Maybe<ParticipantStatus>;
};

export enum Decision {
  Consents = "Consents",
  Objects = "Objects",
  Reviewing = "Reviewing",
}

export type Acknowledged = {
  __typename?: "Acknowledged";
  /** Acknowledged if the date is set. */
  date?: Maybe<Scalars["DateTime"]>;
};

export type Governed = {
  __typename?: "Governed";
  users: Array<SafeUser>;
  groups: Array<SafeGroup>;
};

export type SafeGroup = {
  __typename?: "SafeGroup";
  _id: Scalars["ID"];
  name: Scalars["String"];
  icon?: Maybe<Scalars["String"]>;
  type: GroupType;
  description?: Maybe<Scalars["String"]>;
  members?: Maybe<Array<Member>>;
};

export enum PlayerTurn {
  Participant = "Participant",
  Proposer = "Proposer",
}

export type Interaction = {
  __typename?: "Interaction";
  _id: Scalars["ID"];
  /** The type of interaction. Interaction types are generally associated with specific rounds. */
  type: InteractionType;
  /** When the interaction was created. */
  createdAt: Scalars["DateTime"];
  /** When the interaction was last updated. */
  updatedAt: Scalars["DateTime"];
  /** Content of the interaction */
  content: Scalars["String"];
  /** The property that this interaction is for */
  propertyFor?: Maybe<Scalars["String"]>;
  /** Range of Slate document that interaction is correlated with */
  range?: Maybe<Range>;
  /** user who submitted the interaction. */
  user: SafeUser;
  reactions?: Maybe<Array<Reaction>>;
  /** Response from proposer to interaction this this interaction. */
  response?: Maybe<InteractionResponse>;
  /** ID of the proposal this interaction is associated with. */
  proposalId?: Maybe<Scalars["String"]>;
  /** If objection, the objection loop that it took place in. */
  finalizeCycle?: Maybe<Scalars["Float"]>;
  /** Whether the proposer acknowledged the interaction */
  acknowledgedByProposer?: Maybe<Scalars["Boolean"]>;
};

export enum InteractionType {
  Question = "Question",
  Suggestion = "Suggestion",
  Objection = "Objection",
  ObjectionResponse = "ObjectionResponse",
  Reaction = "Reaction",
}

export type Range = {
  __typename?: "Range";
  anchor: Point;
  focus: Point;
};

export type Point = {
  __typename?: "Point";
  /** Path in a SlateJS document */
  path: Array<Scalars["Float"]>;
  /** Offset in a SlateJS text node */
  offset: Scalars["Float"];
};

export type Reaction = {
  __typename?: "Reaction";
  type: ReactionType;
  user: SafeUser;
};

export enum ReactionType {
  Agree = "Agree",
  LikeIt = "LikeIt",
  Exciting = "Exciting",
  Interesting = "Interesting",
  LetsChat = "LetsChat",
}

export type InteractionResponse = {
  __typename?: "InteractionResponse";
  /** When the interaction response was created. */
  createdAt: Scalars["DateTime"];
  /** When the interaction response was last updated. */
  updatedAt: Scalars["DateTime"];
  /** Content of the interaction response. */
  content: Scalars["String"];
  /** user who submitted the interaction response. */
  user: SafeUser;
};

export type Document = {
  __typename?: "Document";
  title: Scalars["String"];
  body?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  round?: Maybe<Round>;
};

export type Feedback = {
  __typename?: "Feedback";
  /** The ID of the currently active feedback cycle */
  active: Scalars["ID"];
  cycles: Array<Scalars["String"]>;
};

export type ParticipantRole = {
  __typename?: "ParticipantRole";
  /** the id of the group/user that the participant role represents */
  linkedEntityId: Scalars["ObjectId"];
  type: ParticipantType;
  participatesAs?: Maybe<ParticipationRole>;
  needsAcknowledgement: Scalars["Boolean"];
  inviteEmail?: Maybe<Scalars["String"]>;
  inviteAsGuest?: Maybe<Scalars["Boolean"]>;
  displayName: Scalars["String"];
  avatar?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  members?: Maybe<Array<Member>>;
};

export enum ParticipantType {
  Group = "Group",
  User = "User",
  InvitedUser = "InvitedUser",
}

export type ProposalActivity = {
  __typename?: "ProposalActivity";
  /** What kind of activity is this */
  type: ActivityType;
  /** When does the activity complete */
  endDate: Scalars["DateTime"];
  /** What proposal is associated with this activity */
  proposal: Proposal;
};

export type Proposal = {
  __typename?: "Proposal";
  _id: Scalars["ID"];
  proposer: User;
  workspace: Workspace;
  status: Status;
  /** Whether this agreement is a template */
  template?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  workingDocument: DocumentBase;
  visibility: Visibility;
  /** Whether this agreement shows up on Explore page and can (eventually) be indexed by search engines */
  discoverable?: Maybe<Scalars["Boolean"]>;
  /** An array of ancestors, starting with the oldest, and ending with the most recently duplicated agreement */
  provenance?: Maybe<Array<Scalars["ID"]>>;
  /** The duration for an agreement to be active */
  expirationDuration: ExpirationDuration;
  /** Frequency (in seconds) that feedback should be requested */
  feedbackFrequency: FeedbackFrequency;
  /** A category for agreement type, out of a predefined number of types. Undefined by default, and a user can set to 'Uncategorized' if they want to unset the type */
  agreementType?: Maybe<AgreementType>;
  agreementProperties?: Maybe<AgreementProperties>;
  events: Array<AgreementEvent>;
  /** All notes sent with notifications during the proposal process */
  notesForProposalProcess?: Maybe<NotesForProposalProcess>;
  version: Scalars["ID"];
  parent?: Maybe<Agreement>;
  activeVersion?: Maybe<Scalars["ID"]>;
  proposalVersion?: Maybe<Scalars["ID"]>;
  archivedVersion?: Maybe<Scalars["ID"]>;
  smartEvents?: Maybe<Array<SmartEvent>>;
  discoveryMetadata?: Maybe<DiscoveryMetadata>;
  round: Round;
  activePlayer: PlayerTurn;
  participants: Array<Participant>;
  interactions?: Maybe<Array<Interaction>>;
  documents?: Maybe<Array<Document>>;
  /** What objection loop a proposal is currently in. Returns null if no objection loop. */
  finalizeCycle?: Maybe<Scalars["Float"]>;
  /** The timestamp for when the next feedback cycle starts. */
  nextFeedbackCycle: Scalars["DateTime"];
  fastTrack?: Maybe<Scalars["Boolean"]>;
  /** The plain text of the document as seen by a participant */
  participantText?: Maybe<DocumentBase>;
  /** The plain text of the document as seen by the proposer */
  proposerText?: Maybe<DocumentBase>;
  context?: Maybe<Scalars["String"]>;
  governs: Governed;
  roles: Array<ParticipantRole>;
  needsUserAction: Scalars["Boolean"];
  isStale: Scalars["Boolean"];
  nextAdvancement: Scalars["DateTime"];
  inviteLink?: Maybe<Scalars["String"]>;
  inviteLinkParticipationRole?: Maybe<ParticipationRole>;
};

export type ActivityParams = {
  workspaceId: Scalars["String"];
};

export type AgreementsParams = {
  workspaceId: Scalars["String"];
  status?: Maybe<Status>;
};

export type AgreementWithNeedsActionCount = {
  __typename?: "AgreementWithNeedsActionCount";
  total: Scalars["Float"];
  needsAction: Scalars["Float"];
};

export type PublicAgreementsParams = {
  workspaceId?: Maybe<Scalars["String"]>;
};

export type AgreementParams = {
  _id: Scalars["String"];
  workspaceId: Scalars["String"];
  proposer?: Maybe<Scalars["ID"]>;
  status?: Maybe<Status>;
};

export type ArchivedAgreementParams = {
  agreementId: Scalars["String"];
  versionId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type AgreementCount = {
  __typename?: "AgreementCount";
  total: Scalars["Float"];
};

export type DraftParams = {
  _id: Scalars["String"];
  workspaceId: Scalars["String"];
  agreementVersion?: Maybe<Scalars["String"]>;
};

export type ProposalEdit = {
  __typename?: "ProposalEdit";
  _id: Scalars["ID"];
  authorId: Scalars["ID"];
  authoredAt: Scalars["DateTime"];
  finalizeCycle?: Maybe<Scalars["Float"]>;
  operation: Scalars["JSON"];
  proposalId: Scalars["ID"];
  propertyFor: Scalars["String"];
  round?: Maybe<Round>;
  sequence?: Maybe<Scalars["Float"]>;
  ulid: Scalars["String"];
  versionId: Scalars["ID"];
  /** True if an operation has been consolidated into a new operation */
  stale?: Maybe<Scalars["Boolean"]>;
  /** True if an operation was created by consolidating existing operations */
  consolidated?: Maybe<Scalars["Boolean"]>;
};

export type ProposalEditsForAgreementParams = {
  proposalId: Scalars["String"];
  versionId: Scalars["String"];
  status: Status;
};

export type CheckIn = {
  __typename?: "CheckIn";
  _id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  lastModified: Scalars["DateTime"];
  promptResponses: Array<PromptResponse>;
  workspace: Workspace;
  user: User;
};

export type PromptResponse = {
  __typename?: "PromptResponse";
  _id: Scalars["ID"];
  response: Scalars["String"];
  createdAt: Scalars["DateTime"];
  lastModified: Scalars["DateTime"];
  reactions: Array<EmojiReaction>;
  isTodaysPrompt: Scalars["Boolean"];
  prompt: Prompt;
  user: User;
  workspace: Workspace;
  sharedWith: Array<Group>;
  relatedAgreements: Array<DraftProposalAgreement>;
  previousPromptResponse?: Maybe<PromptResponse>;
};

export type EmojiReaction = {
  __typename?: "EmojiReaction";
  createdAt: Scalars["DateTime"];
  user: User;
  emoji: Emoji;
};

export type Emoji = {
  __typename?: "Emoji";
  activeSkinTone?: Maybe<Scalars["String"]>;
  emoji: Scalars["String"];
  unicode: Scalars["String"];
  names: Array<Scalars["String"]>;
};

export type Prompt = {
  __typename?: "Prompt";
  _id: Scalars["ID"];
  content: Scalars["String"];
  disabled: Scalars["Boolean"];
  examples: Array<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  type: PromptType;
  /** The order the prompt will appear in the rotation - lower numbers (minimum 1) are sorted earlier in the list, null/undefined signifies 'unprioritized'. */
  priorityOrder?: Maybe<Scalars["Float"]>;
  hasResponses: Scalars["Boolean"];
  followUpPrompt?: Maybe<Prompt>;
};

export enum PromptType {
  Noticing = "Noticing",
  Recommendation = "Recommendation",
}

export type DraftProposalAgreement = Draft | Proposal | Agreement;

export type GetCheckInParams = {
  checkInId: Scalars["String"];
};

export type GetDailyPromptsParams = {
  type?: Maybe<PromptType>;
  tags?: Maybe<AnyOrAllStringPredicateDto>;
  workspaceId: Scalars["String"];
};

export type AnyOrAllStringPredicateDto = {
  any?: Maybe<Array<Scalars["String"]>>;
  all?: Maybe<Array<Scalars["String"]>>;
};

export type GetPromptsFilteredParams = {
  type?: Maybe<PromptType>;
  tags?: Maybe<AnyOrAllStringPredicateDto>;
};

export type PromptResponsesPage = {
  __typename?: "PromptResponsesPage";
  promptResponses: Array<PromptResponse>;
  cursorLimit: Scalars["String"];
};

export type GetPromptResponsesFeedParams = {
  workspaceId: Scalars["String"];
  limit: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  filterType: PromptResponseFilterType;
  filteredGroupIds?: Maybe<Array<Scalars["String"]>>;
};

export enum PromptResponseFilterType {
  All = "All",
  Mine = "Mine",
  Group = "Group",
}

export type Collection = {
  __typename?: "Collection";
  _id: Scalars["ID"];
  archived: Scalars["Boolean"];
  name: Scalars["String"];
  /** @deprecated Use discoveryMetadata.descriptionText instead */
  description: Scalars["String"];
  visibility: CollectionVisibility;
  style: CollectionStyle;
  /** The order in which this collection should appear, if at all, in the featured collections carousel at the top of the Explore landing page */
  exploreLandingFeaturedOrder?: Maybe<Scalars["Float"]>;
  /** The order in which this collection should appear, if at all, below the featured collections on the Explore landing page */
  exploreLandingPageOrder?: Maybe<Scalars["Float"]>;
  discoveryMetadata?: Maybe<DiscoveryMetadata>;
  templateIds: Array<Scalars["String"]>;
  templates: Array<Draft>;
};

export enum CollectionVisibility {
  Public = "Public",
  Private = "Private",
}

export enum CollectionStyle {
  StickyNoteStack = "StickyNoteStack",
  GridStickyNoteStack = "GridStickyNoteStack",
  MidnightSparkle = "MidnightSparkle",
  Peachy = "Peachy",
  Malibu = "Malibu",
  Undecorated = "Undecorated",
}

export type CollectionCount = {
  __typename?: "CollectionCount";
  total: Scalars["Float"];
};

export type FeedbackCycle = {
  __typename?: "FeedbackCycle";
  _id: Scalars["ID"];
  agreementId: Scalars["ID"];
  versionId: Scalars["ID"];
  startDate: Scalars["DateTime"];
  responses: Array<FeedbackResponse>;
};

export type FeedbackResponse = {
  __typename?: "FeedbackResponse";
  status: FeedbackResponseStatus;
  user: User;
  commitment?: Maybe<FeedbackCommitment>;
  impact?: Maybe<ImpactValue>;
  whatNext?: Maybe<FeedbackWhatNext>;
  learning?: Maybe<Scalars["String"]>;
  completedTime?: Maybe<Scalars["DateTime"]>;
};

/** Types of feedback commitment */
export enum FeedbackResponseStatus {
  Waiting = "Waiting",
  InProgress = "InProgress",
  Complete = "Complete",
}

/** Types of feedback commitment */
export enum FeedbackCommitment {
  Yes = "Yes",
  Somewhat = "Somewhat",
  No = "No",
  Unsure = "Unsure",
}

/** Impact value used in Feedback for agreements */
export enum ImpactValue {
  MuchWorse = "MuchWorse",
  Worse = "Worse",
  Unsure = "Unsure",
  Better = "Better",
  MuchBetter = "MuchBetter",
}

/** What should we do with this agreement? */
export enum FeedbackWhatNext {
  Extend = "Extend",
  Change = "Change",
  Archive = "Archive",
}

export type FeedbackParams = {
  workspace: Scalars["String"];
  cycleId: Scalars["String"];
  agreementId: Scalars["String"];
};

export type GetGroupParams = {
  _id: Scalars["String"];
};

export type WorkspaceGroupsParams = {
  workspaceId: Scalars["String"];
  includeGroupIds?: Maybe<Array<Scalars["String"]>>;
};

export type GroupAgreementCounts = {
  __typename?: "GroupAgreementCounts";
  activeCount: Scalars["Float"];
  proposalCount: Scalars["Float"];
};

export type GetGroupProposalAndAgreementCountParams = {
  workspaceId: Scalars["String"];
  groupId: Scalars["String"];
};

export type InteractionsParams = {
  /** ID of the proposal the interactions are for. */
  proposalId: Scalars["String"];
  /** Workspace the proposal governs. */
  workspaceId: Scalars["String"];
  /** Interaction type, generally associated with a specific round. */
  interactionType: InteractionType;
  /** Property of the agreement that these interactions are for */
  propertyFor: Scalars["String"];
};

export type Notification = {
  __typename?: "Notification";
  _id: Scalars["ID"];
  /** The text content of the notification */
  content: Scalars["String"];
  /** Additional text related to the content */
  description?: Maybe<Scalars["String"]>;
  /** The state of whether or not the notification has been dismissed by the user */
  isDismissed: Scalars["Boolean"];
  /** The ID of the user the notification belongs to. */
  userId: Scalars["ID"];
  /** The ID of the workspace this notification belongs to. */
  workspaceId: Scalars["ID"];
  /** What kind of notification this is. */
  kind: NotificationKind;
  /** When the notification was created. */
  createdAt: Scalars["DateTime"];
  /** When the notification was last updated. */
  updatedAt: Scalars["DateTime"];
};

export enum NotificationKind {
  Default = "Default",
  OnboardingTask = "OnboardingTask",
}

export type NotificationsParams = {
  isDismissed?: Maybe<Scalars["Boolean"]>;
  kind?: Maybe<NotificationKind>;
  workspace: Scalars["String"];
};

export type WorkspaceSummary = {
  __typename?: "WorkspaceSummary";
  _id: Scalars["ID"];
  name: Scalars["String"];
  orgType?: Maybe<OrgType>;
  logoFileId?: Maybe<Scalars["String"]>;
  cohortId?: Maybe<Scalars["String"]>;
  invitedEmails: Array<EmailInvite>;
  integrationsRequiringReinstall: Array<Scalars["String"]>;
  integrationsInstalled: Array<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  allMembersGroup: Group;
  billingAdmins: Array<User>;
  guests: Array<User>;
  agreementCounts: AgreementsSummary;
};

export type AgreementsSummary = {
  __typename?: "AgreementsSummary";
  active: Scalars["Float"];
  proposal: Scalars["Float"];
  draft: Scalars["Float"];
};

export type WorkspaceMergeData = {
  __typename?: "WorkspaceMergeData";
  membersChanges: MembersChanges;
  groupsChanges: GroupsChanges;
  agreementsChanges: AgreementsChanges;
};

export type MembersChanges = {
  __typename?: "MembersChanges";
  added: Array<Member>;
  existing: Array<Member>;
  invites: Array<Invitation>;
};

export type GroupsChanges = {
  __typename?: "GroupsChanges";
  added: Array<Group>;
};

export type AgreementsChanges = {
  __typename?: "AgreementsChanges";
  drafts: Array<Draft>;
  proposals: Array<Proposal>;
  active: Array<Agreement>;
  archived: Array<Agreement>;
};

export type PreviewWorkspacesMergeParams = {
  parentWorkspaceId: Scalars["String"];
  childWorkspaceId: Scalars["String"];
};

export type LinkInvitation = {
  __typename?: "LinkInvitation";
  workspace: Workspace;
  added: Scalars["DateTime"];
  user: User;
  linkId: Scalars["String"];
  expires: Scalars["DateTime"];
  /** Indicates whether this invitation is to be a workspace guest (as opposed to a full member, or something else) */
  workspaceGuest: Scalars["Boolean"];
};

export type GetLinkInvitationParams = {
  linkInvitationId: Scalars["String"];
};

export type ProposalLinkInvitationResponse = {
  __typename?: "ProposalLinkInvitationResponse";
  linkId: Scalars["String"];
  proposer: Scalars["String"];
  proposalTitle: Scalars["String"];
  workspaceName: Scalars["String"];
  enabled: Scalars["Boolean"];
};

export type GetProposalLinkInvitationParams = {
  linkInvitationId: Scalars["String"];
};

export type UserWithSession = {
  __typename?: "UserWithSession";
  _id: Scalars["ID"];
  email: Scalars["String"];
  joined: Scalars["DateTime"];
  walletId?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  lastActive: Scalars["DateTime"];
  workspaces: Array<Workspace>;
  guestOfWorkspaces: Array<Workspace>;
  avatar?: Maybe<Scalars["String"]>;
  notificationPreferences: Array<NotificationPreference>;
  preferences?: Maybe<UserPreferences>;
  featuresSeen?: Maybe<Array<Scalars["String"]>>;
  checklists?: Maybe<Array<Checklist>>;
  workspaceInvitations: Array<Invitation>;
  proposalInvitations: Array<ProposalInvitation>;
  sessionExpiresAt: Scalars["DateTime"];
};

export type WorkspaceParams = {
  workspaceId: Scalars["String"];
};

export type WorkspaceInvitationLinkParams = {
  workspaceId: Scalars["String"];
  guest?: Maybe<Scalars["Boolean"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  markAgreementSeen: AgreementViewedResult;
  createDraftVersionFromActiveAgreement: Agreement;
  updateAgreementProperties: Agreement;
  updateExpirationDate: Agreement;
  acknowledgeAgreement: Agreement;
  addAcknowledgers: Agreement;
  archiveAgreement: Agreement;
  unarchiveAgreement: Agreement;
  createDraft: Draft;
  deleteDraft: Draft;
  draftFromExistingAgreement: Draft;
  draftsFromExistingAgreements: Array<Draft>;
  updateDraft: Draft;
  updateDraftProperties: Draft;
  createDraftFromCheckIn: Draft;
  createDraftFromPromptResponse: Draft;
  createDraftFromQuiz: Draft;
  createDraftFromQuizWithContext: Draft;
  saveProposalEdits: SaveProposalEditsResult;
  advanceProposalForProposer: Proposal;
  advanceProposalForUser: DraftOrProposalOrAgreement;
  undoParticipantFinished: Proposal;
  delayProposalAdvancement: Proposal;
  proposeAgreement: ProposalOrAgreement;
  proposeArchive: Proposal;
  proposeExtendExpiration: Proposal;
  revertProposalToDraft: Proposal;
  updateProposal: Proposal;
  updateProposalProperties: Proposal;
  updateProposalParticipantRoles: Proposal;
  updateTemplate: Draft;
  updateTemplateProperties: Draft;
  markDraftAsTemplate: Draft;
  createCheckIn: CheckIn;
  addPromptResponse: CheckIn;
  createPrompt: Prompt;
  updatePrompt: Prompt;
  createPromptResponse: PromptResponse;
  updatePromptResponse: PromptResponse;
  addEmojiReactionToPromptResponse: PromptResponse;
  removeEmojiReactionFromPromptResponse: PromptResponse;
  updateEmojiReactionForPromptResponse: PromptResponse;
  createCollection: Collection;
  updateCollection: Collection;
  useAllCollectionTemplates: Collection;
  createCreatorProfile: CreatorProfile;
  updateCreatorProfile: CreatorProfile;
  deleteCreatorProfile: CreatorProfile;
  updateFeedbackResponse: FeedbackCycle;
  createGroup: Group;
  deleteGroup: Group;
  updateGroupSettings: Group;
  addMembersToGroup: Group;
  addGroupNotificationPreference: GroupNotificationPreference;
  removeGroupNotificationPreference: GroupNotificationPreference;
  removeMembersFromGroup: Group;
  updateMemberRole: Group;
  createInteraction: Interaction;
  updateInteraction: Interaction;
  updateInteractionAcknowledgement: Interaction;
  deleteInteraction: Interaction;
  addInteractionReaction: Interaction;
  updateInteractionReaction: Interaction;
  removeInteractionReaction: Interaction;
  createInteractionResponse: Interaction;
  deleteInteractionResponse: Interaction;
  mergeWorkspaces?: Maybe<WorkspaceMergeData>;
  acceptInvite: UserWithSession;
  updateAuthenticatedUser: UserWithSession;
  updateAuthenticatedUserNotificationPreference: UserWithSession;
  updateUserPreferences: UserWithSession;
  markFeatureAsSeenByUser: UserWithSession;
  markChecklistItemCompleted: UserWithSession;
  removeWorkspaceGuest: User;
  promoteWorkspaceGuestToMember: Workspace;
  demoteWorkspaceMemberToGuest: Workspace;
  inviteMembersToWorkspace: Workspace;
  inviteGuestsToWorkspace: Workspace;
  changeInviteType: Workspace;
  deleteInvitation: Workspace;
  setMembersBillingAdminStatus: Workspace;
  removeMember: Workspace;
  updateWorkspaceProfile: Workspace;
  createWorkspace: Workspace;
};

export type MutationMarkAgreementSeenArgs = {
  input: MarkAgreementSeenInput;
};

export type MutationCreateDraftVersionFromActiveAgreementArgs = {
  input: CreateDraftVersionFromActiveAgreementInput;
};

export type MutationUpdateAgreementPropertiesArgs = {
  input: UpdatePropertiesInput;
};

export type MutationUpdateExpirationDateArgs = {
  input: UpdateExpirationDateInput;
};

export type MutationAcknowledgeAgreementArgs = {
  input: AcknowledgeAgreementInput;
};

export type MutationAddAcknowledgersArgs = {
  input: AddAcknowledgersInput;
};

export type MutationArchiveAgreementArgs = {
  input: ArchiveAgreementInput;
};

export type MutationUnarchiveAgreementArgs = {
  input: UnarchiveAgreementInput;
};

export type MutationCreateDraftArgs = {
  input: CreateDraftInput;
};

export type MutationDeleteDraftArgs = {
  input: DeleteDraftInput;
};

export type MutationDraftFromExistingAgreementArgs = {
  input: DraftFromExistingAgreementInput;
};

export type MutationDraftsFromExistingAgreementsArgs = {
  input: DraftsFromExistingAgreementsInput;
};

export type MutationUpdateDraftArgs = {
  input: DraftInput;
  _id: Scalars["String"];
};

export type MutationUpdateDraftPropertiesArgs = {
  input: UpdatePropertiesInput;
};

export type MutationCreateDraftFromCheckInArgs = {
  input: CreateDraftFromCheckInInput;
};

export type MutationCreateDraftFromPromptResponseArgs = {
  promptResponseId: Scalars["String"];
};

export type MutationCreateDraftFromQuizArgs = {
  input: CreateDraftFromQuizInput;
};

export type MutationCreateDraftFromQuizWithContextArgs = {
  input: CreateDraftFromQuizInput;
};

export type MutationSaveProposalEditsArgs = {
  input: SaveProposalEditsInput;
};

export type MutationAdvanceProposalForProposerArgs = {
  input: AdvanceProposalForProposerInput;
};

export type MutationAdvanceProposalForUserArgs = {
  input: AdvanceInput;
  _id: Scalars["String"];
};

export type MutationUndoParticipantFinishedArgs = {
  input: UndoParticipantFinishedInput;
};

export type MutationDelayProposalAdvancementArgs = {
  input: DelayProposalAdvancementInput;
};

export type MutationProposeAgreementArgs = {
  input: ProposeDraftInput;
  _id: Scalars["String"];
};

export type MutationProposeArchiveArgs = {
  input: ProposeArchiveOrExtendInput;
};

export type MutationProposeExtendExpirationArgs = {
  input: ProposeArchiveOrExtendInput;
};

export type MutationRevertProposalToDraftArgs = {
  input: RevertProposalToDraftInput;
};

export type MutationUpdateProposalArgs = {
  input: UpdateProposalInput;
};

export type MutationUpdateProposalPropertiesArgs = {
  input: UpdatePropertiesInput;
};

export type MutationUpdateProposalParticipantRolesArgs = {
  input: UpdateParticipantRolesInput;
};

export type MutationUpdateTemplateArgs = {
  input: DraftInput;
  _id: Scalars["String"];
};

export type MutationUpdateTemplatePropertiesArgs = {
  input: UpdatePropertiesInput;
};

export type MutationMarkDraftAsTemplateArgs = {
  _id: Scalars["String"];
};

export type MutationCreateCheckInArgs = {
  input: CreateCheckInInput;
};

export type MutationAddPromptResponseArgs = {
  input: AddPromptResponseInput;
};

export type MutationCreatePromptArgs = {
  input: CreatePromptInput;
};

export type MutationUpdatePromptArgs = {
  input: UpdatePromptInput;
};

export type MutationCreatePromptResponseArgs = {
  input: CreatePromptResponseInput;
};

export type MutationUpdatePromptResponseArgs = {
  input: UpdatePromptResponseInput;
};

export type MutationAddEmojiReactionToPromptResponseArgs = {
  emoji: EmojiInput;
  promptResponseId: Scalars["String"];
};

export type MutationRemoveEmojiReactionFromPromptResponseArgs = {
  emoji: EmojiInput;
  promptResponseId: Scalars["String"];
};

export type MutationUpdateEmojiReactionForPromptResponseArgs = {
  updatedEmoji: EmojiInput;
  originalEmoji: EmojiInput;
  promptResponseId: Scalars["String"];
};

export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};

export type MutationUseAllCollectionTemplatesArgs = {
  collectionId: Scalars["String"];
};

export type MutationUpdateCreatorProfileArgs = {
  input: UpdateCreatorProfileInput;
};

export type MutationDeleteCreatorProfileArgs = {
  id: Scalars["String"];
};

export type MutationUpdateFeedbackResponseArgs = {
  input: UpdateFeedbackResponseInput;
};

export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};

export type MutationUpdateGroupSettingsArgs = {
  input: UpdateGroupSettingsInput;
};

export type MutationAddMembersToGroupArgs = {
  input: AddMembersToGroupInput;
};

export type MutationAddGroupNotificationPreferenceArgs = {
  input: AddGroupNotificationPreferenceInput;
};

export type MutationRemoveGroupNotificationPreferenceArgs = {
  input: RemoveGroupNotificationPreferenceInput;
};

export type MutationRemoveMembersFromGroupArgs = {
  input: RemoveMembersFromGroupInput;
};

export type MutationUpdateMemberRoleArgs = {
  input: UpdateMemberRoleInput;
};

export type MutationCreateInteractionArgs = {
  input: CreateInteractionInput;
};

export type MutationUpdateInteractionArgs = {
  input: UpdateInteractionInput;
};

export type MutationUpdateInteractionAcknowledgementArgs = {
  input: UpdateInteractionAcknowledgementInput;
};

export type MutationDeleteInteractionArgs = {
  input: DeleteInteractionInput;
};

export type MutationAddInteractionReactionArgs = {
  input: UpdateInteractionReactionInput;
};

export type MutationUpdateInteractionReactionArgs = {
  input: UpdateInteractionReactionInput;
};

export type MutationRemoveInteractionReactionArgs = {
  input: UpdateInteractionReactionInput;
};

export type MutationCreateInteractionResponseArgs = {
  input: CreateInteractionResponseInput;
};

export type MutationDeleteInteractionResponseArgs = {
  input: DeleteInteractionResponseInput;
};

export type MutationMergeWorkspacesArgs = {
  input: MergeWorkspacesInput;
};

export type MutationAcceptInviteArgs = {
  workspaceId: Scalars["String"];
};

export type MutationUpdateAuthenticatedUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateAuthenticatedUserNotificationPreferenceArgs = {
  input: UpdatedAuthenticatedUserNotificationsInput;
};

export type MutationUpdateUserPreferencesArgs = {
  preferences: UpdateUserPreferencesInput;
};

export type MutationMarkFeatureAsSeenByUserArgs = {
  featureName: Scalars["String"];
};

export type MutationMarkChecklistItemCompletedArgs = {
  input: MarkChecklistItemCompletedInput;
};

export type MutationRemoveWorkspaceGuestArgs = {
  input: RemoveWorkspaceGuestInput;
};

export type MutationPromoteWorkspaceGuestToMemberArgs = {
  input: PromoteWorkspaceGuestToMemberInput;
};

export type MutationDemoteWorkspaceMemberToGuestArgs = {
  input: DemoteWorkspaceMemberToGuestInput;
};

export type MutationInviteMembersToWorkspaceArgs = {
  input: InviteMembersToWorkspaceInput;
};

export type MutationInviteGuestsToWorkspaceArgs = {
  input: InviteGuestsToWorkspaceInput;
};

export type MutationChangeInviteTypeArgs = {
  input: ChangeInviteTypeInput;
};

export type MutationDeleteInvitationArgs = {
  input: DeleteInvitationInput;
};

export type MutationSetMembersBillingAdminStatusArgs = {
  input: SetMembersBillingAdminStatusInput;
};

export type MutationRemoveMemberArgs = {
  input: RemoveMemberInput;
};

export type MutationUpdateWorkspaceProfileArgs = {
  input: UpdateWorkspaceProfileInput;
};

export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};

export type AgreementViewedResult = {
  __typename?: "AgreementViewedResult";
  seen: Scalars["Boolean"];
};

export type MarkAgreementSeenInput = {
  agreementVersionId: Scalars["String"];
};

export type CreateDraftVersionFromActiveAgreementInput = {
  agreementId: Scalars["String"];
  workspace: Scalars["String"];
};

export type UpdatePropertiesInput = {
  _id: Scalars["ID"];
  workspaceId: Scalars["String"];
  expirationDuration?: Maybe<ExpirationDuration>;
  feedbackFrequency?: Maybe<FeedbackFrequency>;
  agreementType?: Maybe<AgreementType>;
  visibility?: Maybe<Visibility>;
  governs?: Maybe<Scalars["GovernedScalar"]>;
  roles?: Maybe<Array<Scalars["ParticipantRoleScalar"]>>;
  draftVisibleToWorkspace?: Maybe<Scalars["Boolean"]>;
};

export type UpdateExpirationDateInput = {
  agreementId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type AcknowledgeAgreementInput = {
  agreementId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type AddAcknowledgersInput = {
  roles: Array<Scalars["ParticipantRoleScalar"]>;
  agreementId: Scalars["String"];
  workspaceId: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
};

export type ArchiveAgreementInput = {
  agreementId: Scalars["String"];
  versionId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type UnarchiveAgreementInput = {
  agreementId: Scalars["String"];
  versionId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type CreateDraftInput = {
  workspaceId: Scalars["String"];
};

export type DeleteDraftInput = {
  /** ID of the draft to be deleted */
  _id: Scalars["ID"];
  /** Workspace governed by the draft to be deleted */
  workspaceId: Scalars["String"];
};

export type DraftFromExistingAgreementInput = {
  /** id of the agreement/template being duplicated */
  _id: Scalars["ID"];
  workspaceId: Scalars["String"];
  fromCollectionId?: Maybe<Scalars["String"]>;
};

export type DraftsFromExistingAgreementsInput = {
  /** ids of the agreements/templates being duplicated */
  agreementIds: Array<Scalars["ID"]>;
  workspaceId: Scalars["String"];
  fromCollectionId?: Maybe<Scalars["String"]>;
};

export type DraftInput = {
  _id?: Maybe<Scalars["ID"]>;
  workspaceId: Scalars["String"];
  versionId: Scalars["String"];
  visibility?: Maybe<Visibility>;
  template?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["String"]>;
  context?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  nextAdvancement?: Maybe<Scalars["DateTime"]>;
  discoveryMetadata?: Maybe<UpdateDiscoveryMetadataInput>;
};

export type UpdateDiscoveryMetadataInput = {
  creatorProfile?: Maybe<Scalars["ID"]>;
  /** A list of templates that work well with this agreement */
  worksWellWith?: Maybe<Array<Scalars["ID"]>>;
  /** A Slate document description of the template */
  description?: Maybe<Scalars["String"]>;
  /** The plain text of the Slate description */
  descriptionText?: Maybe<Scalars["String"]>;
  /** A Slate document describing the audience for the template */
  audience?: Maybe<Scalars["String"]>;
  /** The plain text of the Slate audience */
  audienceText?: Maybe<Scalars["String"]>;
  /** The type of organization that this template is for */
  orgType: Array<OrgType>;
  /** The type of team that this template is for */
  teamType: Array<TeamType>;
};

export type CreateDraftFromCheckInInput = {
  workspaceId: Scalars["String"];
  checkInId: Scalars["String"];
  expirationDuration?: Maybe<ExpirationDuration>;
};

export type CreateDraftFromQuizInput = {
  workspaceId: Scalars["String"];
  tinyTemplate: TinyTemplate;
  expirationDuration?: Maybe<ExpirationDuration>;
};

export enum TinyTemplate {
  FunSlackChannels = "FunSlackChannels",
  VirtualHangoutArea = "VirtualHangoutArea",
  BuddyUp = "BuddyUp",
  BirthdayBonus = "BirthdayBonus",
  DestinationRetreats = "DestinationRetreats",
  AfterHourMessages = "AfterHourMessages",
  DeleteSlackFromPhone = "DeleteSlackFromPhone",
  FourDayWorkWeek = "FourDayWorkWeek",
  QuarterlyPtoShutdown = "QuarterlyPTOShutdown",
  SummerShutdown = "SummerShutdown",
  LoomUpdates = "LoomUpdates",
  BetterVideoChat = "BetterVideoChat",
  WeeklyMeetingFreeDay = "WeeklyMeetingFreeDay",
  CollaborativeMeetings = "CollaborativeMeetings",
  ClearTheCalendar = "ClearTheCalendar",
}

export type SaveProposalEditsResult = {
  __typename?: "SaveProposalEditsResult";
  failedIndexes: Array<Scalars["Float"]>;
};

export type SaveProposalEditsInput = {
  operations: Array<ProposalEditOperationInput>;
  proposalId: Scalars["String"];
  versionId: Scalars["String"];
  status: Status;
};

export type ProposalEditOperationInput = {
  propertyFor: Scalars["String"];
  ulid: Scalars["String"];
  authoredAt: Scalars["Float"];
  operationJSON: Scalars["String"];
  round?: Maybe<Round>;
  sequence?: Maybe<Scalars["Float"]>;
  versionId?: Maybe<Scalars["String"]>;
  finalizeCycle?: Maybe<Scalars["Float"]>;
};

export type AdvanceProposalForProposerInput = {
  /** The _id for proposal being advanced */
  proposalId: Scalars["String"];
  /** The _id for the workspace being governed by the proposal */
  workspaceId: Scalars["String"];
  /** Note from proposer to participants in the next step of the proposal process */
  note?: Maybe<Scalars["String"]>;
  /** The date at which the proposal should automatically move to the next round */
  nextAdvancement: Scalars["DateTime"];
};

export type DraftOrProposalOrAgreement = Draft | Proposal | Agreement;

export type AdvanceInput = {
  workspaceId: Scalars["String"];
  decision?: Maybe<Decision>;
  reaction?: Maybe<Scalars["Float"]>;
};

export type UndoParticipantFinishedInput = {
  proposalId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type DelayProposalAdvancementInput = {
  /** ID of the proposal for which advancement is being delayed */
  _id: Scalars["ID"];
  /** Workspace governed by the proposal for which advancement is being delayed */
  workspaceId: Scalars["String"];
};

export type ProposalOrAgreement = Proposal | Agreement;

export type ProposeDraftInput = {
  _id?: Maybe<Scalars["ID"]>;
  workspaceId: Scalars["String"];
  versionId: Scalars["String"];
  visibility?: Maybe<Visibility>;
  template?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["String"]>;
  context?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  nextAdvancement?: Maybe<Scalars["DateTime"]>;
  discoveryMetadata?: Maybe<UpdateDiscoveryMetadataInput>;
  roles: Array<Scalars["ParticipantRoleScalar"]>;
  createInviteLink?: Maybe<Scalars["Boolean"]>;
  inviteLinkParticipationRole?: Maybe<ParticipationRole>;
  fastTrack?: Maybe<Scalars["Boolean"]>;
};

export type ProposeArchiveOrExtendInput = {
  agreementId: Scalars["String"];
  agreementVersionId: Scalars["String"];
  workspaceId: Scalars["String"];
  nextAdvancement: Scalars["DateTime"];
  note?: Maybe<Scalars["String"]>;
};

export type RevertProposalToDraftInput = {
  workspaceId: Scalars["String"];
  versionId: Scalars["String"];
};

export type UpdateProposalInput = {
  _id: Scalars["String"];
  workspaceId: Scalars["String"];
  body?: Maybe<Scalars["String"]>;
  context?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  visibility?: Maybe<Visibility>;
};

export type UpdateParticipantRolesInput = {
  versionId: Scalars["String"];
  workspaceId: Scalars["String"];
  roles: Array<Scalars["ParticipantRoleScalar"]>;
  inviteLinkParticipationRole?: Maybe<ParticipationRole>;
};

export type CreateCheckInInput = {
  workspaceId: Scalars["String"];
  promptId?: Maybe<Scalars["String"]>;
  response?: Maybe<Scalars["String"]>;
};

export type AddPromptResponseInput = {
  workspaceId: Scalars["String"];
  checkInId: Scalars["String"];
  promptId: Scalars["String"];
  response: Scalars["String"];
  previousPromptResponseId?: Maybe<Scalars["String"]>;
};

export type CreatePromptInput = {
  content: Scalars["String"];
  disabled?: Maybe<Scalars["Boolean"]>;
  examples?: Maybe<Array<Scalars["String"]>>;
  tags?: Maybe<Array<Scalars["String"]>>;
  type: PromptType;
  followUpPromptId?: Maybe<Scalars["String"]>;
  priorityOrder?: Maybe<Scalars["Float"]>;
};

export type UpdatePromptInput = {
  promptId: Scalars["String"];
  content?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
  examples?: Maybe<Array<Scalars["String"]>>;
  tags?: Maybe<Array<Scalars["String"]>>;
  type?: Maybe<PromptType>;
  followUpPromptId?: Maybe<Scalars["String"]>;
  priorityOrder?: Maybe<Scalars["Float"]>;
};

export type CreatePromptResponseInput = {
  workspaceId: Scalars["String"];
  promptId: Scalars["String"];
  response: Scalars["String"];
  previousPromptResponseId?: Maybe<Scalars["String"]>;
  sharedWith: Array<Scalars["String"]>;
};

export type UpdatePromptResponseInput = {
  promptResponseId: Scalars["String"];
  response?: Maybe<Scalars["String"]>;
  sharedWith?: Maybe<Array<Scalars["String"]>>;
};

export type EmojiInput = {
  activeSkinTone?: Maybe<Scalars["String"]>;
  emoji: Scalars["String"];
  unicode: Scalars["String"];
  names: Array<Scalars["String"]>;
};

export type UpdateCollectionInput = {
  collectionId: Scalars["String"];
  archived?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  visibility?: Maybe<CollectionVisibility>;
  style?: Maybe<CollectionStyle>;
  templateIds?: Maybe<Array<Scalars["String"]>>;
  exploreLandingFeaturedOrder?: Maybe<Scalars["Float"]>;
  exploreLandingPageOrder?: Maybe<Scalars["Float"]>;
  discoveryMetadata?: Maybe<UpdateDiscoveryMetadataInput>;
};

export type UpdateCreatorProfileInput = {
  _id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  logoImageFileId?: Maybe<Scalars["String"]>;
  twitterProfileURL?: Maybe<Scalars["String"]>;
  linkedInProfileURL?: Maybe<Scalars["String"]>;
  facebookProfileURL?: Maybe<Scalars["String"]>;
  instagramProfileURL?: Maybe<Scalars["String"]>;
  websiteURL?: Maybe<Scalars["String"]>;
  public?: Maybe<Scalars["Boolean"]>;
};

export type UpdateFeedbackResponseInput = {
  workspace: Scalars["String"];
  cycleId: Scalars["String"];
  agreementId: Scalars["String"];
  field: FeedbackFields;
  value: Scalars["String"];
  complete?: Maybe<Scalars["Boolean"]>;
};

export enum FeedbackFields {
  Commitment = "Commitment",
  Impact = "Impact",
  Learning = "Learning",
  WhatNext = "WhatNext",
}

export type CreateGroupInput = {
  workspaceId: Scalars["String"];
  name: Scalars["String"];
  description: Scalars["String"];
  visibility: GroupVisibility;
};

export type DeleteGroupInput = {
  workspaceId: Scalars["String"];
  groupId: Scalars["String"];
};

export type UpdateGroupSettingsInput = {
  groupId: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  visibility?: Maybe<GroupVisibility>;
  workspaceId: Scalars["String"];
};

export type AddMembersToGroupInput = {
  userIds: Array<Scalars["String"]>;
  groupId: Scalars["String"];
  addNewMembersToProposals: Scalars["Boolean"];
  workspaceId: Scalars["String"];
};

export type AddGroupNotificationPreferenceInput = {
  groupId: Scalars["String"];
  type: GroupNotificationType;
  channelId: Scalars["String"];
  channelUrl: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type RemoveGroupNotificationPreferenceInput = {
  groupId: Scalars["String"];
  type: GroupNotificationType;
  channelId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type RemoveMembersFromGroupInput = {
  userIds: Array<Scalars["String"]>;
  groupId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type UpdateMemberRoleInput = {
  userId: Scalars["String"];
  groupId: Scalars["String"];
  role: GroupRole;
  workspaceId: Scalars["String"];
};

export type CreateInteractionInput = {
  /** ID of the proposal the interaction is for. */
  proposalId: Scalars["String"];
  /** Interaction type, generally associated with a specific round. */
  interactionType: InteractionType;
  /** Content of the interaction. */
  content: Scalars["String"];
  /** Workspace the proposal governs. */
  workspaceId: Scalars["String"];
  /** Selection of agreement body that interaction is correlated with. */
  range?: Maybe<RangeInput>;
  /** Property of the agreement that this interaction is for */
  propertyFor: Scalars["String"];
};

export type RangeInput = {
  anchor: PointInput;
  focus: PointInput;
};

export type PointInput = {
  /** Path in a SlateJS document */
  path: Array<Scalars["Float"]>;
  /** Offset in a SlateJS text node */
  offset: Scalars["Float"];
};

export type UpdateInteractionInput = {
  /** ID for the interaction. */
  _id: Scalars["String"];
  /** ID of the proposal the interaction is for. */
  proposalId: Scalars["String"];
  /** Content of the interaction. */
  content: Scalars["String"];
  /** Workspace that this proposal governs. */
  workspaceId: Scalars["String"];
  /** Current round the proposal is in. */
  round: Round;
};

export type UpdateInteractionAcknowledgementInput = {
  /** ID for the interaction. */
  interactionId: Scalars["String"];
  /** ID of the proposal the interaction is for. */
  proposalId: Scalars["String"];
  /** Workspace that this proposal governs. */
  workspaceId: Scalars["String"];
  /** Whether the interaction has been acknowledged by the proposer */
  acknowledgedByProposer: Scalars["Boolean"];
};

export type DeleteInteractionInput = {
  /** ID for the interaction to delete. */
  _id: Scalars["String"];
  /** ID of the proposal the interaction being deleted is for. */
  proposalId: Scalars["String"];
  /** Workspace that this proposal governs. */
  workspaceId: Scalars["String"];
  /** Current round the proposal is in. */
  round: Round;
};

export type UpdateInteractionReactionInput = {
  /** ID for the interaction to react to. */
  _id: Scalars["String"];
  /** ID of the proposal for the interaction reaction. */
  proposalId: Scalars["String"];
  /** Workspace that this proposal governs. */
  workspaceId: Scalars["String"];
  /** The type of reaction to add to the interaction */
  type: ReactionType;
  /** Current round the proposal is in. */
  round: Round;
};

export type CreateInteractionResponseInput = {
  /** ID of the proposal the interaction is for. */
  proposalId: Scalars["String"];
  /** Content of the interaction. */
  content: Scalars["String"];
  /** ID of the interaction that this interaction is a response to. */
  responseTo: Scalars["String"];
  /** Workspace that this proposal governs. */
  workspaceId: Scalars["String"];
  /** Current round the proposal is in. */
  round: Round;
};

export type DeleteInteractionResponseInput = {
  /** ID for the interaction to delete the response from. */
  _id: Scalars["String"];
  /** ID of the proposal for which the interaction response is being deleted. */
  proposalId: Scalars["String"];
  /** Workspace that this proposal governs. */
  workspaceId: Scalars["String"];
};

export type MergeWorkspacesInput = {
  parentWorkspaceId: Scalars["String"];
  childWorkspaceId: Scalars["String"];
  updatedGroupNames: Array<Scalars["GroupNameScalar"]>;
  newParentWorkspaceGroup?: Maybe<Scalars["String"]>;
};

export type UpdateUserInput = {
  displayName: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  avatar: Scalars["String"];
};

export type UpdatedAuthenticatedUserNotificationsInput = {
  type: NotificationType;
  enabled: Scalars["Boolean"];
  workspace: Scalars["ID"];
};

export type UpdateUserPreferencesInput = {
  lastUsedEmojiSkinTone?: Maybe<EmojiSkinTones>;
  dontShowWhatHappensWhenIProposeHelp?: Maybe<Scalars["Boolean"]>;
};

export type MarkChecklistItemCompletedInput = {
  workspaceId: Scalars["String"];
  checkedItem: Scalars["String"];
};

export type RemoveWorkspaceGuestInput = {
  userId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type PromoteWorkspaceGuestToMemberInput = {
  workspaceId: Scalars["String"];
  userId: Scalars["String"];
};

export type DemoteWorkspaceMemberToGuestInput = {
  workspaceId: Scalars["String"];
  userId: Scalars["String"];
};

export type InviteMembersToWorkspaceInput = {
  workspaceId: Scalars["String"];
  emailAddresses: Array<Scalars["String"]>;
};

export type InviteGuestsToWorkspaceInput = {
  emailAddresses: Array<Scalars["String"]>;
  workspaceId: Scalars["String"];
};

export type ChangeInviteTypeInput = {
  emailAddress: Scalars["String"];
  workspaceId: Scalars["String"];
  workspaceGuest: Scalars["Boolean"];
};

export type DeleteInvitationInput = {
  /** email address that will have all invites to the workspace removed */
  email: Scalars["String"];
  /** workspace for which the invitation is being deleted */
  workspaceId: Scalars["String"];
};

export type SetMembersBillingAdminStatusInput = {
  workspaceId: Scalars["String"];
  userIds: Array<Scalars["String"]>;
  setToBillingAdmin: Scalars["Boolean"];
};

export type RemoveMemberInput = {
  userId: Scalars["String"];
  workspaceId: Scalars["String"];
};

export type UpdateWorkspaceProfileInput = {
  workspaceId: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  logoFileId?: Maybe<Scalars["String"]>;
};

export type CreateWorkspaceInput = {
  name: Scalars["String"];
  workspaceId?: Maybe<Scalars["String"]>;
  orgType?: Maybe<OrgType>;
  cohortId?: Maybe<Scalars["String"]>;
};

export type CheckInFragment = { __typename?: "CheckIn" } & Pick<
  CheckIn,
  "_id" | "createdAt" | "lastModified"
> & {
    promptResponses: Array<
      { __typename?: "PromptResponse" } & PromptResponseFragment
    >;
    user: { __typename?: "User" } & Pick<User, "displayName" | "avatar">;
  };

export type EmojiReactionFragment = { __typename?: "EmojiReaction" } & {
  user: { __typename?: "User" } & Pick<User, "_id" | "displayName">;
  emoji: { __typename?: "Emoji" } & Pick<
    Emoji,
    "emoji" | "unicode" | "names" | "activeSkinTone"
  >;
};

export type PromptFragment = { __typename?: "Prompt" } & Pick<
  Prompt,
  "_id" | "type" | "content" | "examples"
> & { followUpPrompt?: Maybe<{ __typename?: "Prompt" } & Pick<Prompt, "_id">> };

export type PromptResponseFragment = { __typename?: "PromptResponse" } & Pick<
  PromptResponse,
  "_id" | "response" | "createdAt" | "lastModified"
> & {
    prompt: { __typename?: "Prompt" } & PromptFragment;
    sharedWith: Array<{ __typename?: "Group" } & Pick<Group, "_id" | "name">>;
    user: { __typename?: "User" } & Pick<
      User,
      "_id" | "displayName" | "avatar"
    >;
    previousPromptResponse?: Maybe<
      { __typename?: "PromptResponse" } & Pick<PromptResponse, "_id">
    >;
    relatedAgreements: Array<
      | ({ __typename?: "Draft" } & Pick<
          Draft,
          "_id" | "status" | "version"
        > & { proposer: { __typename?: "User" } & Pick<User, "_id"> })
      | ({ __typename?: "Proposal" } & Pick<Proposal, "_id" | "status"> & {
            proposer: { __typename?: "User" } & Pick<User, "_id">;
          })
      | ({ __typename?: "Agreement" } & Pick<Agreement, "_id" | "status"> & {
            proposer: { __typename?: "User" } & Pick<User, "_id">;
          })
    >;
    reactions: Array<{ __typename?: "EmojiReaction" } & EmojiReactionFragment>;
  };

export type AddPromptResponseMutationVariables = Exact<{
  input: AddPromptResponseInput;
}>;

export type AddPromptResponseMutation = { __typename?: "Mutation" } & {
  addPromptResponse: { __typename?: "CheckIn" } & CheckInFragment;
};

export type CreateCheckInMutationVariables = Exact<{
  input: CreateCheckInInput;
}>;

export type CreateCheckInMutation = { __typename?: "Mutation" } & {
  createCheckIn: { __typename?: "CheckIn" } & CheckInFragment;
};

export type CreateDraftFromCheckInMutationVariables = Exact<{
  input: CreateDraftFromCheckInInput;
}>;

export type CreateDraftFromCheckInMutation = { __typename?: "Mutation" } & {
  createDraftFromCheckIn: { __typename?: "Draft" } & Pick<
    Draft,
    "_id" | "version"
  > & { proposer: { __typename?: "User" } & Pick<User, "_id"> };
};

export type UpdatePromptResponseMutationVariables = Exact<{
  input: UpdatePromptResponseInput;
}>;

export type UpdatePromptResponseMutation = { __typename?: "Mutation" } & {
  updatePromptResponse: {
    __typename?: "PromptResponse";
  } & PromptResponseFragment;
};

export type GetCheckInQueryVariables = Exact<{
  params: GetCheckInParams;
}>;

export type GetCheckInQuery = { __typename?: "Query" } & {
  getCheckIn: { __typename?: "CheckIn" } & CheckInFragment;
};

export type GetPromptsFilteredQueryVariables = Exact<{
  params: GetPromptsFilteredParams;
}>;

export type GetPromptsFilteredQuery = { __typename?: "Query" } & {
  getPromptsFiltered: Array<{ __typename?: "Prompt" } & PromptFragment>;
};

export type UpdateParticipantRolesMutationVariables = Exact<{
  input: UpdateParticipantRolesInput;
}>;

export type UpdateParticipantRolesMutation = { __typename?: "Mutation" } & {
  updateProposalParticipantRoles: {
    __typename?: "Proposal";
  } & ProposalFragment;
};

export type CreatorProfileFragment = { __typename?: "CreatorProfile" } & Pick<
  CreatorProfile,
  | "_id"
  | "name"
  | "bio"
  | "logoImageFileId"
  | "createdByUserId"
  | "twitterProfileURL"
  | "linkedInProfileURL"
  | "facebookProfileURL"
  | "instagramProfileURL"
  | "websiteURL"
  | "public"
>;

export type CreateCreatorProfileMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateCreatorProfileMutation = { __typename?: "Mutation" } & {
  createCreatorProfile: {
    __typename?: "CreatorProfile";
  } & CreatorProfileFragment;
};

export type DeleteCreatorProfileMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteCreatorProfileMutation = { __typename?: "Mutation" } & {
  deleteCreatorProfile: {
    __typename?: "CreatorProfile";
  } & CreatorProfileFragment;
};

export type UpdateCreatorProfileMutationVariables = Exact<{
  input: UpdateCreatorProfileInput;
}>;

export type UpdateCreatorProfileMutation = { __typename?: "Mutation" } & {
  updateCreatorProfile: {
    __typename?: "CreatorProfile";
  } & CreatorProfileFragment;
};

export type AllCreatorProfilesQueryVariables = Exact<{ [key: string]: never }>;

export type AllCreatorProfilesQuery = { __typename?: "Query" } & {
  creatorProfiles: Array<
    { __typename?: "CreatorProfile" } & CreatorProfileFragment
  >;
};

export type PublicCreatorProfilesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PublicCreatorProfilesQuery = { __typename?: "Query" } & {
  publicCreatorProfiles: Array<
    { __typename?: "CreatorProfile" } & CreatorProfileFragment
  >;
};

export type AddEmojiReactionToPromptResponseMutationVariables = Exact<{
  promptResponseId: Scalars["String"];
  emoji: EmojiInput;
}>;

export type AddEmojiReactionToPromptResponseMutation = {
  __typename?: "Mutation";
} & {
  addEmojiReactionToPromptResponse: {
    __typename?: "PromptResponse";
  } & PromptResponseFragment;
};

export type CreateDraftFromPromptResponseMutationVariables = Exact<{
  promptResponseId: Scalars["String"];
}>;

export type CreateDraftFromPromptResponseMutation = {
  __typename?: "Mutation";
} & {
  createDraftFromPromptResponse: { __typename?: "Draft" } & Pick<
    Draft,
    "_id" | "version"
  >;
};

export type CreatePromptResponseMutationVariables = Exact<{
  input: CreatePromptResponseInput;
}>;

export type CreatePromptResponseMutation = { __typename?: "Mutation" } & {
  createPromptResponse: {
    __typename?: "PromptResponse";
  } & PromptResponseFragment;
};

export type MarkChecklistItemCompletedMutationVariables = Exact<{
  input: MarkChecklistItemCompletedInput;
}>;

export type MarkChecklistItemCompletedMutation = { __typename?: "Mutation" } & {
  markChecklistItemCompleted: {
    __typename?: "UserWithSession";
  } & UserWithSessionFragment;
};

export type RemoveEmojiReactionFromPromptResponseMutationVariables = Exact<{
  promptResponseId: Scalars["String"];
  emoji: EmojiInput;
}>;

export type RemoveEmojiReactionFromPromptResponseMutation = {
  __typename?: "Mutation";
} & {
  removeEmojiReactionFromPromptResponse: {
    __typename?: "PromptResponse";
  } & PromptResponseFragment;
};

export type UpdateEmojiReactionForPromptResponseMutationVariables = Exact<{
  promptResponseId: Scalars["String"];
  originalEmoji: EmojiInput;
  updatedEmoji: EmojiInput;
}>;

export type UpdateEmojiReactionForPromptResponseMutation = {
  __typename?: "Mutation";
} & {
  updateEmojiReactionForPromptResponse: {
    __typename?: "PromptResponse";
  } & PromptResponseFragment;
};

export type GetActivitiesByWorkspaceQueryVariables = Exact<{
  activitiesParams: ActivityParams;
}>;

export type GetActivitiesByWorkspaceQuery = { __typename?: "Query" } & {
  activities: Array<
    | ({ __typename?: "AgreementActivity" } & ActivityAgreementSummaryFragment)
    | ({ __typename?: "ProposalActivity" } & ActivityProposalSummaryFragment)
  >;
};

export type GetDailyPromptsQueryVariables = Exact<{
  params: GetDailyPromptsParams;
}>;

export type GetDailyPromptsQuery = { __typename?: "Query" } & {
  getDailyPrompts: Array<{ __typename?: "Prompt" } & PromptFragment>;
};

export type GetSharedPromptResponsesForWorkspaceQueryVariables = Exact<{
  workspaceId: Scalars["String"];
  params: GetPromptResponsesFeedParams;
}>;

export type GetSharedPromptResponsesForWorkspaceQuery = {
  __typename?: "Query";
} & {
  getTodaysPromptResponseForUser?: Maybe<
    { __typename?: "PromptResponse" } & PromptResponseFragment
  >;
  getPromptResponsesFeed: { __typename?: "PromptResponsesPage" } & Pick<
    PromptResponsesPage,
    "cursorLimit"
  > & {
      promptResponses: Array<
        { __typename?: "PromptResponse" } & PromptResponseFragment
      >;
    };
};

export type DraftsFromExistingAgreementsMutationVariables = Exact<{
  input: DraftsFromExistingAgreementsInput;
}>;

export type DraftsFromExistingAgreementsMutation = {
  __typename?: "Mutation";
} & {
  draftsFromExistingAgreements: Array<
    { __typename?: "Draft" } & Pick<Draft, "_id">
  >;
};

export type GetAuthenticatedUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAuthenticatedUserQuery = { __typename?: "Query" } & {
  authenticatedUser?: Maybe<
    { __typename?: "UserWithSession" } & UserWithSessionFragment
  >;
};

export type CreateDraftFromQuizMutationVariables = Exact<{
  input: CreateDraftFromQuizInput;
}>;

export type CreateDraftFromQuizMutation = { __typename?: "Mutation" } & {
  createDraftFromQuiz: { __typename?: "Draft" } & Pick<
    Draft,
    "_id" | "version"
  > & { proposer: { __typename?: "User" } & Pick<User, "_id"> };
};

export type CreateDraftFromQuizWithContextMutationVariables = Exact<{
  input: CreateDraftFromQuizInput;
}>;

export type CreateDraftFromQuizWithContextMutation = {
  __typename?: "Mutation";
} & {
  createDraftFromQuizWithContext: { __typename?: "Draft" } & Pick<
    Draft,
    "_id" | "version"
  > & { proposer: { __typename?: "User" } & Pick<User, "_id"> };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: "Mutation" } & {
  updateAuthenticatedUser: {
    __typename?: "UserWithSession";
  } & UserWithSessionFragment;
};

export type ChangeInviteTypeMutationVariables = Exact<{
  input: ChangeInviteTypeInput;
}>;

export type ChangeInviteTypeMutation = { __typename?: "Mutation" } & {
  changeInviteType: { __typename?: "Workspace" } & WorkspaceFragment;
};

export type DemoteWorkspaceMemberToGuestMutationVariables = Exact<{
  input: DemoteWorkspaceMemberToGuestInput;
}>;

export type DemoteWorkspaceMemberToGuestMutation = {
  __typename?: "Mutation";
} & {
  demoteWorkspaceMemberToGuest: {
    __typename?: "Workspace";
  } & WorkspaceFragment;
};

export type InviteGuestsToWorkspaceMutationVariables = Exact<{
  input: InviteGuestsToWorkspaceInput;
}>;

export type InviteGuestsToWorkspaceMutation = { __typename?: "Mutation" } & {
  inviteGuestsToWorkspace: { __typename?: "Workspace" } & WorkspaceFragment;
};

export type PromoteWorkspaceGuestToMemberMutationVariables = Exact<{
  input: PromoteWorkspaceGuestToMemberInput;
}>;

export type PromoteWorkspaceGuestToMemberMutation = {
  __typename?: "Mutation";
} & {
  promoteWorkspaceGuestToMember: {
    __typename?: "Workspace";
  } & WorkspaceFragment;
};

export type RemoveWorkspaceGuestMutationVariables = Exact<{
  input: RemoveWorkspaceGuestInput;
}>;

export type RemoveWorkspaceGuestMutation = { __typename?: "Mutation" } & {
  removeWorkspaceGuest: { __typename?: "User" } & Pick<User, "_id">;
};

export type UpdateWorkspaceProfileMutationVariables = Exact<{
  input: UpdateWorkspaceProfileInput;
}>;

export type UpdateWorkspaceProfileMutation = { __typename?: "Mutation" } & {
  updateWorkspaceProfile: { __typename?: "Workspace" } & Pick<
    Workspace,
    "name" | "logoFileId"
  >;
};

export type FeedbackCycleFragment = { __typename?: "FeedbackCycle" } & Pick<
  FeedbackCycle,
  "_id"
> & {
    responses: Array<
      { __typename?: "FeedbackResponse" } & Pick<
        FeedbackResponse,
        | "completedTime"
        | "status"
        | "commitment"
        | "impact"
        | "learning"
        | "whatNext"
      > & {
          user: { __typename?: "User" } & Pick<
            User,
            "_id" | "displayName" | "avatar"
          >;
        }
    >;
  };

export type GetFeedbackQueryVariables = Exact<{
  params: FeedbackParams;
}>;

export type GetFeedbackQuery = { __typename?: "Query" } & {
  feedback: { __typename?: "FeedbackCycle" } & FeedbackCycleFragment;
};

export type GetAgreementTypesCountsQueryVariables = Exact<{
  agreementsParams: AgreementsParams;
}>;

export type GetAgreementTypesCountsQuery = { __typename?: "Query" } & {
  draftsCount: { __typename?: "AgreementCount" } & Pick<
    AgreementCount,
    "total"
  >;
  activeAgreementsCount: {
    __typename?: "AgreementWithNeedsActionCount";
  } & Pick<AgreementWithNeedsActionCount, "total" | "needsAction">;
  proposalsCount: { __typename?: "AgreementWithNeedsActionCount" } & Pick<
    AgreementWithNeedsActionCount,
    "total" | "needsAction"
  >;
  templatesCount: { __typename?: "AgreementCount" } & Pick<
    AgreementCount,
    "total"
  >;
  allCollectionsCount: { __typename?: "CollectionCount" } & Pick<
    CollectionCount,
    "total"
  >;
};

export type ActivityAgreementSummaryFragment = {
  __typename?: "AgreementActivity";
} & Pick<AgreementActivity, "type" | "endDate"> & {
    agreement: { __typename?: "Agreement" } & Pick<
      Agreement,
      "_id" | "status"
    > & {
        workingDocument: { __typename?: "DocumentBase" } & Pick<
          DocumentBase,
          "title"
        >;
      };
  };

export type ActivityProposalSummaryFragment = {
  __typename?: "ProposalActivity";
} & Pick<ProposalActivity, "type" | "endDate"> & {
    proposal: { __typename?: "Proposal" } & Pick<
      Proposal,
      "_id" | "round" | "status"
    > & {
        workingDocument: { __typename?: "DocumentBase" } & Pick<
          DocumentBase,
          "title"
        >;
        proposer: { __typename?: "User" } & Pick<User, "displayName">;
      };
  };

export type ParticipantRoleFragment = { __typename?: "ParticipantRole" } & Pick<
  ParticipantRole,
  | "displayName"
  | "linkedEntityId"
  | "type"
  | "participatesAs"
  | "needsAcknowledgement"
  | "inviteEmail"
  | "inviteAsGuest"
  | "avatar"
  | "icon"
> & {
    members?: Maybe<
      Array<
        { __typename?: "Member" } & {
          user: { __typename?: "User" } & Pick<User, "_id">;
        }
      >
    >;
  };

export type PopulatedCollectionFragment = { __typename?: "Collection" } & Pick<
  Collection,
  | "_id"
  | "archived"
  | "name"
  | "description"
  | "style"
  | "exploreLandingFeaturedOrder"
  | "exploreLandingPageOrder"
  | "visibility"
> & {
    discoveryMetadata?: Maybe<
      { __typename?: "DiscoveryMetadata" } & Pick<
        DiscoveryMetadata,
        | "creatorProfileId"
        | "worksWellWith"
        | "description"
        | "descriptionText"
        | "audience"
        | "audienceText"
        | "orgType"
        | "teamType"
      >
    >;
    templates: Array<
      { __typename?: "Draft" } & Pick<
        Draft,
        | "_id"
        | "agreementType"
        | "context"
        | "feedbackFrequency"
        | "expirationDuration"
      > & {
          agreementProperties?: Maybe<
            { __typename?: "AgreementProperties" } & {
              predefined: {
                __typename?: "PredefinedAgreementProperties";
              } & Pick<
                PredefinedAgreementProperties,
                "agreementType" | "feedbackFrequency" | "expirationDuration"
              >;
            }
          >;
          discoveryMetadata?: Maybe<
            { __typename?: "DiscoveryMetadata" } & Pick<
              DiscoveryMetadata,
              | "creatorProfileId"
              | "worksWellWith"
              | "description"
              | "descriptionText"
              | "audience"
              | "audienceText"
              | "orgType"
              | "teamType"
            >
          >;
          workingDocument: { __typename?: "DocumentBase" } & Pick<
            DocumentBase,
            "title" | "subtitle" | "body"
          >;
        }
    >;
  };

export type PopulatedTemplateFragment = { __typename?: "Draft" } & Pick<
  Draft,
  | "_id"
  | "context"
  | "status"
  | "template"
  | "feedbackFrequency"
  | "agreementType"
  | "expirationDuration"
  | "visibility"
> & {
    discoveryMetadata?: Maybe<
      { __typename?: "DiscoveryMetadata" } & Pick<
        DiscoveryMetadata,
        | "creatorProfileId"
        | "worksWellWith"
        | "description"
        | "descriptionText"
        | "audience"
        | "audienceText"
        | "orgType"
        | "teamType"
      > & {
          creatorProfile?: Maybe<
            { __typename?: "CreatorProfile" } & Pick<
              CreatorProfile,
              | "_id"
              | "name"
              | "bio"
              | "logoImageFileId"
              | "createdByUserId"
              | "twitterProfileURL"
              | "linkedInProfileURL"
              | "facebookProfileURL"
              | "instagramProfileURL"
              | "websiteURL"
            >
          >;
          worksWellWithTemplates?: Maybe<
            Array<{ __typename?: "Draft" } & TemplateFragment>
          >;
        }
    >;
    agreementProperties?: Maybe<
      { __typename?: "AgreementProperties" } & {
        predefined: { __typename?: "PredefinedAgreementProperties" } & Pick<
          PredefinedAgreementProperties,
          "agreementType" | "expirationDuration" | "feedbackFrequency"
        >;
      }
    >;
    workingDocument: { __typename?: "DocumentBase" } & Pick<
      DocumentBase,
      "title" | "subtitle" | "body"
    >;
  };

export type Acknowledge_AgreementFragment = { __typename?: "Agreement" } & Pick<
  Agreement,
  "_id" | "needsAcknowledgment"
> & {
    acknowledgers: Array<
      { __typename?: "Acknowledger" } & Pick<
        Acknowledger,
        "userId" | "date"
      > & {
          user: { __typename?: "SafeUser" } & Pick<
            SafeUser,
            "_id" | "displayName" | "avatar"
          >;
        }
    >;
  };

export type AgreementFragment = { __typename?: "Agreement" } & Pick<
  Agreement,
  | "_id"
  | "context"
  | "needsAcknowledgment"
  | "proposalVersion"
  | "activeVersion"
  | "status"
  | "expirationDuration"
  | "visibility"
  | "discoverable"
  | "feedbackFrequency"
  | "agreementType"
  | "version"
> & {
    acknowledgers: Array<
      { __typename?: "Acknowledger" } & Pick<
        Acknowledger,
        "userId" | "date"
      > & {
          user: { __typename?: "SafeUser" } & Pick<
            SafeUser,
            "_id" | "displayName" | "avatar"
          >;
        }
    >;
    parent?: Maybe<
      { __typename?: "Agreement" } & Pick<
        Agreement,
        "version" | "activeVersion"
      >
    >;
    roles: Array<{ __typename?: "ParticipantRole" } & ParticipantRoleFragment>;
    agreementProperties?: Maybe<
      { __typename?: "AgreementProperties" } & {
        predefined: { __typename?: "PredefinedAgreementProperties" } & Pick<
          PredefinedAgreementProperties,
          "agreementType" | "feedbackFrequency" | "expirationDuration"
        >;
      }
    >;
    workspace: { __typename?: "Workspace" } & Pick<Workspace, "_id" | "name">;
    workingDocument: { __typename?: "DocumentBase" } & Pick<
      DocumentBase,
      "title" | "subtitle" | "body"
    >;
    governs: { __typename?: "Governed" } & {
      users: Array<
        { __typename?: "SafeUser" } & Pick<
          SafeUser,
          "_id" | "displayName" | "avatar"
        >
      >;
      groups: Array<
        { __typename?: "SafeGroup" } & Pick<
          SafeGroup,
          "_id" | "name" | "type" | "icon" | "description"
        >
      >;
    };
    participants: Array<
      { __typename?: "Participant" } & Pick<
        Participant,
        "userId" | "decision" | "role"
      > & {
          user: { __typename?: "SafeUser" } & Pick<
            SafeUser,
            "_id" | "displayName" | "avatar"
          >;
        }
    >;
    proposer: { __typename?: "User" } & Pick<
      User,
      "_id" | "displayName" | "avatar" | "email"
    >;
    feedback?: Maybe<
      { __typename?: "Feedback" } & Pick<Feedback, "active" | "cycles">
    >;
  };

export type Agreement_RolesFragment = { __typename?: "Agreement" } & Pick<
  Agreement,
  "_id" | "needsAcknowledgment"
> & {
    roles: Array<{ __typename?: "ParticipantRole" } & ParticipantRoleFragment>;
    acknowledgers: Array<
      { __typename?: "Acknowledger" } & Pick<
        Acknowledger,
        "userId" | "date"
      > & {
          user: { __typename?: "SafeUser" } & Pick<
            SafeUser,
            "_id" | "displayName" | "avatar"
          >;
        }
    >;
  };

export type AgreementSummaryFragment = { __typename?: "Agreement" } & Pick<
  Agreement,
  | "_id"
  | "status"
  | "agreementType"
  | "version"
  | "needsFeedback"
  | "needsAcknowledgment"
> & {
    workingDocument: { __typename?: "DocumentBase" } & Pick<
      DocumentBase,
      "title" | "subtitle" | "body"
    >;
    workspace: { __typename?: "Workspace" } & Pick<Workspace, "name" | "_id">;
    proposer: { __typename?: "User" } & Pick<
      User,
      "_id" | "displayName" | "avatar"
    >;
    governs: { __typename?: "Governed" } & {
      users: Array<
        { __typename?: "SafeUser" } & Pick<
          SafeUser,
          "_id" | "displayName" | "avatar"
        >
      >;
      groups: Array<
        { __typename?: "SafeGroup" } & Pick<
          SafeGroup,
          "_id" | "name" | "type" | "icon" | "description"
        >
      >;
    };
    roles: Array<{ __typename?: "ParticipantRole" } & ParticipantRoleFragment>;
  };

export type DraftFragment = { __typename?: "Draft" } & Pick<
  Draft,
  | "_id"
  | "context"
  | "proposalVersion"
  | "activeVersion"
  | "draftURL"
  | "status"
  | "version"
  | "visibility"
  | "template"
  | "feedbackFrequency"
  | "agreementType"
  | "expirationDuration"
  | "draftVisibleToWorkspace"
> & {
    discoveryMetadata?: Maybe<
      { __typename?: "DiscoveryMetadata" } & Pick<
        DiscoveryMetadata,
        | "creatorProfileId"
        | "worksWellWith"
        | "description"
        | "descriptionText"
        | "audience"
        | "audienceText"
        | "orgType"
        | "teamType"
      >
    >;
    agreementProperties?: Maybe<
      { __typename?: "AgreementProperties" } & {
        predefined: { __typename?: "PredefinedAgreementProperties" } & Pick<
          PredefinedAgreementProperties,
          "agreementType" | "feedbackFrequency" | "expirationDuration"
        >;
      }
    >;
    workspace: { __typename?: "Workspace" } & Pick<Workspace, "_id">;
    workingDocument: { __typename?: "DocumentBase" } & Pick<
      DocumentBase,
      "title" | "subtitle" | "body"
    >;
    parent?: Maybe<
      { __typename?: "Agreement" } & Pick<
        Agreement,
        "activeVersion" | "version"
      > & {
          roles: Array<
            { __typename?: "ParticipantRole" } & ParticipantRoleFragment
          >;
        }
    >;
    otherUsersDraftingFromParent?: Maybe<
      Array<{ __typename?: "SafeUser" } & Pick<SafeUser, "_id" | "displayName">>
    >;
    proposer: { __typename?: "User" } & Pick<
      User,
      "_id" | "displayName" | "avatar" | "email"
    >;
  };

export type DraftSummaryFragment = { __typename?: "Draft" } & Pick<
  Draft,
  "_id" | "status" | "template" | "updatedAt" | "version" | "visibility"
> & {
    parent?: Maybe<
      { __typename?: "Agreement" } & Pick<Agreement, "_id"> & {
          workingDocument: { __typename?: "DocumentBase" } & Pick<
            DocumentBase,
            "title"
          >;
        }
    >;
    workingDocument: { __typename?: "DocumentBase" } & Pick<
      DocumentBase,
      "title" | "subtitle" | "body"
    >;
    proposerText?: Maybe<
      { __typename?: "DocumentBase" } & Pick<
        DocumentBase,
        "title" | "subtitle" | "body"
      >
    >;
    discoveryMetadata?: Maybe<
      { __typename?: "DiscoveryMetadata" } & Pick<
        DiscoveryMetadata,
        | "creatorProfileId"
        | "worksWellWith"
        | "description"
        | "descriptionText"
        | "audience"
        | "audienceText"
        | "orgType"
        | "teamType"
      >
    >;
  };

export type GovernsFragment = { __typename?: "Governed" } & {
  users: Array<
    { __typename?: "SafeUser" } & Pick<
      SafeUser,
      "_id" | "displayName" | "avatar"
    >
  >;
  groups: Array<
    { __typename?: "SafeGroup" } & Pick<
      SafeGroup,
      "_id" | "name" | "type" | "icon" | "description"
    >
  >;
};

export type GovernsWithMembersFragment = { __typename?: "Governed" } & {
  users: Array<
    { __typename?: "SafeUser" } & Pick<
      SafeUser,
      "_id" | "displayName" | "avatar"
    >
  >;
  groups: Array<
    { __typename?: "SafeGroup" } & Pick<
      SafeGroup,
      "_id" | "name" | "type" | "icon" | "description"
    > & {
        members?: Maybe<
          Array<
            { __typename?: "Member" } & {
              user: { __typename?: "User" } & Pick<User, "_id">;
            }
          >
        >;
      }
  >;
};

export type PopulatedGroupFragment = { __typename?: "Group" } & Pick<
  Group,
  "_id" | "name" | "icon" | "type" | "description" | "visibility"
> & {
    notificationPreferences: Array<
      { __typename?: "GroupNotificationPreference" } & Pick<
        GroupNotificationPreference,
        "type" | "enabled" | "channelId" | "channelUrl"
      >
    >;
    workspace: { __typename?: "Workspace" } & WorkspaceFragment;
    members: Array<{ __typename?: "Member" } & FullMemberFragment>;
  };

export type GroupFragment = { __typename?: "Group" } & Pick<
  Group,
  "_id" | "name" | "icon" | "type" | "description" | "visibility"
> & {
    workspace: { __typename?: "Workspace" } & WorkspaceFragment;
    members: Array<
      { __typename?: "Member" } & Pick<Member, "_id" | "permission"> & {
          user: { __typename?: "User" } & Pick<User, "_id">;
        }
    >;
  };

export type InteractionFragment = { __typename?: "Interaction" } & Pick<
  Interaction,
  | "content"
  | "_id"
  | "createdAt"
  | "updatedAt"
  | "propertyFor"
  | "type"
  | "proposalId"
  | "finalizeCycle"
  | "acknowledgedByProposer"
> & {
    range?: Maybe<
      { __typename?: "Range" } & {
        anchor: { __typename?: "Point" } & Pick<Point, "path" | "offset">;
        focus: { __typename?: "Point" } & Pick<Point, "path" | "offset">;
      }
    >;
    user: { __typename?: "SafeUser" } & Pick<
      SafeUser,
      "displayName" | "_id" | "avatar"
    >;
    response?: Maybe<
      { __typename?: "InteractionResponse" } & Pick<
        InteractionResponse,
        "content" | "createdAt" | "updatedAt"
      > & {
          user: { __typename?: "SafeUser" } & Pick<
            SafeUser,
            "avatar" | "displayName" | "_id"
          >;
        }
    >;
    reactions?: Maybe<
      Array<
        { __typename?: "Reaction" } & Pick<Reaction, "type"> & {
            user: { __typename?: "SafeUser" } & Pick<
              SafeUser,
              "_id" | "displayName"
            >;
          }
      >
    >;
  };

export type InteractionReactionFragment = { __typename?: "Interaction" } & Pick<
  Interaction,
  "_id"
> & {
    reactions?: Maybe<
      Array<
        { __typename?: "Reaction" } & Pick<Reaction, "type"> & {
            user: { __typename?: "SafeUser" } & Pick<
              SafeUser,
              "_id" | "displayName"
            >;
          }
      >
    >;
  };

export type InteractionResponseFragment = { __typename?: "Interaction" } & Pick<
  Interaction,
  "_id" | "proposalId"
> & {
    response?: Maybe<
      { __typename?: "InteractionResponse" } & Pick<
        InteractionResponse,
        "content" | "createdAt" | "updatedAt"
      > & {
          user: { __typename?: "SafeUser" } & Pick<
            SafeUser,
            "avatar" | "displayName" | "_id"
          >;
        }
    >;
  };

export type FullMemberFragment = { __typename?: "Member" } & Pick<
  Member,
  "_id" | "permission"
> & {
    user: { __typename?: "User" } & Pick<
      User,
      "avatar" | "displayName" | "_id" | "email" | "lastActive" | "joined"
    >;
  };

export type NotificationFragment = { __typename?: "Notification" } & Pick<
  Notification,
  | "_id"
  | "content"
  | "createdAt"
  | "description"
  | "isDismissed"
  | "kind"
  | "updatedAt"
  | "userId"
  | "workspaceId"
>;

export type ProposalFragment = { __typename?: "Proposal" } & Pick<
  Proposal,
  | "_id"
  | "context"
  | "activeVersion"
  | "isStale"
  | "status"
  | "round"
  | "version"
  | "visibility"
  | "activePlayer"
  | "expirationDuration"
  | "nextAdvancement"
  | "feedbackFrequency"
  | "fastTrack"
  | "agreementType"
  | "inviteLink"
  | "inviteLinkParticipationRole"
  | "finalizeCycle"
> & {
    parent?: Maybe<
      { __typename?: "Agreement" } & Pick<
        Agreement,
        "version" | "activeVersion" | "expirationDate"
      > & {
          roles: Array<
            { __typename?: "ParticipantRole" } & ParticipantRoleFragment
          >;
        }
    >;
    smartEvents?: Maybe<
      Array<{ __typename?: "SmartEvent" } & Pick<SmartEvent, "eventAction">>
    >;
    agreementProperties?: Maybe<
      { __typename?: "AgreementProperties" } & {
        predefined: { __typename?: "PredefinedAgreementProperties" } & Pick<
          PredefinedAgreementProperties,
          "agreementType" | "feedbackFrequency" | "expirationDuration"
        >;
      }
    >;
    workingDocument: { __typename?: "DocumentBase" } & Pick<
      DocumentBase,
      "title" | "subtitle" | "body"
    >;
    workspace: { __typename?: "Workspace" } & Pick<Workspace, "_id" | "name">;
    interactions?: Maybe<
      Array<{ __typename?: "Interaction" } & InteractionFragment>
    >;
    participants: Array<
      { __typename?: "Participant" } & Pick<
        Participant,
        "userId" | "role" | "reaction" | "status" | "decision"
      > & {
          user: { __typename?: "SafeUser" } & Pick<
            SafeUser,
            "_id" | "displayName" | "avatar"
          >;
          roundStatus?: Maybe<
            { __typename?: "ParticipantRoundStatus" } & Pick<
              ParticipantRoundStatus,
              "understand" | "improve" | "finalize"
            >
          >;
          onBehalfOf: { __typename?: "Governed" } & GovernsFragment;
        }
    >;
    governs: { __typename?: "Governed" } & GovernsFragment;
    roles: Array<{ __typename?: "ParticipantRole" } & ParticipantRoleFragment>;
    proposer: { __typename?: "User" } & Pick<
      User,
      "_id" | "displayName" | "avatar" | "email"
    >;
    documents?: Maybe<
      Array<
        { __typename?: "Document" } & Pick<
          Document,
          "title" | "subtitle" | "body" | "round"
        >
      >
    >;
  };

export type ProposalSummaryFragment = { __typename?: "Proposal" } & Pick<
  Proposal,
  | "_id"
  | "needsUserAction"
  | "isStale"
  | "nextAdvancement"
  | "status"
  | "round"
  | "activePlayer"
  | "agreementType"
> & {
    workingDocument: { __typename?: "DocumentBase" } & Pick<
      DocumentBase,
      "title" | "subtitle"
    >;
    proposerText?: Maybe<
      { __typename?: "DocumentBase" } & Pick<
        DocumentBase,
        "title" | "subtitle" | "body"
      >
    >;
    participantText?: Maybe<
      { __typename?: "DocumentBase" } & Pick<
        DocumentBase,
        "title" | "subtitle" | "body"
      >
    >;
    parent?: Maybe<
      { __typename?: "Agreement" } & Pick<Agreement, "_id"> & {
          workingDocument: { __typename?: "DocumentBase" } & Pick<
            DocumentBase,
            "title"
          >;
        }
    >;
    proposer: { __typename?: "User" } & Pick<
      User,
      "_id" | "displayName" | "avatar"
    >;
    governs: { __typename?: "Governed" } & GovernsFragment;
    participants: Array<
      { __typename?: "Participant" } & {
        user: { __typename?: "SafeUser" } & Pick<SafeUser, "_id">;
      }
    >;
  };

export type PublicAgreementSummaryFragment = {
  __typename?: "Agreement";
} & Pick<Agreement, "_id" | "status" | "agreementType"> & {
    workingDocument: { __typename?: "DocumentBase" } & Pick<
      DocumentBase,
      "title" | "subtitle"
    >;
    workspace: { __typename?: "Workspace" } & Pick<Workspace, "name" | "_id">;
  };

export type TemplateFragment = { __typename?: "Draft" } & Pick<
  Draft,
  | "_id"
  | "status"
  | "template"
  | "feedbackFrequency"
  | "agreementType"
  | "expirationDuration"
  | "visibility"
> & {
    discoveryMetadata?: Maybe<
      { __typename?: "DiscoveryMetadata" } & Pick<
        DiscoveryMetadata,
        | "creatorProfileId"
        | "worksWellWith"
        | "description"
        | "descriptionText"
        | "audience"
        | "audienceText"
        | "orgType"
        | "teamType"
      >
    >;
    agreementProperties?: Maybe<
      { __typename?: "AgreementProperties" } & {
        predefined: { __typename?: "PredefinedAgreementProperties" } & Pick<
          PredefinedAgreementProperties,
          "agreementType" | "expirationDuration" | "feedbackFrequency"
        >;
      }
    >;
    workingDocument: { __typename?: "DocumentBase" } & Pick<
      DocumentBase,
      "title" | "subtitle" | "body"
    >;
  };

export type TemplateSummaryFragment = { __typename?: "Draft" } & Pick<
  Draft,
  "_id"
> & {
    workingDocument: { __typename?: "DocumentBase" } & Pick<
      DocumentBase,
      "title" | "subtitle"
    >;
  };

export type UserFragment = { __typename?: "User" } & Pick<
  User,
  "_id" | "email" | "displayName" | "avatar" | "joined"
> & {
    workspaceInvitations: Array<
      { __typename?: "Invitation" } & Pick<Invitation, "_id"> & {
          workspace: { __typename?: "Workspace" } & Pick<
            Workspace,
            "name" | "_id"
          >;
        }
    >;
    proposalInvitations: Array<
      { __typename?: "ProposalInvitation" } & Pick<
        ProposalInvitation,
        "linkId"
      > & {
          workspace: { __typename?: "Workspace" } & Pick<
            Workspace,
            "name" | "_id"
          >;
        }
    >;
    notificationPreferences: Array<
      { __typename?: "NotificationPreference" } & Pick<
        NotificationPreference,
        "enabled" | "type"
      > & { workspace: { __typename?: "Workspace" } & Pick<Workspace, "_id"> }
    >;
    workspaces: Array<
      { __typename?: "Workspace" } & Pick<
        Workspace,
        "_id" | "name" | "cohortId"
      >
    >;
    guestOfWorkspaces: Array<
      { __typename?: "Workspace" } & Pick<
        Workspace,
        "_id" | "name" | "cohortId"
      >
    >;
  };

export type UserWithSessionFragment = { __typename?: "UserWithSession" } & Pick<
  UserWithSession,
  | "_id"
  | "email"
  | "displayName"
  | "avatar"
  | "joined"
  | "sessionExpiresAt"
  | "featuresSeen"
  | "walletId"
> & {
    workspaceInvitations: Array<
      { __typename?: "Invitation" } & Pick<
        Invitation,
        "_id" | "workspaceGuest"
      > & {
          workspace: { __typename?: "Workspace" } & Pick<
            Workspace,
            "name" | "_id"
          >;
        }
    >;
    proposalInvitations: Array<
      { __typename?: "ProposalInvitation" } & Pick<
        ProposalInvitation,
        "linkId" | "workspaceGuest"
      > & {
          workspace: { __typename?: "Workspace" } & Pick<
            Workspace,
            "name" | "_id"
          >;
        }
    >;
    notificationPreferences: Array<
      { __typename?: "NotificationPreference" } & Pick<
        NotificationPreference,
        "enabled" | "type"
      > & { workspace: { __typename?: "Workspace" } & Pick<Workspace, "_id"> }
    >;
    preferences?: Maybe<
      { __typename?: "UserPreferences" } & Pick<
        UserPreferences,
        "lastUsedEmojiSkinTone" | "dontShowWhatHappensWhenIProposeHelp"
      >
    >;
    workspaces: Array<
      { __typename?: "Workspace" } & Pick<
        Workspace,
        "_id" | "name" | "cohortId"
      >
    >;
    guestOfWorkspaces: Array<
      { __typename?: "Workspace" } & Pick<
        Workspace,
        "_id" | "name" | "cohortId"
      >
    >;
    checklists?: Maybe<
      Array<
        { __typename?: "Checklist" } & Pick<
          Checklist,
          "workspaceId" | "completedItems"
        >
      >
    >;
  };

export type WorkspaceFragment = { __typename?: "Workspace" } & Pick<
  Workspace,
  | "_id"
  | "createdAt"
  | "name"
  | "integrationsInstalled"
  | "integrationsRequiringReinstall"
  | "logoFileId"
  | "cohortId"
> & {
    guests: Array<
      { __typename?: "User" } & Pick<
        User,
        "avatar" | "displayName" | "_id" | "email" | "lastActive" | "joined"
      >
    >;
    invitedEmails: Array<
      { __typename?: "EmailInvite" } & Pick<
        EmailInvite,
        "email" | "workspaceGuest"
      >
    >;
    billingAdmins: Array<{ __typename?: "User" } & Pick<User, "_id">>;
    allMembersGroup: { __typename?: "Group" } & Pick<Group, "_id" | "name"> & {
        members: Array<{ __typename?: "Member" } & FullMemberFragment>;
      };
  };

export type WorkspaceMergeDataFragment = {
  __typename?: "WorkspaceMergeData";
} & {
  membersChanges: { __typename?: "MembersChanges" } & {
    added: Array<
      { __typename?: "Member" } & Pick<Member, "_id"> & {
          user: { __typename?: "User" } & Pick<
            User,
            "_id" | "displayName" | "avatar"
          >;
        }
    >;
    existing: Array<
      { __typename?: "Member" } & Pick<Member, "_id"> & {
          user: { __typename?: "User" } & Pick<
            User,
            "_id" | "displayName" | "avatar"
          >;
        }
    >;
    invites: Array<
      { __typename?: "Invitation" } & Pick<Invitation, "_id" | "email">
    >;
  };
  groupsChanges: { __typename?: "GroupsChanges" } & {
    added: Array<{ __typename?: "Group" } & Pick<Group, "_id" | "name">>;
  };
  agreementsChanges: { __typename?: "AgreementsChanges" } & {
    drafts: Array<
      { __typename?: "Draft" } & Pick<Draft, "_id"> & {
          workingDocument: { __typename?: "DocumentBase" } & Pick<
            DocumentBase,
            "title"
          >;
        }
    >;
    proposals: Array<
      { __typename?: "Proposal" } & Pick<Proposal, "_id"> & {
          workingDocument: { __typename?: "DocumentBase" } & Pick<
            DocumentBase,
            "title"
          >;
        }
    >;
    active: Array<
      { __typename?: "Agreement" } & Pick<Agreement, "_id"> & {
          workingDocument: { __typename?: "DocumentBase" } & Pick<
            DocumentBase,
            "title"
          >;
        }
    >;
    archived: Array<
      { __typename?: "Agreement" } & Pick<Agreement, "_id"> & {
          workingDocument: { __typename?: "DocumentBase" } & Pick<
            DocumentBase,
            "title"
          >;
        }
    >;
  };
};

export type AcceptInviteMutationVariables = Exact<{
  workspaceId: Scalars["String"];
}>;

export type AcceptInviteMutation = { __typename?: "Mutation" } & {
  acceptInvite: { __typename?: "UserWithSession" } & Pick<
    UserWithSession,
    "_id" | "displayName"
  > & {
      workspaceInvitations: Array<
        { __typename?: "Invitation" } & Pick<Invitation, "_id"> & {
            workspace: { __typename?: "Workspace" } & Pick<
              Workspace,
              "name" | "_id"
            >;
          }
      >;
      proposalInvitations: Array<
        { __typename?: "ProposalInvitation" } & Pick<
          ProposalInvitation,
          "linkId"
        > & {
            workspace: { __typename?: "Workspace" } & Pick<
              Workspace,
              "name" | "_id"
            >;
          }
      >;
      workspaces: Array<
        { __typename?: "Workspace" } & Pick<Workspace, "name" | "_id">
      >;
      guestOfWorkspaces: Array<
        { __typename?: "Workspace" } & Pick<Workspace, "name" | "_id">
      >;
    };
};

export type CreatePromptMutationVariables = Exact<{
  input: CreatePromptInput;
}>;

export type CreatePromptMutation = { __typename?: "Mutation" } & {
  createPrompt: { __typename?: "Prompt" } & PromptFragment;
};

export type UpdatePromptMutationVariables = Exact<{
  input: UpdatePromptInput;
}>;

export type UpdatePromptMutation = { __typename?: "Mutation" } & {
  updatePrompt: { __typename?: "Prompt" } & PromptFragment;
};

export type AcknowledgeAgreementMutationVariables = Exact<{
  input: AcknowledgeAgreementInput;
}>;

export type AcknowledgeAgreementMutation = { __typename?: "Mutation" } & {
  acknowledgeAgreement: {
    __typename?: "Agreement";
  } & Acknowledge_AgreementFragment;
};

export type AddAcknowledgersMutationVariables = Exact<{
  input: AddAcknowledgersInput;
}>;

export type AddAcknowledgersMutation = { __typename?: "Mutation" } & {
  addAcknowledgers: { __typename?: "Agreement" } & Agreement_RolesFragment;
};

export type ArchiveAgreementMutationVariables = Exact<{
  input: ArchiveAgreementInput;
}>;

export type ArchiveAgreementMutation = { __typename?: "Mutation" } & {
  archiveAgreement: { __typename?: "Agreement" } & Pick<Agreement, "_id">;
};

export type CreateDraftMutationVariables = Exact<{
  input: CreateDraftInput;
}>;

export type CreateDraftMutation = { __typename?: "Mutation" } & {
  createDraft: { __typename?: "Draft" } & Pick<Draft, "_id" | "version">;
};

export type CreateDraftVersionFromActiveAgreementMutationVariables = Exact<{
  input: CreateDraftVersionFromActiveAgreementInput;
}>;

export type CreateDraftVersionFromActiveAgreementMutation = {
  __typename?: "Mutation";
} & {
  createDraftVersionFromActiveAgreement: { __typename?: "Agreement" } & Pick<
    Agreement,
    "_id" | "version"
  > & { workspace: { __typename?: "Workspace" } & Pick<Workspace, "_id"> };
};

export type DeleteDraftMutationVariables = Exact<{
  input: DeleteDraftInput;
}>;

export type DeleteDraftMutation = { __typename?: "Mutation" } & {
  deleteDraft: { __typename?: "Draft" } & Pick<Draft, "_id">;
};

export type DraftFromExistingAgreementMutationVariables = Exact<{
  input: DraftFromExistingAgreementInput;
}>;

export type DraftFromExistingAgreementMutation = { __typename?: "Mutation" } & {
  draftFromExistingAgreement: { __typename?: "Draft" } & Pick<Draft, "_id"> & {
      workspace: { __typename?: "Workspace" } & Pick<Workspace, "_id">;
    };
};

export type ExtendExpirationDateMutationVariables = Exact<{
  input: UpdateExpirationDateInput;
}>;

export type ExtendExpirationDateMutation = { __typename?: "Mutation" } & {
  updateExpirationDate: { __typename?: "Agreement" } & Pick<
    Agreement,
    "_id" | "expirationDate"
  >;
};

export type MarkAgreementSeenMutationVariables = Exact<{
  input: MarkAgreementSeenInput;
}>;

export type MarkAgreementSeenMutation = { __typename?: "Mutation" } & {
  markAgreementSeen: { __typename?: "AgreementViewedResult" } & Pick<
    AgreementViewedResult,
    "seen"
  >;
};

export type MarkDraftAsTemplateMutationVariables = Exact<{
  _id: Scalars["String"];
}>;

export type MarkDraftAsTemplateMutation = { __typename?: "Mutation" } & {
  markDraftAsTemplate: { __typename?: "Draft" } & DraftFragment;
};

export type ProposeAgreementMutationVariables = Exact<{
  _id: Scalars["String"];
  input: ProposeDraftInput;
}>;

export type ProposeAgreementMutation = { __typename?: "Mutation" } & {
  proposeAgreement:
    | ({ __typename?: "Proposal" } & ProposalFragment)
    | ({ __typename?: "Agreement" } & AgreementFragment);
};

export type ProposeArchiveMutationVariables = Exact<{
  input: ProposeArchiveOrExtendInput;
}>;

export type ProposeArchiveMutation = { __typename?: "Mutation" } & {
  proposeArchive: { __typename?: "Proposal" } & ProposalFragment;
};

export type ProposeExtendExpirationMutationVariables = Exact<{
  input: ProposeArchiveOrExtendInput;
}>;

export type ProposeExtendExpirationMutation = { __typename?: "Mutation" } & {
  proposeExtendExpiration: { __typename?: "Proposal" } & ProposalFragment;
};

export type UnarchiveAgreementMutationVariables = Exact<{
  input: UnarchiveAgreementInput;
}>;

export type UnarchiveAgreementMutation = { __typename?: "Mutation" } & {
  unarchiveAgreement: { __typename?: "Agreement" } & Pick<Agreement, "_id">;
};

export type UpdateAgreementPropertiesMutationVariables = Exact<{
  input: UpdatePropertiesInput;
}>;

export type UpdateAgreementPropertiesMutation = { __typename?: "Mutation" } & {
  updateAgreementProperties: { __typename?: "Agreement" } & AgreementFragment;
};

export type UpdateDraftMutationVariables = Exact<{
  _id: Scalars["String"];
  input: DraftInput;
}>;

export type UpdateDraftMutation = { __typename?: "Mutation" } & {
  updateDraft: { __typename?: "Draft" } & DraftFragment;
};

export type UpdateTemplateMutationVariables = Exact<{
  _id: Scalars["String"];
  input: DraftInput;
}>;

export type UpdateTemplateMutation = { __typename?: "Mutation" } & {
  updateTemplate: { __typename?: "Draft" } & DraftFragment;
};

export type UpdateTemplatePropertiesMutationVariables = Exact<{
  input: UpdatePropertiesInput;
}>;

export type UpdateTemplatePropertiesMutation = { __typename?: "Mutation" } & {
  updateTemplateProperties: { __typename?: "Draft" } & DraftFragment;
};

export type CreateCollectionMutationVariables = Exact<{ [key: string]: never }>;

export type CreateCollectionMutation = { __typename?: "Mutation" } & {
  createCollection: { __typename?: "Collection" } & PopulatedCollectionFragment;
};

export type UpdateCollectionMutationVariables = Exact<{
  input: UpdateCollectionInput;
}>;

export type UpdateCollectionMutation = { __typename?: "Mutation" } & {
  updateCollection: { __typename?: "Collection" } & PopulatedCollectionFragment;
};

export type CreateWorkspaceMutationVariables = Exact<{
  input: CreateWorkspaceInput;
}>;

export type CreateWorkspaceMutation = { __typename?: "Mutation" } & {
  createWorkspace: { __typename?: "Workspace" } & Pick<
    Workspace,
    "_id" | "name"
  >;
};

export type DeleteInvitationMutationVariables = Exact<{
  input: DeleteInvitationInput;
}>;

export type DeleteInvitationMutation = { __typename?: "Mutation" } & {
  deleteInvitation: { __typename?: "Workspace" } & Pick<Workspace, "_id"> & {
      invitedEmails: Array<
        { __typename?: "EmailInvite" } & Pick<
          EmailInvite,
          "email" | "workspaceGuest"
        >
      >;
    };
};

export type UpdateDraftPropertiesMutationVariables = Exact<{
  input: UpdatePropertiesInput;
}>;

export type UpdateDraftPropertiesMutation = { __typename?: "Mutation" } & {
  updateDraftProperties: { __typename?: "Draft" } & DraftFragment;
};

export type AddGroupNotificationPreferenceMutationVariables = Exact<{
  input: AddGroupNotificationPreferenceInput;
}>;

export type AddGroupNotificationPreferenceMutation = {
  __typename?: "Mutation";
} & {
  addGroupNotificationPreference: {
    __typename?: "GroupNotificationPreference";
  } & Pick<
    GroupNotificationPreference,
    "type" | "channelUrl" | "channelId" | "groupId"
  >;
};

export type AddMembersToGroupMutationVariables = Exact<{
  input: AddMembersToGroupInput;
}>;

export type AddMembersToGroupMutation = { __typename?: "Mutation" } & {
  addMembersToGroup: { __typename?: "Group" } & Pick<
    Group,
    "_id" | "name" | "description"
  > & { members: Array<{ __typename?: "Member" } & FullMemberFragment> };
};

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;

export type CreateGroupMutation = { __typename?: "Mutation" } & {
  createGroup: { __typename?: "Group" } & PopulatedGroupFragment;
};

export type DeleteGroupMutationVariables = Exact<{
  input: DeleteGroupInput;
}>;

export type DeleteGroupMutation = { __typename?: "Mutation" } & {
  deleteGroup: { __typename?: "Group" } & Pick<Group, "_id">;
};

export type RemoveGroupNotificationPreferenceMutationVariables = Exact<{
  input: RemoveGroupNotificationPreferenceInput;
}>;

export type RemoveGroupNotificationPreferenceMutation = {
  __typename?: "Mutation";
} & {
  removeGroupNotificationPreference: {
    __typename?: "GroupNotificationPreference";
  } & Pick<GroupNotificationPreference, "type" | "channelId" | "groupId">;
};

export type RemoveMembersFromGroupMutationVariables = Exact<{
  input: RemoveMembersFromGroupInput;
}>;

export type RemoveMembersFromGroupMutation = { __typename?: "Mutation" } & {
  removeMembersFromGroup: { __typename?: "Group" } & Pick<
    Group,
    "_id" | "name"
  > & { members: Array<{ __typename?: "Member" } & FullMemberFragment> };
};

export type UpdateGroupSettingsMutationVariables = Exact<{
  input: UpdateGroupSettingsInput;
}>;

export type UpdateGroupSettingsMutation = { __typename?: "Mutation" } & {
  updateGroupSettings: { __typename?: "Group" } & Pick<
    Group,
    "_id" | "name" | "description"
  > & { members: Array<{ __typename?: "Member" } & FullMemberFragment> };
};

export type UpdateMemberRoleMutationVariables = Exact<{
  input: UpdateMemberRoleInput;
}>;

export type UpdateMemberRoleMutation = { __typename?: "Mutation" } & {
  updateMemberRole: { __typename?: "Group" } & Pick<
    Group,
    "_id" | "name" | "description"
  > & { members: Array<{ __typename?: "Member" } & FullMemberFragment> };
};

export type MergeWorkspacesMutationVariables = Exact<{
  input: MergeWorkspacesInput;
}>;

export type MergeWorkspacesMutation = { __typename?: "Mutation" } & {
  mergeWorkspaces?: Maybe<
    { __typename?: "WorkspaceMergeData" } & WorkspaceMergeDataFragment
  >;
};

export type AddInteractionReactionMutationVariables = Exact<{
  input: UpdateInteractionReactionInput;
}>;

export type AddInteractionReactionMutation = { __typename?: "Mutation" } & {
  addInteractionReaction: {
    __typename?: "Interaction";
  } & InteractionReactionFragment;
};

export type AdvanceProposalForProposerMutationVariables = Exact<{
  input: AdvanceProposalForProposerInput;
}>;

export type AdvanceProposalForProposerMutation = { __typename?: "Mutation" } & {
  advanceProposalForProposer: { __typename?: "Proposal" } & ProposalFragment;
};

export type CreateInteractionMutationVariables = Exact<{
  input: CreateInteractionInput;
}>;

export type CreateInteractionMutation = { __typename?: "Mutation" } & {
  createInteraction: { __typename?: "Interaction" } & InteractionFragment;
};

export type CreateInteractionResponseMutationVariables = Exact<{
  input: CreateInteractionResponseInput;
}>;

export type CreateInteractionResponseMutation = { __typename?: "Mutation" } & {
  createInteractionResponse: {
    __typename?: "Interaction";
  } & InteractionResponseFragment;
};

export type DelayProposalAdvancementMutationVariables = Exact<{
  input: DelayProposalAdvancementInput;
}>;

export type DelayProposalAdvancementMutation = { __typename?: "Mutation" } & {
  delayProposalAdvancement: { __typename?: "Proposal" } & ProposalFragment;
};

export type DeleteInteractionFromProposalMutationVariables = Exact<{
  input: DeleteInteractionInput;
}>;

export type DeleteInteractionFromProposalMutation = {
  __typename?: "Mutation";
} & {
  deleteInteraction: { __typename?: "Interaction" } & Pick<
    Interaction,
    "_id" | "proposalId"
  >;
};

export type DeleteInteractionResponseMutationVariables = Exact<{
  input: DeleteInteractionResponseInput;
}>;

export type DeleteInteractionResponseMutation = { __typename?: "Mutation" } & {
  deleteInteractionResponse: { __typename?: "Interaction" } & Pick<
    Interaction,
    "_id" | "proposalId"
  >;
};

export type AdvanceProposalForParticipantMutationVariables = Exact<{
  _id: Scalars["String"];
  input: AdvanceInput;
}>;

export type AdvanceProposalForParticipantMutation = {
  __typename?: "Mutation";
} & {
  advanceProposalForUser:
    | ({ __typename?: "Draft" } & DraftFragment)
    | ({ __typename?: "Proposal" } & ProposalFragment)
    | ({ __typename?: "Agreement" } & AgreementFragment);
};

export type RemoveInteractionReactionMutationVariables = Exact<{
  input: UpdateInteractionReactionInput;
}>;

export type RemoveInteractionReactionMutation = { __typename?: "Mutation" } & {
  removeInteractionReaction: {
    __typename?: "Interaction";
  } & InteractionFragment;
};

export type RevertProposalToDraftMutationVariables = Exact<{
  input: RevertProposalToDraftInput;
}>;

export type RevertProposalToDraftMutation = { __typename?: "Mutation" } & {
  revertProposalToDraft: { __typename?: "Proposal" } & Pick<
    Proposal,
    "_id" | "version"
  >;
};

export type SaveProposalEditsMutationVariables = Exact<{
  input: SaveProposalEditsInput;
}>;

export type SaveProposalEditsMutation = { __typename?: "Mutation" } & {
  saveProposalEdits: { __typename?: "SaveProposalEditsResult" } & Pick<
    SaveProposalEditsResult,
    "failedIndexes"
  >;
};

export type UndoParticipantFinishedMutationVariables = Exact<{
  input: UndoParticipantFinishedInput;
}>;

export type UndoParticipantFinishedMutation = { __typename?: "Mutation" } & {
  undoParticipantFinished: { __typename?: "Proposal" } & ProposalFragment;
};

export type UpdateInteractionMutationVariables = Exact<{
  input: UpdateInteractionInput;
}>;

export type UpdateInteractionMutation = { __typename?: "Mutation" } & {
  updateInteraction: { __typename?: "Interaction" } & InteractionFragment;
};

export type UpdateInteractionAcknowledgementMutationVariables = Exact<{
  input: UpdateInteractionAcknowledgementInput;
}>;

export type UpdateInteractionAcknowledgementMutation = {
  __typename?: "Mutation";
} & {
  updateInteractionAcknowledgement: { __typename?: "Interaction" } & Pick<
    Interaction,
    "_id" | "acknowledgedByProposer"
  >;
};

export type UpdateInteractionReactionMutationVariables = Exact<{
  input: UpdateInteractionReactionInput;
}>;

export type UpdateInteractionReactionMutation = { __typename?: "Mutation" } & {
  updateInteractionReaction: {
    __typename?: "Interaction";
  } & InteractionReactionFragment;
};

export type UpdateProposalMutationVariables = Exact<{
  input: UpdateProposalInput;
}>;

export type UpdateProposalMutation = { __typename?: "Mutation" } & {
  updateProposal: { __typename?: "Proposal" } & ProposalFragment;
};

export type UpdateProposalPropertiesMutationVariables = Exact<{
  input: UpdatePropertiesInput;
}>;

export type UpdateProposalPropertiesMutation = { __typename?: "Mutation" } & {
  updateProposalProperties: { __typename?: "Proposal" } & ProposalFragment;
};

export type RemoveMemberMutationVariables = Exact<{
  input: RemoveMemberInput;
}>;

export type RemoveMemberMutation = { __typename?: "Mutation" } & {
  removeMember: { __typename?: "Workspace" } & Pick<
    Workspace,
    "_id" | "name"
  > & {
      allMembersGroup: { __typename?: "Group" } & Pick<Group, "_id"> & {
          members: Array<{ __typename?: "Member" } & FullMemberFragment>;
        };
    };
};

export type UpdateAuthenticatedUserMutationVariables = Exact<{
  displayName: Scalars["String"];
  email: Scalars["String"];
  avatar: Scalars["String"];
}>;

export type UpdateAuthenticatedUserMutation = { __typename?: "Mutation" } & {
  updateAuthenticatedUser: { __typename?: "UserWithSession" } & Pick<
    UserWithSession,
    "avatar" | "email" | "displayName" | "_id"
  > & {
      workspaces: Array<{ __typename?: "Workspace" } & Pick<Workspace, "_id">>;
    };
};

export type MarkFeatureAsSeenByUserMutationVariables = Exact<{
  featureName: Scalars["String"];
}>;

export type MarkFeatureAsSeenByUserMutation = { __typename?: "Mutation" } & {
  markFeatureAsSeenByUser: {
    __typename?: "UserWithSession";
  } & UserWithSessionFragment;
};

export type UpdateUserPreferencesMutationVariables = Exact<{
  preferences: UpdateUserPreferencesInput;
}>;

export type UpdateUserPreferencesMutation = { __typename?: "Mutation" } & {
  updateUserPreferences: {
    __typename?: "UserWithSession";
  } & UserWithSessionFragment;
};

export type UpdateAuthenticatedUserNotificationPreferenceMutationVariables =
  Exact<{
    input: UpdatedAuthenticatedUserNotificationsInput;
  }>;

export type UpdateAuthenticatedUserNotificationPreferenceMutation = {
  __typename?: "Mutation";
} & {
  updateAuthenticatedUserNotificationPreference: {
    __typename?: "UserWithSession";
  } & Pick<UserWithSession, "_id"> & {
      notificationPreferences: Array<
        { __typename?: "NotificationPreference" } & Pick<
          NotificationPreference,
          "type" | "enabled"
        > & { workspace: { __typename?: "Workspace" } & Pick<Workspace, "_id"> }
      >;
    };
};

export type InviteMembersMutationVariables = Exact<{
  input: InviteMembersToWorkspaceInput;
}>;

export type InviteMembersMutation = { __typename?: "Mutation" } & {
  inviteMembersToWorkspace: { __typename?: "Workspace" } & Pick<
    Workspace,
    "_id" | "name"
  > & {
      invitedEmails: Array<
        { __typename?: "EmailInvite" } & Pick<
          EmailInvite,
          "email" | "workspaceGuest"
        >
      >;
    };
};

export type SetMembersBillingAdminStatusMutationVariables = Exact<{
  input: SetMembersBillingAdminStatusInput;
}>;

export type SetMembersBillingAdminStatusMutation = {
  __typename?: "Mutation";
} & {
  setMembersBillingAdminStatus: {
    __typename?: "Workspace";
  } & WorkspaceFragment;
};

export type GetAllPromptsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPromptsQuery = { __typename?: "Query" } & {
  getAllPrompts: Array<
    { __typename?: "Prompt" } & Pick<
      Prompt,
      "hasResponses" | "tags" | "disabled" | "priorityOrder"
    > &
      PromptFragment
  >;
};

export type AdminOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type AdminOverviewQuery = { __typename?: "Query" } & {
  adminWorkspaces: Array<
    { __typename?: "WorkspaceSummary" } & Pick<
      WorkspaceSummary,
      "_id" | "name"
    > & {
        agreementCounts: { __typename?: "AgreementsSummary" } & Pick<
          AgreementsSummary,
          "active" | "proposal" | "draft"
        >;
        allMembersGroup: { __typename?: "Group" } & Pick<Group, "_id"> & {
            members: Array<{ __typename?: "Member" } & FullMemberFragment>;
          };
      }
  >;
};

export type OopsQueryVariables = Exact<{ [key: string]: never }>;

export type OopsQuery = { __typename?: "Query" } & {
  oops: { __typename?: "Group" } & Pick<Group, "_id">;
};

export type PreviewWorkspacesMergeQueryVariables = Exact<{
  params: PreviewWorkspacesMergeParams;
}>;

export type PreviewWorkspacesMergeQuery = { __typename?: "Query" } & {
  previewWorkspacesMerge?: Maybe<
    { __typename?: "WorkspaceMergeData" } & WorkspaceMergeDataFragment
  >;
};

export type GetAgreementQueryVariables = Exact<{
  params: AgreementParams;
}>;

export type GetAgreementQuery = { __typename?: "Query" } & {
  agreement: { __typename?: "Agreement" } & Pick<
    Agreement,
    "expirationDate" | "userParticipationRole"
  > &
    AgreementFragment;
};

export type GetAgreementsQueryVariables = Exact<{
  agreementsParams: AgreementsParams;
}>;

export type GetAgreementsQuery = { __typename?: "Query" } & {
  agreements: Array<
    { __typename?: "Agreement" } & Pick<Agreement, "expirationDate"> &
      AgreementSummaryFragment
  >;
};

export type GetAllTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTemplatesQuery = { __typename?: "Query" } & {
  allTemplates: Array<{ __typename?: "Draft" } & DraftSummaryFragment>;
};

export type GetArchivedAgreementQueryVariables = Exact<{
  params: ArchivedAgreementParams;
}>;

export type GetArchivedAgreementQuery = { __typename?: "Query" } & {
  archivedAgreement: { __typename?: "Agreement" } & AgreementFragment;
};

export type GetArchivedAgreementsQueryVariables = Exact<{
  agreementsParams: AgreementsParams;
}>;

export type GetArchivedAgreementsQuery = { __typename?: "Query" } & {
  archivedAgreements: Array<
    { __typename?: "Agreement" } & AgreementSummaryFragment
  >;
};

export type GetDraftQueryVariables = Exact<{
  params: DraftParams;
}>;

export type GetDraftQuery = { __typename?: "Query" } & {
  draft: { __typename?: "Draft" } & DraftFragment;
};

export type GetDraftsQueryVariables = Exact<{
  agreementsParams: AgreementsParams;
}>;

export type GetDraftsQuery = { __typename?: "Query" } & {
  drafts: Array<{ __typename?: "Draft" } & DraftSummaryFragment>;
};

export type GetGroupAgreementsQueryVariables = Exact<{
  workspaceId: Scalars["String"];
  groupId: Scalars["String"];
}>;

export type GetGroupAgreementsQuery = { __typename?: "Query" } & {
  groupAgreements: Array<
    { __typename?: "Agreement" } & AgreementSummaryFragment
  >;
};

export type GetGroupProposalsQueryVariables = Exact<{
  workspaceId: Scalars["String"];
  groupId: Scalars["String"];
}>;

export type GetGroupProposalsQuery = { __typename?: "Query" } & {
  groupProposals: Array<{ __typename?: "Proposal" } & ProposalSummaryFragment>;
};

export type GetProposalQueryVariables = Exact<{
  params: AgreementParams;
}>;

export type GetProposalQuery = { __typename?: "Query" } & {
  proposal: { __typename?: "Proposal" } & ProposalFragment;
};

export type GetProposalEditsQueryVariables = Exact<{
  params: ProposalEditsForAgreementParams;
}>;

export type GetProposalEditsQuery = { __typename?: "Query" } & {
  proposalEditsForAgreement: Array<
    { __typename?: "ProposalEdit" } & Pick<
      ProposalEdit,
      | "authoredAt"
      | "_id"
      | "ulid"
      | "operation"
      | "propertyFor"
      | "round"
      | "sequence"
      | "finalizeCycle"
    >
  >;
};

export type GetProposalsQueryVariables = Exact<{
  agreementsParams: AgreementsParams;
}>;

export type GetProposalsQuery = { __typename?: "Query" } & {
  proposals: Array<{ __typename?: "Proposal" } & ProposalSummaryFragment>;
};

export type ViewAgreementQueryVariables = Exact<{
  params: AgreementParams;
}>;

export type ViewAgreementQuery = { __typename?: "Query" } & {
  agreement: { __typename?: "Agreement" } & Pick<Agreement, "seen">;
};

export type GetAllCollectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCollectionsQuery = { __typename?: "Query" } & {
  allCollections: Array<
    { __typename?: "Collection" } & PopulatedCollectionFragment
  >;
};

export type GetPublicCollectionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPublicCollectionsQuery = { __typename?: "Query" } & {
  collections: Array<
    { __typename?: "Collection" } & PopulatedCollectionFragment
  >;
};

export type ExploreQueryVariables = Exact<{
  publicAgreementParams?: Maybe<PublicAgreementsParams>;
}>;

export type ExploreQuery = { __typename?: "Query" } & {
  collections: Array<
    { __typename?: "Collection" } & PopulatedCollectionFragment
  >;
  templates: Array<{ __typename?: "Draft" } & TemplateFragment>;
  publicAgreements: Array<
    { __typename?: "Agreement" } & PublicAgreementSummaryFragment
  >;
  publicCreatorProfiles: Array<
    { __typename?: "CreatorProfile" } & CreatorProfileFragment
  >;
};

export type GetGroupQueryVariables = Exact<{
  params: GetGroupParams;
}>;

export type GetGroupQuery = { __typename?: "Query" } & {
  group: { __typename?: "Group" } & PopulatedGroupFragment;
};

export type GetGroupAgreementCountsQueryVariables = Exact<{
  params: GetGroupProposalAndAgreementCountParams;
}>;

export type GetGroupAgreementCountsQuery = { __typename?: "Query" } & {
  groupProposalAndAgreementCount: {
    __typename?: "GroupAgreementCounts";
  } & Pick<GroupAgreementCounts, "activeCount" | "proposalCount">;
};

export type GetUserWorkspaceGroupsQueryVariables = Exact<{
  params: WorkspaceGroupsParams;
}>;

export type GetUserWorkspaceGroupsQuery = { __typename?: "Query" } & {
  userWorkspaceGroups: Array<{ __typename?: "Group" } & GroupFragment>;
};

export type GetWorkspaceGroupsQueryVariables = Exact<{
  params: WorkspaceGroupsParams;
}>;

export type GetWorkspaceGroupsQuery = { __typename?: "Query" } & {
  workspaceGroups: Array<{ __typename?: "Group" } & GroupFragment>;
};

export type GetAllWorkspaceGroupsQueryVariables = Exact<{
  params: WorkspaceGroupsParams;
}>;

export type GetAllWorkspaceGroupsQuery = { __typename?: "Query" } & {
  allWorkspaceGroups: Array<{ __typename?: "Group" } & GroupFragment>;
};

export type GetProposalLinkInvitationQueryVariables = Exact<{
  params: GetProposalLinkInvitationParams;
}>;

export type GetProposalLinkInvitationQuery = { __typename?: "Query" } & {
  proposalLinkInvitation: {
    __typename?: "ProposalLinkInvitationResponse";
  } & Pick<
    ProposalLinkInvitationResponse,
    "linkId" | "proposer" | "proposalTitle" | "workspaceName" | "enabled"
  >;
};

export type GetLinkInvitationQueryVariables = Exact<{
  params: GetLinkInvitationParams;
}>;

export type GetLinkInvitationQuery = { __typename?: "Query" } & {
  linkInvitation: { __typename?: "LinkInvitation" } & Pick<
    LinkInvitation,
    "linkId" | "expires"
  > & {
      workspace: { __typename?: "Workspace" } & Pick<Workspace, "_id" | "name">;
    };
};

export type GetNotificationsQueryVariables = Exact<{
  params: NotificationsParams;
}>;

export type GetNotificationsQuery = { __typename?: "Query" } & {
  notifications: Array<{ __typename?: "Notification" } & NotificationFragment>;
};

export type GetTemplateQueryVariables = Exact<{
  _id: Scalars["String"];
}>;

export type GetTemplateQuery = { __typename?: "Query" } & {
  template: { __typename?: "Draft" } & PopulatedTemplateFragment;
};

export type GetTemplateDraftQueryVariables = Exact<{
  _id: Scalars["String"];
}>;

export type GetTemplateDraftQuery = { __typename?: "Query" } & {
  template: { __typename?: "Draft" } & DraftFragment;
  collections: Array<
    { __typename?: "Collection" } & PopulatedCollectionFragment
  >;
};

export type GetWorkspaceInvitationLinkQueryVariables = Exact<{
  params: WorkspaceInvitationLinkParams;
}>;

export type GetWorkspaceInvitationLinkQuery = { __typename?: "Query" } & {
  workspaceInvitationLink: { __typename?: "LinkInvitation" } & Pick<
    LinkInvitation,
    "linkId" | "expires"
  > & { workspace: { __typename?: "Workspace" } & Pick<Workspace, "_id"> };
};

export type GetWorkspaceQueryVariables = Exact<{
  params: WorkspaceParams;
}>;

export type GetWorkspaceQuery = { __typename?: "Query" } & {
  workspace: { __typename?: "Workspace" } & WorkspaceFragment;
};

export const PromptFragmentDoc = gql`
  fragment Prompt on Prompt {
    _id
    type
    content
    examples
    followUpPrompt {
      _id
    }
  }
`;
export const EmojiReactionFragmentDoc = gql`
  fragment EmojiReaction on EmojiReaction {
    user {
      _id
      displayName
    }
    emoji {
      emoji
      unicode
      names
      activeSkinTone
    }
  }
`;
export const PromptResponseFragmentDoc = gql`
  fragment PromptResponse on PromptResponse {
    _id
    prompt {
      ...Prompt
    }
    response
    createdAt
    lastModified
    sharedWith {
      _id
      name
    }
    user {
      _id
      displayName
      avatar
    }
    previousPromptResponse {
      _id
    }
    relatedAgreements {
      ... on Draft {
        _id
        status
        version
        proposer {
          _id
        }
      }
      ... on Proposal {
        _id
        status
        proposer {
          _id
        }
      }
      ... on Agreement {
        _id
        status
        proposer {
          _id
        }
      }
    }
    reactions {
      ...EmojiReaction
    }
  }
  ${PromptFragmentDoc}
  ${EmojiReactionFragmentDoc}
`;
export const CheckInFragmentDoc = gql`
  fragment CheckIn on CheckIn {
    _id
    createdAt
    lastModified
    promptResponses {
      ...PromptResponse
    }
    user {
      displayName
      avatar
    }
  }
  ${PromptResponseFragmentDoc}
`;
export const CreatorProfileFragmentDoc = gql`
  fragment CreatorProfile on CreatorProfile {
    _id
    name
    bio
    logoImageFileId
    createdByUserId
    twitterProfileURL
    linkedInProfileURL
    facebookProfileURL
    instagramProfileURL
    websiteURL
    public
  }
`;
export const FeedbackCycleFragmentDoc = gql`
  fragment FeedbackCycle on FeedbackCycle {
    _id
    responses {
      user {
        _id
        displayName
        avatar
      }
      completedTime
      status
      commitment
      impact
      learning
      whatNext
    }
  }
`;
export const ActivityAgreementSummaryFragmentDoc = gql`
  fragment ActivityAgreementSummary on AgreementActivity {
    type
    endDate
    agreement {
      _id
      status
      workingDocument {
        title
      }
    }
  }
`;
export const ActivityProposalSummaryFragmentDoc = gql`
  fragment ActivityProposalSummary on ProposalActivity {
    type
    endDate
    proposal {
      _id
      round
      status
      workingDocument {
        title
      }
      proposer {
        displayName
      }
    }
  }
`;
export const PopulatedCollectionFragmentDoc = gql`
  fragment PopulatedCollection on Collection {
    _id
    archived
    name
    description
    style
    exploreLandingFeaturedOrder
    exploreLandingPageOrder
    visibility
    discoveryMetadata {
      creatorProfileId
      worksWellWith
      description
      descriptionText
      audience
      audienceText
      orgType
      teamType
    }
    templates {
      _id
      agreementProperties {
        predefined {
          agreementType
          feedbackFrequency
          expirationDuration
        }
      }
      agreementType
      context
      feedbackFrequency
      expirationDuration
      discoveryMetadata {
        creatorProfileId
        worksWellWith
        description
        descriptionText
        audience
        audienceText
        orgType
        teamType
      }
      workingDocument {
        title
        subtitle
        body
      }
    }
  }
`;
export const TemplateFragmentDoc = gql`
  fragment Template on Draft {
    _id
    status
    template
    feedbackFrequency
    agreementType
    expirationDuration
    discoveryMetadata {
      creatorProfileId
      worksWellWith
      description
      descriptionText
      audience
      audienceText
      orgType
      teamType
    }
    agreementProperties {
      predefined {
        agreementType
        expirationDuration
        feedbackFrequency
      }
    }
    workingDocument {
      title
      subtitle
      body
    }
    visibility
  }
`;
export const PopulatedTemplateFragmentDoc = gql`
  fragment PopulatedTemplate on Draft {
    _id
    context
    status
    template
    feedbackFrequency
    agreementType
    expirationDuration
    discoveryMetadata {
      creatorProfile {
        _id
        name
        bio
        logoImageFileId
        createdByUserId
        twitterProfileURL
        linkedInProfileURL
        facebookProfileURL
        instagramProfileURL
        websiteURL
      }
      creatorProfileId
      worksWellWith
      worksWellWithTemplates {
        ...Template
      }
      description
      descriptionText
      audience
      audienceText
      orgType
      teamType
    }
    agreementProperties {
      predefined {
        agreementType
        expirationDuration
        feedbackFrequency
      }
    }
    workingDocument {
      title
      subtitle
      body
    }
    visibility
  }
  ${TemplateFragmentDoc}
`;
export const Acknowledge_AgreementFragmentDoc = gql`
  fragment Acknowledge_Agreement on Agreement {
    _id
    acknowledgers {
      userId
      date
      user {
        _id
        displayName
        avatar
      }
    }
    needsAcknowledgment
  }
`;
export const ParticipantRoleFragmentDoc = gql`
  fragment ParticipantRole on ParticipantRole {
    displayName
    linkedEntityId
    type
    participatesAs
    needsAcknowledgement
    inviteEmail
    inviteAsGuest
    avatar
    icon
    members {
      user {
        _id
      }
    }
  }
`;
export const AgreementFragmentDoc = gql`
  fragment Agreement on Agreement {
    _id
    acknowledgers {
      userId
      date
      user {
        _id
        displayName
        avatar
      }
    }
    context
    needsAcknowledgment
    parent {
      version
      activeVersion
    }
    proposalVersion
    roles {
      ...ParticipantRole
    }
    activeVersion
    status
    expirationDuration
    visibility
    discoverable
    feedbackFrequency
    agreementType
    version
    agreementProperties {
      predefined {
        agreementType
        feedbackFrequency
        expirationDuration
      }
    }
    workspace {
      _id
      name
    }
    workingDocument {
      title
      subtitle
      body
    }
    governs {
      users {
        _id
        displayName
        avatar
      }
      groups {
        _id
        name
        type
        icon
        description
      }
    }
    participants {
      userId
      user {
        _id
        displayName
        avatar
      }
      decision
      role
    }
    proposer {
      _id
      displayName
      avatar
      email
    }
    feedback {
      active
      cycles
    }
  }
  ${ParticipantRoleFragmentDoc}
`;
export const Agreement_RolesFragmentDoc = gql`
  fragment Agreement_Roles on Agreement {
    _id
    roles {
      ...ParticipantRole
    }
    acknowledgers {
      userId
      date
      user {
        _id
        displayName
        avatar
      }
    }
    needsAcknowledgment
  }
  ${ParticipantRoleFragmentDoc}
`;
export const AgreementSummaryFragmentDoc = gql`
  fragment AgreementSummary on Agreement {
    _id
    status
    agreementType
    version
    needsFeedback
    needsAcknowledgment
    workingDocument {
      title
      subtitle
      body
    }
    workspace {
      name
      _id
    }
    proposer {
      _id
      displayName
      avatar
    }
    governs {
      users {
        _id
        displayName
        avatar
      }
      groups {
        _id
        name
        type
        icon
        description
      }
    }
    roles {
      ...ParticipantRole
    }
  }
  ${ParticipantRoleFragmentDoc}
`;
export const DraftFragmentDoc = gql`
  fragment Draft on Draft {
    _id
    context
    proposalVersion
    activeVersion
    draftURL
    status
    version
    visibility
    template
    feedbackFrequency
    agreementType
    expirationDuration
    discoveryMetadata {
      creatorProfileId
      worksWellWith
      description
      descriptionText
      audience
      audienceText
      orgType
      teamType
    }
    agreementProperties {
      predefined {
        agreementType
        feedbackFrequency
        expirationDuration
      }
    }
    draftVisibleToWorkspace
    workspace {
      _id
    }
    workingDocument {
      title
      subtitle
      body
    }
    parent {
      activeVersion
      version
      roles {
        ...ParticipantRole
      }
    }
    otherUsersDraftingFromParent {
      _id
      displayName
    }
    proposer {
      _id
      displayName
      avatar
      email
    }
  }
  ${ParticipantRoleFragmentDoc}
`;
export const DraftSummaryFragmentDoc = gql`
  fragment DraftSummary on Draft {
    _id
    status
    template
    updatedAt
    version
    visibility
    parent {
      _id
      workingDocument {
        title
      }
    }
    workingDocument {
      title
      subtitle
      body
    }
    proposerText {
      title
      subtitle
      body
    }
    discoveryMetadata {
      creatorProfileId
      worksWellWith
      description
      descriptionText
      audience
      audienceText
      orgType
      teamType
    }
  }
`;
export const GovernsWithMembersFragmentDoc = gql`
  fragment GovernsWithMembers on Governed {
    users {
      _id
      displayName
      avatar
    }
    groups {
      _id
      name
      type
      icon
      description
      members {
        user {
          _id
        }
      }
    }
  }
`;
export const FullMemberFragmentDoc = gql`
  fragment FullMember on Member {
    _id
    permission
    user {
      avatar
      displayName
      _id
      email
      lastActive
      joined
    }
  }
`;
export const WorkspaceFragmentDoc = gql`
  fragment Workspace on Workspace {
    _id
    createdAt
    name
    integrationsInstalled
    integrationsRequiringReinstall
    logoFileId
    cohortId
    guests {
      avatar
      displayName
      _id
      email
      lastActive
      joined
    }
    invitedEmails {
      email
      workspaceGuest
    }
    billingAdmins {
      _id
    }
    allMembersGroup {
      _id
      name
      members {
        ...FullMember
      }
    }
  }
  ${FullMemberFragmentDoc}
`;
export const PopulatedGroupFragmentDoc = gql`
  fragment PopulatedGroup on Group {
    _id
    name
    icon
    type
    description
    visibility
    notificationPreferences {
      type
      enabled
      channelId
      channelUrl
    }
    workspace {
      ...Workspace
    }
    members {
      ...FullMember
    }
  }
  ${WorkspaceFragmentDoc}
  ${FullMemberFragmentDoc}
`;
export const GroupFragmentDoc = gql`
  fragment Group on Group {
    _id
    name
    icon
    type
    description
    visibility
    workspace {
      ...Workspace
    }
    members {
      _id
      permission
      user {
        _id
      }
    }
  }
  ${WorkspaceFragmentDoc}
`;
export const InteractionReactionFragmentDoc = gql`
  fragment InteractionReaction on Interaction {
    _id
    reactions {
      user {
        _id
        displayName
      }
      type
    }
  }
`;
export const InteractionResponseFragmentDoc = gql`
  fragment InteractionResponse on Interaction {
    _id
    proposalId
    response {
      content
      createdAt
      updatedAt
      createdAt
      user {
        avatar
        displayName
        _id
      }
    }
  }
`;
export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    _id
    content
    createdAt
    description
    isDismissed
    kind
    updatedAt
    userId
    workspaceId
  }
`;
export const InteractionFragmentDoc = gql`
  fragment Interaction on Interaction {
    content
    _id
    createdAt
    updatedAt
    propertyFor
    type
    range {
      anchor {
        path
        offset
      }
      focus {
        path
        offset
      }
    }
    user {
      displayName
      _id
      avatar
    }
    proposalId
    response {
      content
      createdAt
      updatedAt
      user {
        avatar
        displayName
        _id
      }
    }
    reactions {
      user {
        _id
        displayName
      }
      type
    }
    finalizeCycle
    acknowledgedByProposer
  }
`;
export const GovernsFragmentDoc = gql`
  fragment Governs on Governed {
    users {
      _id
      displayName
      avatar
    }
    groups {
      _id
      name
      type
      icon
      description
    }
  }
`;
export const ProposalFragmentDoc = gql`
  fragment Proposal on Proposal {
    _id
    context
    parent {
      version
      activeVersion
      roles {
        ...ParticipantRole
      }
      expirationDate
    }
    activeVersion
    isStale
    smartEvents {
      eventAction
    }
    status
    round
    version
    visibility
    activePlayer
    expirationDuration
    nextAdvancement
    feedbackFrequency
    fastTrack
    agreementType
    agreementProperties {
      predefined {
        agreementType
        feedbackFrequency
        expirationDuration
      }
    }
    inviteLink
    inviteLinkParticipationRole
    workingDocument {
      title
      subtitle
      body
    }
    workspace {
      _id
      name
    }
    interactions {
      ...Interaction
    }
    participants {
      userId
      user {
        _id
        displayName
        avatar
      }
      role
      reaction
      status
      roundStatus {
        understand
        improve
        finalize
      }
      decision
      onBehalfOf {
        ...Governs
      }
    }
    governs {
      ...Governs
    }
    roles {
      ...ParticipantRole
    }
    proposer {
      _id
      displayName
      avatar
      email
    }
    documents {
      title
      subtitle
      body
      round
    }
    finalizeCycle
  }
  ${ParticipantRoleFragmentDoc}
  ${InteractionFragmentDoc}
  ${GovernsFragmentDoc}
`;
export const ProposalSummaryFragmentDoc = gql`
  fragment ProposalSummary on Proposal {
    _id
    needsUserAction
    isStale
    nextAdvancement
    status
    round
    activePlayer
    agreementType
    workingDocument {
      title
      subtitle
    }
    proposerText {
      title
      subtitle
      body
    }
    participantText {
      title
      subtitle
      body
    }
    parent {
      _id
      workingDocument {
        title
      }
    }
    proposer {
      _id
      displayName
      avatar
    }
    governs {
      ...Governs
    }
    participants {
      user {
        _id
      }
    }
  }
  ${GovernsFragmentDoc}
`;
export const PublicAgreementSummaryFragmentDoc = gql`
  fragment PublicAgreementSummary on Agreement {
    _id
    status
    agreementType
    workingDocument {
      title
      subtitle
    }
    workspace {
      name
      _id
    }
  }
`;
export const TemplateSummaryFragmentDoc = gql`
  fragment TemplateSummary on Draft {
    _id
    workingDocument {
      title
      subtitle
    }
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    _id
    email
    displayName
    avatar
    joined
    workspaceInvitations {
      _id
      workspace {
        name
        _id
      }
    }
    proposalInvitations {
      linkId
      workspace {
        name
        _id
      }
    }
    notificationPreferences {
      enabled
      type
      workspace {
        _id
      }
    }
    workspaces {
      _id
      name
      cohortId
    }
    guestOfWorkspaces {
      _id
      name
      cohortId
    }
  }
`;
export const UserWithSessionFragmentDoc = gql`
  fragment UserWithSession on UserWithSession {
    _id
    email
    displayName
    avatar
    joined
    sessionExpiresAt
    workspaceInvitations {
      _id
      workspaceGuest
      workspace {
        name
        _id
      }
    }
    proposalInvitations {
      linkId
      workspaceGuest
      workspace {
        name
        _id
      }
    }
    notificationPreferences {
      enabled
      type
      workspace {
        _id
      }
    }
    preferences {
      lastUsedEmojiSkinTone
      dontShowWhatHappensWhenIProposeHelp
    }
    featuresSeen
    walletId
    workspaces {
      _id
      name
      cohortId
    }
    guestOfWorkspaces {
      _id
      name
      cohortId
    }
    checklists {
      workspaceId
      completedItems
    }
  }
`;
export const WorkspaceMergeDataFragmentDoc = gql`
  fragment WorkspaceMergeData on WorkspaceMergeData {
    membersChanges {
      added {
        _id
        user {
          _id
          displayName
          avatar
        }
      }
      existing {
        _id
        user {
          _id
          displayName
          avatar
        }
      }
      invites {
        _id
        email
      }
    }
    groupsChanges {
      added {
        _id
        name
      }
    }
    agreementsChanges {
      drafts {
        _id
        workingDocument {
          title
        }
      }
      proposals {
        _id
        workingDocument {
          title
        }
      }
      active {
        _id
        workingDocument {
          title
        }
      }
      archived {
        _id
        workingDocument {
          title
        }
      }
    }
  }
`;
export const AddPromptResponseDocument = gql`
  mutation AddPromptResponse($input: AddPromptResponseInput!) {
    addPromptResponse(input: $input) {
      ...CheckIn
    }
  }
  ${CheckInFragmentDoc}
`;
export type AddPromptResponseMutationFn = Apollo.MutationFunction<
  AddPromptResponseMutation,
  AddPromptResponseMutationVariables
>;

/**
 * __useAddPromptResponseMutation__
 *
 * To run a mutation, you first call `useAddPromptResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPromptResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPromptResponseMutation, { data, loading, error }] = useAddPromptResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPromptResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPromptResponseMutation,
    AddPromptResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPromptResponseMutation,
    AddPromptResponseMutationVariables
  >(AddPromptResponseDocument, options);
}
export type AddPromptResponseMutationHookResult = ReturnType<
  typeof useAddPromptResponseMutation
>;
export type AddPromptResponseMutationResult =
  Apollo.MutationResult<AddPromptResponseMutation>;
export type AddPromptResponseMutationOptions = Apollo.BaseMutationOptions<
  AddPromptResponseMutation,
  AddPromptResponseMutationVariables
>;
export const CreateCheckInDocument = gql`
  mutation CreateCheckIn($input: CreateCheckInInput!) {
    createCheckIn(input: $input) {
      ...CheckIn
    }
  }
  ${CheckInFragmentDoc}
`;
export type CreateCheckInMutationFn = Apollo.MutationFunction<
  CreateCheckInMutation,
  CreateCheckInMutationVariables
>;

/**
 * __useCreateCheckInMutation__
 *
 * To run a mutation, you first call `useCreateCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckInMutation, { data, loading, error }] = useCreateCheckInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCheckInMutation,
    CreateCheckInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCheckInMutation,
    CreateCheckInMutationVariables
  >(CreateCheckInDocument, options);
}
export type CreateCheckInMutationHookResult = ReturnType<
  typeof useCreateCheckInMutation
>;
export type CreateCheckInMutationResult =
  Apollo.MutationResult<CreateCheckInMutation>;
export type CreateCheckInMutationOptions = Apollo.BaseMutationOptions<
  CreateCheckInMutation,
  CreateCheckInMutationVariables
>;
export const CreateDraftFromCheckInDocument = gql`
  mutation CreateDraftFromCheckIn($input: CreateDraftFromCheckInInput!) {
    createDraftFromCheckIn(input: $input) {
      _id
      version
      proposer {
        _id
      }
    }
  }
`;
export type CreateDraftFromCheckInMutationFn = Apollo.MutationFunction<
  CreateDraftFromCheckInMutation,
  CreateDraftFromCheckInMutationVariables
>;

/**
 * __useCreateDraftFromCheckInMutation__
 *
 * To run a mutation, you first call `useCreateDraftFromCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftFromCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftFromCheckInMutation, { data, loading, error }] = useCreateDraftFromCheckInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftFromCheckInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftFromCheckInMutation,
    CreateDraftFromCheckInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDraftFromCheckInMutation,
    CreateDraftFromCheckInMutationVariables
  >(CreateDraftFromCheckInDocument, options);
}
export type CreateDraftFromCheckInMutationHookResult = ReturnType<
  typeof useCreateDraftFromCheckInMutation
>;
export type CreateDraftFromCheckInMutationResult =
  Apollo.MutationResult<CreateDraftFromCheckInMutation>;
export type CreateDraftFromCheckInMutationOptions = Apollo.BaseMutationOptions<
  CreateDraftFromCheckInMutation,
  CreateDraftFromCheckInMutationVariables
>;
export const UpdatePromptResponseDocument = gql`
  mutation UpdatePromptResponse($input: UpdatePromptResponseInput!) {
    updatePromptResponse(input: $input) {
      ...PromptResponse
    }
  }
  ${PromptResponseFragmentDoc}
`;
export type UpdatePromptResponseMutationFn = Apollo.MutationFunction<
  UpdatePromptResponseMutation,
  UpdatePromptResponseMutationVariables
>;

/**
 * __useUpdatePromptResponseMutation__
 *
 * To run a mutation, you first call `useUpdatePromptResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromptResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromptResponseMutation, { data, loading, error }] = useUpdatePromptResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromptResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePromptResponseMutation,
    UpdatePromptResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePromptResponseMutation,
    UpdatePromptResponseMutationVariables
  >(UpdatePromptResponseDocument, options);
}
export type UpdatePromptResponseMutationHookResult = ReturnType<
  typeof useUpdatePromptResponseMutation
>;
export type UpdatePromptResponseMutationResult =
  Apollo.MutationResult<UpdatePromptResponseMutation>;
export type UpdatePromptResponseMutationOptions = Apollo.BaseMutationOptions<
  UpdatePromptResponseMutation,
  UpdatePromptResponseMutationVariables
>;
export const GetCheckInDocument = gql`
  query GetCheckIn($params: GetCheckInParams!) {
    getCheckIn(params: $params) {
      ...CheckIn
    }
  }
  ${CheckInFragmentDoc}
`;

/**
 * __useGetCheckInQuery__
 *
 * To run a query within a React component, call `useGetCheckInQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckInQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCheckInQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCheckInQuery,
    GetCheckInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCheckInQuery, GetCheckInQueryVariables>(
    GetCheckInDocument,
    options
  );
}
export function useGetCheckInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCheckInQuery,
    GetCheckInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCheckInQuery, GetCheckInQueryVariables>(
    GetCheckInDocument,
    options
  );
}
export type GetCheckInQueryHookResult = ReturnType<typeof useGetCheckInQuery>;
export type GetCheckInLazyQueryHookResult = ReturnType<
  typeof useGetCheckInLazyQuery
>;
export type GetCheckInQueryResult = Apollo.QueryResult<
  GetCheckInQuery,
  GetCheckInQueryVariables
>;
export const GetPromptsFilteredDocument = gql`
  query GetPromptsFiltered($params: GetPromptsFilteredParams!) {
    getPromptsFiltered(params: $params) {
      ...Prompt
    }
  }
  ${PromptFragmentDoc}
`;

/**
 * __useGetPromptsFilteredQuery__
 *
 * To run a query within a React component, call `useGetPromptsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromptsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromptsFilteredQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetPromptsFilteredQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPromptsFilteredQuery,
    GetPromptsFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPromptsFilteredQuery,
    GetPromptsFilteredQueryVariables
  >(GetPromptsFilteredDocument, options);
}
export function useGetPromptsFilteredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPromptsFilteredQuery,
    GetPromptsFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPromptsFilteredQuery,
    GetPromptsFilteredQueryVariables
  >(GetPromptsFilteredDocument, options);
}
export type GetPromptsFilteredQueryHookResult = ReturnType<
  typeof useGetPromptsFilteredQuery
>;
export type GetPromptsFilteredLazyQueryHookResult = ReturnType<
  typeof useGetPromptsFilteredLazyQuery
>;
export type GetPromptsFilteredQueryResult = Apollo.QueryResult<
  GetPromptsFilteredQuery,
  GetPromptsFilteredQueryVariables
>;
export const UpdateParticipantRolesDocument = gql`
  mutation UpdateParticipantRoles($input: UpdateParticipantRolesInput!) {
    updateProposalParticipantRoles(input: $input) {
      ...Proposal
    }
  }
  ${ProposalFragmentDoc}
`;
export type UpdateParticipantRolesMutationFn = Apollo.MutationFunction<
  UpdateParticipantRolesMutation,
  UpdateParticipantRolesMutationVariables
>;

/**
 * __useUpdateParticipantRolesMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantRolesMutation, { data, loading, error }] = useUpdateParticipantRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateParticipantRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateParticipantRolesMutation,
    UpdateParticipantRolesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateParticipantRolesMutation,
    UpdateParticipantRolesMutationVariables
  >(UpdateParticipantRolesDocument, options);
}
export type UpdateParticipantRolesMutationHookResult = ReturnType<
  typeof useUpdateParticipantRolesMutation
>;
export type UpdateParticipantRolesMutationResult =
  Apollo.MutationResult<UpdateParticipantRolesMutation>;
export type UpdateParticipantRolesMutationOptions = Apollo.BaseMutationOptions<
  UpdateParticipantRolesMutation,
  UpdateParticipantRolesMutationVariables
>;
export const CreateCreatorProfileDocument = gql`
  mutation CreateCreatorProfile {
    createCreatorProfile {
      ...CreatorProfile
    }
  }
  ${CreatorProfileFragmentDoc}
`;
export type CreateCreatorProfileMutationFn = Apollo.MutationFunction<
  CreateCreatorProfileMutation,
  CreateCreatorProfileMutationVariables
>;

/**
 * __useCreateCreatorProfileMutation__
 *
 * To run a mutation, you first call `useCreateCreatorProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreatorProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreatorProfileMutation, { data, loading, error }] = useCreateCreatorProfileMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCreatorProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCreatorProfileMutation,
    CreateCreatorProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCreatorProfileMutation,
    CreateCreatorProfileMutationVariables
  >(CreateCreatorProfileDocument, options);
}
export type CreateCreatorProfileMutationHookResult = ReturnType<
  typeof useCreateCreatorProfileMutation
>;
export type CreateCreatorProfileMutationResult =
  Apollo.MutationResult<CreateCreatorProfileMutation>;
export type CreateCreatorProfileMutationOptions = Apollo.BaseMutationOptions<
  CreateCreatorProfileMutation,
  CreateCreatorProfileMutationVariables
>;
export const DeleteCreatorProfileDocument = gql`
  mutation DeleteCreatorProfile($id: String!) {
    deleteCreatorProfile(id: $id) {
      ...CreatorProfile
    }
  }
  ${CreatorProfileFragmentDoc}
`;
export type DeleteCreatorProfileMutationFn = Apollo.MutationFunction<
  DeleteCreatorProfileMutation,
  DeleteCreatorProfileMutationVariables
>;

/**
 * __useDeleteCreatorProfileMutation__
 *
 * To run a mutation, you first call `useDeleteCreatorProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreatorProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreatorProfileMutation, { data, loading, error }] = useDeleteCreatorProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCreatorProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCreatorProfileMutation,
    DeleteCreatorProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCreatorProfileMutation,
    DeleteCreatorProfileMutationVariables
  >(DeleteCreatorProfileDocument, options);
}
export type DeleteCreatorProfileMutationHookResult = ReturnType<
  typeof useDeleteCreatorProfileMutation
>;
export type DeleteCreatorProfileMutationResult =
  Apollo.MutationResult<DeleteCreatorProfileMutation>;
export type DeleteCreatorProfileMutationOptions = Apollo.BaseMutationOptions<
  DeleteCreatorProfileMutation,
  DeleteCreatorProfileMutationVariables
>;
export const UpdateCreatorProfileDocument = gql`
  mutation UpdateCreatorProfile($input: UpdateCreatorProfileInput!) {
    updateCreatorProfile(input: $input) {
      ...CreatorProfile
    }
  }
  ${CreatorProfileFragmentDoc}
`;
export type UpdateCreatorProfileMutationFn = Apollo.MutationFunction<
  UpdateCreatorProfileMutation,
  UpdateCreatorProfileMutationVariables
>;

/**
 * __useUpdateCreatorProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCreatorProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreatorProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreatorProfileMutation, { data, loading, error }] = useUpdateCreatorProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreatorProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCreatorProfileMutation,
    UpdateCreatorProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCreatorProfileMutation,
    UpdateCreatorProfileMutationVariables
  >(UpdateCreatorProfileDocument, options);
}
export type UpdateCreatorProfileMutationHookResult = ReturnType<
  typeof useUpdateCreatorProfileMutation
>;
export type UpdateCreatorProfileMutationResult =
  Apollo.MutationResult<UpdateCreatorProfileMutation>;
export type UpdateCreatorProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateCreatorProfileMutation,
  UpdateCreatorProfileMutationVariables
>;
export const AllCreatorProfilesDocument = gql`
  query AllCreatorProfiles {
    creatorProfiles {
      ...CreatorProfile
    }
  }
  ${CreatorProfileFragmentDoc}
`;

/**
 * __useAllCreatorProfilesQuery__
 *
 * To run a query within a React component, call `useAllCreatorProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCreatorProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCreatorProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCreatorProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCreatorProfilesQuery,
    AllCreatorProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllCreatorProfilesQuery,
    AllCreatorProfilesQueryVariables
  >(AllCreatorProfilesDocument, options);
}
export function useAllCreatorProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCreatorProfilesQuery,
    AllCreatorProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllCreatorProfilesQuery,
    AllCreatorProfilesQueryVariables
  >(AllCreatorProfilesDocument, options);
}
export type AllCreatorProfilesQueryHookResult = ReturnType<
  typeof useAllCreatorProfilesQuery
>;
export type AllCreatorProfilesLazyQueryHookResult = ReturnType<
  typeof useAllCreatorProfilesLazyQuery
>;
export type AllCreatorProfilesQueryResult = Apollo.QueryResult<
  AllCreatorProfilesQuery,
  AllCreatorProfilesQueryVariables
>;
export const PublicCreatorProfilesDocument = gql`
  query PublicCreatorProfiles {
    publicCreatorProfiles {
      ...CreatorProfile
    }
  }
  ${CreatorProfileFragmentDoc}
`;

/**
 * __usePublicCreatorProfilesQuery__
 *
 * To run a query within a React component, call `usePublicCreatorProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicCreatorProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicCreatorProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicCreatorProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PublicCreatorProfilesQuery,
    PublicCreatorProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PublicCreatorProfilesQuery,
    PublicCreatorProfilesQueryVariables
  >(PublicCreatorProfilesDocument, options);
}
export function usePublicCreatorProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicCreatorProfilesQuery,
    PublicCreatorProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicCreatorProfilesQuery,
    PublicCreatorProfilesQueryVariables
  >(PublicCreatorProfilesDocument, options);
}
export type PublicCreatorProfilesQueryHookResult = ReturnType<
  typeof usePublicCreatorProfilesQuery
>;
export type PublicCreatorProfilesLazyQueryHookResult = ReturnType<
  typeof usePublicCreatorProfilesLazyQuery
>;
export type PublicCreatorProfilesQueryResult = Apollo.QueryResult<
  PublicCreatorProfilesQuery,
  PublicCreatorProfilesQueryVariables
>;
export const AddEmojiReactionToPromptResponseDocument = gql`
  mutation AddEmojiReactionToPromptResponse(
    $promptResponseId: String!
    $emoji: EmojiInput!
  ) {
    addEmojiReactionToPromptResponse(
      promptResponseId: $promptResponseId
      emoji: $emoji
    ) {
      ...PromptResponse
    }
  }
  ${PromptResponseFragmentDoc}
`;
export type AddEmojiReactionToPromptResponseMutationFn =
  Apollo.MutationFunction<
    AddEmojiReactionToPromptResponseMutation,
    AddEmojiReactionToPromptResponseMutationVariables
  >;

/**
 * __useAddEmojiReactionToPromptResponseMutation__
 *
 * To run a mutation, you first call `useAddEmojiReactionToPromptResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmojiReactionToPromptResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmojiReactionToPromptResponseMutation, { data, loading, error }] = useAddEmojiReactionToPromptResponseMutation({
 *   variables: {
 *      promptResponseId: // value for 'promptResponseId'
 *      emoji: // value for 'emoji'
 *   },
 * });
 */
export function useAddEmojiReactionToPromptResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEmojiReactionToPromptResponseMutation,
    AddEmojiReactionToPromptResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEmojiReactionToPromptResponseMutation,
    AddEmojiReactionToPromptResponseMutationVariables
  >(AddEmojiReactionToPromptResponseDocument, options);
}
export type AddEmojiReactionToPromptResponseMutationHookResult = ReturnType<
  typeof useAddEmojiReactionToPromptResponseMutation
>;
export type AddEmojiReactionToPromptResponseMutationResult =
  Apollo.MutationResult<AddEmojiReactionToPromptResponseMutation>;
export type AddEmojiReactionToPromptResponseMutationOptions =
  Apollo.BaseMutationOptions<
    AddEmojiReactionToPromptResponseMutation,
    AddEmojiReactionToPromptResponseMutationVariables
  >;
export const CreateDraftFromPromptResponseDocument = gql`
  mutation CreateDraftFromPromptResponse($promptResponseId: String!) {
    createDraftFromPromptResponse(promptResponseId: $promptResponseId) {
      _id
      version
    }
  }
`;
export type CreateDraftFromPromptResponseMutationFn = Apollo.MutationFunction<
  CreateDraftFromPromptResponseMutation,
  CreateDraftFromPromptResponseMutationVariables
>;

/**
 * __useCreateDraftFromPromptResponseMutation__
 *
 * To run a mutation, you first call `useCreateDraftFromPromptResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftFromPromptResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftFromPromptResponseMutation, { data, loading, error }] = useCreateDraftFromPromptResponseMutation({
 *   variables: {
 *      promptResponseId: // value for 'promptResponseId'
 *   },
 * });
 */
export function useCreateDraftFromPromptResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftFromPromptResponseMutation,
    CreateDraftFromPromptResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDraftFromPromptResponseMutation,
    CreateDraftFromPromptResponseMutationVariables
  >(CreateDraftFromPromptResponseDocument, options);
}
export type CreateDraftFromPromptResponseMutationHookResult = ReturnType<
  typeof useCreateDraftFromPromptResponseMutation
>;
export type CreateDraftFromPromptResponseMutationResult =
  Apollo.MutationResult<CreateDraftFromPromptResponseMutation>;
export type CreateDraftFromPromptResponseMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDraftFromPromptResponseMutation,
    CreateDraftFromPromptResponseMutationVariables
  >;
export const CreatePromptResponseDocument = gql`
  mutation CreatePromptResponse($input: CreatePromptResponseInput!) {
    createPromptResponse(input: $input) {
      ...PromptResponse
    }
  }
  ${PromptResponseFragmentDoc}
`;
export type CreatePromptResponseMutationFn = Apollo.MutationFunction<
  CreatePromptResponseMutation,
  CreatePromptResponseMutationVariables
>;

/**
 * __useCreatePromptResponseMutation__
 *
 * To run a mutation, you first call `useCreatePromptResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromptResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromptResponseMutation, { data, loading, error }] = useCreatePromptResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromptResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePromptResponseMutation,
    CreatePromptResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePromptResponseMutation,
    CreatePromptResponseMutationVariables
  >(CreatePromptResponseDocument, options);
}
export type CreatePromptResponseMutationHookResult = ReturnType<
  typeof useCreatePromptResponseMutation
>;
export type CreatePromptResponseMutationResult =
  Apollo.MutationResult<CreatePromptResponseMutation>;
export type CreatePromptResponseMutationOptions = Apollo.BaseMutationOptions<
  CreatePromptResponseMutation,
  CreatePromptResponseMutationVariables
>;
export const MarkChecklistItemCompletedDocument = gql`
  mutation MarkChecklistItemCompleted(
    $input: MarkChecklistItemCompletedInput!
  ) {
    markChecklistItemCompleted(input: $input) {
      ...UserWithSession
    }
  }
  ${UserWithSessionFragmentDoc}
`;
export type MarkChecklistItemCompletedMutationFn = Apollo.MutationFunction<
  MarkChecklistItemCompletedMutation,
  MarkChecklistItemCompletedMutationVariables
>;

/**
 * __useMarkChecklistItemCompletedMutation__
 *
 * To run a mutation, you first call `useMarkChecklistItemCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkChecklistItemCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markChecklistItemCompletedMutation, { data, loading, error }] = useMarkChecklistItemCompletedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkChecklistItemCompletedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkChecklistItemCompletedMutation,
    MarkChecklistItemCompletedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkChecklistItemCompletedMutation,
    MarkChecklistItemCompletedMutationVariables
  >(MarkChecklistItemCompletedDocument, options);
}
export type MarkChecklistItemCompletedMutationHookResult = ReturnType<
  typeof useMarkChecklistItemCompletedMutation
>;
export type MarkChecklistItemCompletedMutationResult =
  Apollo.MutationResult<MarkChecklistItemCompletedMutation>;
export type MarkChecklistItemCompletedMutationOptions =
  Apollo.BaseMutationOptions<
    MarkChecklistItemCompletedMutation,
    MarkChecklistItemCompletedMutationVariables
  >;
export const RemoveEmojiReactionFromPromptResponseDocument = gql`
  mutation RemoveEmojiReactionFromPromptResponse(
    $promptResponseId: String!
    $emoji: EmojiInput!
  ) {
    removeEmojiReactionFromPromptResponse(
      promptResponseId: $promptResponseId
      emoji: $emoji
    ) {
      ...PromptResponse
    }
  }
  ${PromptResponseFragmentDoc}
`;
export type RemoveEmojiReactionFromPromptResponseMutationFn =
  Apollo.MutationFunction<
    RemoveEmojiReactionFromPromptResponseMutation,
    RemoveEmojiReactionFromPromptResponseMutationVariables
  >;

/**
 * __useRemoveEmojiReactionFromPromptResponseMutation__
 *
 * To run a mutation, you first call `useRemoveEmojiReactionFromPromptResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEmojiReactionFromPromptResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEmojiReactionFromPromptResponseMutation, { data, loading, error }] = useRemoveEmojiReactionFromPromptResponseMutation({
 *   variables: {
 *      promptResponseId: // value for 'promptResponseId'
 *      emoji: // value for 'emoji'
 *   },
 * });
 */
export function useRemoveEmojiReactionFromPromptResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEmojiReactionFromPromptResponseMutation,
    RemoveEmojiReactionFromPromptResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveEmojiReactionFromPromptResponseMutation,
    RemoveEmojiReactionFromPromptResponseMutationVariables
  >(RemoveEmojiReactionFromPromptResponseDocument, options);
}
export type RemoveEmojiReactionFromPromptResponseMutationHookResult =
  ReturnType<typeof useRemoveEmojiReactionFromPromptResponseMutation>;
export type RemoveEmojiReactionFromPromptResponseMutationResult =
  Apollo.MutationResult<RemoveEmojiReactionFromPromptResponseMutation>;
export type RemoveEmojiReactionFromPromptResponseMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveEmojiReactionFromPromptResponseMutation,
    RemoveEmojiReactionFromPromptResponseMutationVariables
  >;
export const UpdateEmojiReactionForPromptResponseDocument = gql`
  mutation UpdateEmojiReactionForPromptResponse(
    $promptResponseId: String!
    $originalEmoji: EmojiInput!
    $updatedEmoji: EmojiInput!
  ) {
    updateEmojiReactionForPromptResponse(
      promptResponseId: $promptResponseId
      originalEmoji: $originalEmoji
      updatedEmoji: $updatedEmoji
    ) {
      ...PromptResponse
    }
  }
  ${PromptResponseFragmentDoc}
`;
export type UpdateEmojiReactionForPromptResponseMutationFn =
  Apollo.MutationFunction<
    UpdateEmojiReactionForPromptResponseMutation,
    UpdateEmojiReactionForPromptResponseMutationVariables
  >;

/**
 * __useUpdateEmojiReactionForPromptResponseMutation__
 *
 * To run a mutation, you first call `useUpdateEmojiReactionForPromptResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmojiReactionForPromptResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmojiReactionForPromptResponseMutation, { data, loading, error }] = useUpdateEmojiReactionForPromptResponseMutation({
 *   variables: {
 *      promptResponseId: // value for 'promptResponseId'
 *      originalEmoji: // value for 'originalEmoji'
 *      updatedEmoji: // value for 'updatedEmoji'
 *   },
 * });
 */
export function useUpdateEmojiReactionForPromptResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmojiReactionForPromptResponseMutation,
    UpdateEmojiReactionForPromptResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmojiReactionForPromptResponseMutation,
    UpdateEmojiReactionForPromptResponseMutationVariables
  >(UpdateEmojiReactionForPromptResponseDocument, options);
}
export type UpdateEmojiReactionForPromptResponseMutationHookResult = ReturnType<
  typeof useUpdateEmojiReactionForPromptResponseMutation
>;
export type UpdateEmojiReactionForPromptResponseMutationResult =
  Apollo.MutationResult<UpdateEmojiReactionForPromptResponseMutation>;
export type UpdateEmojiReactionForPromptResponseMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateEmojiReactionForPromptResponseMutation,
    UpdateEmojiReactionForPromptResponseMutationVariables
  >;
export const GetActivitiesByWorkspaceDocument = gql`
  query GetActivitiesByWorkspace($activitiesParams: ActivityParams!) {
    activities(params: $activitiesParams) {
      ... on AgreementActivity {
        ...ActivityAgreementSummary
      }
      ... on ProposalActivity {
        ...ActivityProposalSummary
      }
    }
  }
  ${ActivityAgreementSummaryFragmentDoc}
  ${ActivityProposalSummaryFragmentDoc}
`;

/**
 * __useGetActivitiesByWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetActivitiesByWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesByWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesByWorkspaceQuery({
 *   variables: {
 *      activitiesParams: // value for 'activitiesParams'
 *   },
 * });
 */
export function useGetActivitiesByWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivitiesByWorkspaceQuery,
    GetActivitiesByWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivitiesByWorkspaceQuery,
    GetActivitiesByWorkspaceQueryVariables
  >(GetActivitiesByWorkspaceDocument, options);
}
export function useGetActivitiesByWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivitiesByWorkspaceQuery,
    GetActivitiesByWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivitiesByWorkspaceQuery,
    GetActivitiesByWorkspaceQueryVariables
  >(GetActivitiesByWorkspaceDocument, options);
}
export type GetActivitiesByWorkspaceQueryHookResult = ReturnType<
  typeof useGetActivitiesByWorkspaceQuery
>;
export type GetActivitiesByWorkspaceLazyQueryHookResult = ReturnType<
  typeof useGetActivitiesByWorkspaceLazyQuery
>;
export type GetActivitiesByWorkspaceQueryResult = Apollo.QueryResult<
  GetActivitiesByWorkspaceQuery,
  GetActivitiesByWorkspaceQueryVariables
>;
export const GetDailyPromptsDocument = gql`
  query GetDailyPrompts($params: GetDailyPromptsParams!) {
    getDailyPrompts(params: $params) {
      ...Prompt
    }
  }
  ${PromptFragmentDoc}
`;

/**
 * __useGetDailyPromptsQuery__
 *
 * To run a query within a React component, call `useGetDailyPromptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyPromptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyPromptsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetDailyPromptsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDailyPromptsQuery,
    GetDailyPromptsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDailyPromptsQuery, GetDailyPromptsQueryVariables>(
    GetDailyPromptsDocument,
    options
  );
}
export function useGetDailyPromptsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDailyPromptsQuery,
    GetDailyPromptsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDailyPromptsQuery,
    GetDailyPromptsQueryVariables
  >(GetDailyPromptsDocument, options);
}
export type GetDailyPromptsQueryHookResult = ReturnType<
  typeof useGetDailyPromptsQuery
>;
export type GetDailyPromptsLazyQueryHookResult = ReturnType<
  typeof useGetDailyPromptsLazyQuery
>;
export type GetDailyPromptsQueryResult = Apollo.QueryResult<
  GetDailyPromptsQuery,
  GetDailyPromptsQueryVariables
>;
export const GetSharedPromptResponsesForWorkspaceDocument = gql`
  query GetSharedPromptResponsesForWorkspace(
    $workspaceId: String!
    $params: GetPromptResponsesFeedParams!
  ) {
    getTodaysPromptResponseForUser(workspaceId: $workspaceId) {
      ...PromptResponse
    }
    getPromptResponsesFeed(params: $params) {
      promptResponses {
        ...PromptResponse
      }
      cursorLimit
    }
  }
  ${PromptResponseFragmentDoc}
`;

/**
 * __useGetSharedPromptResponsesForWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetSharedPromptResponsesForWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedPromptResponsesForWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedPromptResponsesForWorkspaceQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSharedPromptResponsesForWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSharedPromptResponsesForWorkspaceQuery,
    GetSharedPromptResponsesForWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSharedPromptResponsesForWorkspaceQuery,
    GetSharedPromptResponsesForWorkspaceQueryVariables
  >(GetSharedPromptResponsesForWorkspaceDocument, options);
}
export function useGetSharedPromptResponsesForWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSharedPromptResponsesForWorkspaceQuery,
    GetSharedPromptResponsesForWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSharedPromptResponsesForWorkspaceQuery,
    GetSharedPromptResponsesForWorkspaceQueryVariables
  >(GetSharedPromptResponsesForWorkspaceDocument, options);
}
export type GetSharedPromptResponsesForWorkspaceQueryHookResult = ReturnType<
  typeof useGetSharedPromptResponsesForWorkspaceQuery
>;
export type GetSharedPromptResponsesForWorkspaceLazyQueryHookResult =
  ReturnType<typeof useGetSharedPromptResponsesForWorkspaceLazyQuery>;
export type GetSharedPromptResponsesForWorkspaceQueryResult =
  Apollo.QueryResult<
    GetSharedPromptResponsesForWorkspaceQuery,
    GetSharedPromptResponsesForWorkspaceQueryVariables
  >;
export const DraftsFromExistingAgreementsDocument = gql`
  mutation DraftsFromExistingAgreements(
    $input: DraftsFromExistingAgreementsInput!
  ) {
    draftsFromExistingAgreements(input: $input) {
      _id
    }
  }
`;
export type DraftsFromExistingAgreementsMutationFn = Apollo.MutationFunction<
  DraftsFromExistingAgreementsMutation,
  DraftsFromExistingAgreementsMutationVariables
>;

/**
 * __useDraftsFromExistingAgreementsMutation__
 *
 * To run a mutation, you first call `useDraftsFromExistingAgreementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftsFromExistingAgreementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftsFromExistingAgreementsMutation, { data, loading, error }] = useDraftsFromExistingAgreementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDraftsFromExistingAgreementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DraftsFromExistingAgreementsMutation,
    DraftsFromExistingAgreementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DraftsFromExistingAgreementsMutation,
    DraftsFromExistingAgreementsMutationVariables
  >(DraftsFromExistingAgreementsDocument, options);
}
export type DraftsFromExistingAgreementsMutationHookResult = ReturnType<
  typeof useDraftsFromExistingAgreementsMutation
>;
export type DraftsFromExistingAgreementsMutationResult =
  Apollo.MutationResult<DraftsFromExistingAgreementsMutation>;
export type DraftsFromExistingAgreementsMutationOptions =
  Apollo.BaseMutationOptions<
    DraftsFromExistingAgreementsMutation,
    DraftsFromExistingAgreementsMutationVariables
  >;
export const GetAuthenticatedUserDocument = gql`
  query GetAuthenticatedUser {
    authenticatedUser {
      ...UserWithSession
    }
  }
  ${UserWithSessionFragmentDoc}
`;

/**
 * __useGetAuthenticatedUserQuery__
 *
 * To run a query within a React component, call `useGetAuthenticatedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticatedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticatedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthenticatedUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAuthenticatedUserQuery,
    GetAuthenticatedUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuthenticatedUserQuery,
    GetAuthenticatedUserQueryVariables
  >(GetAuthenticatedUserDocument, options);
}
export function useGetAuthenticatedUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthenticatedUserQuery,
    GetAuthenticatedUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuthenticatedUserQuery,
    GetAuthenticatedUserQueryVariables
  >(GetAuthenticatedUserDocument, options);
}
export type GetAuthenticatedUserQueryHookResult = ReturnType<
  typeof useGetAuthenticatedUserQuery
>;
export type GetAuthenticatedUserLazyQueryHookResult = ReturnType<
  typeof useGetAuthenticatedUserLazyQuery
>;
export type GetAuthenticatedUserQueryResult = Apollo.QueryResult<
  GetAuthenticatedUserQuery,
  GetAuthenticatedUserQueryVariables
>;
export const CreateDraftFromQuizDocument = gql`
  mutation CreateDraftFromQuiz($input: CreateDraftFromQuizInput!) {
    createDraftFromQuiz(input: $input) {
      _id
      version
      proposer {
        _id
      }
    }
  }
`;
export type CreateDraftFromQuizMutationFn = Apollo.MutationFunction<
  CreateDraftFromQuizMutation,
  CreateDraftFromQuizMutationVariables
>;

/**
 * __useCreateDraftFromQuizMutation__
 *
 * To run a mutation, you first call `useCreateDraftFromQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftFromQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftFromQuizMutation, { data, loading, error }] = useCreateDraftFromQuizMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftFromQuizMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftFromQuizMutation,
    CreateDraftFromQuizMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDraftFromQuizMutation,
    CreateDraftFromQuizMutationVariables
  >(CreateDraftFromQuizDocument, options);
}
export type CreateDraftFromQuizMutationHookResult = ReturnType<
  typeof useCreateDraftFromQuizMutation
>;
export type CreateDraftFromQuizMutationResult =
  Apollo.MutationResult<CreateDraftFromQuizMutation>;
export type CreateDraftFromQuizMutationOptions = Apollo.BaseMutationOptions<
  CreateDraftFromQuizMutation,
  CreateDraftFromQuizMutationVariables
>;
export const CreateDraftFromQuizWithContextDocument = gql`
  mutation CreateDraftFromQuizWithContext($input: CreateDraftFromQuizInput!) {
    createDraftFromQuizWithContext(input: $input) {
      _id
      version
      proposer {
        _id
      }
    }
  }
`;
export type CreateDraftFromQuizWithContextMutationFn = Apollo.MutationFunction<
  CreateDraftFromQuizWithContextMutation,
  CreateDraftFromQuizWithContextMutationVariables
>;

/**
 * __useCreateDraftFromQuizWithContextMutation__
 *
 * To run a mutation, you first call `useCreateDraftFromQuizWithContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftFromQuizWithContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftFromQuizWithContextMutation, { data, loading, error }] = useCreateDraftFromQuizWithContextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftFromQuizWithContextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftFromQuizWithContextMutation,
    CreateDraftFromQuizWithContextMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDraftFromQuizWithContextMutation,
    CreateDraftFromQuizWithContextMutationVariables
  >(CreateDraftFromQuizWithContextDocument, options);
}
export type CreateDraftFromQuizWithContextMutationHookResult = ReturnType<
  typeof useCreateDraftFromQuizWithContextMutation
>;
export type CreateDraftFromQuizWithContextMutationResult =
  Apollo.MutationResult<CreateDraftFromQuizWithContextMutation>;
export type CreateDraftFromQuizWithContextMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDraftFromQuizWithContextMutation,
    CreateDraftFromQuizWithContextMutationVariables
  >;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateAuthenticatedUser(input: $input) {
      ...UserWithSession
    }
  }
  ${UserWithSessionFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const ChangeInviteTypeDocument = gql`
  mutation ChangeInviteType($input: ChangeInviteTypeInput!) {
    changeInviteType(input: $input) {
      ...Workspace
    }
  }
  ${WorkspaceFragmentDoc}
`;
export type ChangeInviteTypeMutationFn = Apollo.MutationFunction<
  ChangeInviteTypeMutation,
  ChangeInviteTypeMutationVariables
>;

/**
 * __useChangeInviteTypeMutation__
 *
 * To run a mutation, you first call `useChangeInviteTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeInviteTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeInviteTypeMutation, { data, loading, error }] = useChangeInviteTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeInviteTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeInviteTypeMutation,
    ChangeInviteTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeInviteTypeMutation,
    ChangeInviteTypeMutationVariables
  >(ChangeInviteTypeDocument, options);
}
export type ChangeInviteTypeMutationHookResult = ReturnType<
  typeof useChangeInviteTypeMutation
>;
export type ChangeInviteTypeMutationResult =
  Apollo.MutationResult<ChangeInviteTypeMutation>;
export type ChangeInviteTypeMutationOptions = Apollo.BaseMutationOptions<
  ChangeInviteTypeMutation,
  ChangeInviteTypeMutationVariables
>;
export const DemoteWorkspaceMemberToGuestDocument = gql`
  mutation DemoteWorkspaceMemberToGuest(
    $input: DemoteWorkspaceMemberToGuestInput!
  ) {
    demoteWorkspaceMemberToGuest(input: $input) {
      ...Workspace
    }
  }
  ${WorkspaceFragmentDoc}
`;
export type DemoteWorkspaceMemberToGuestMutationFn = Apollo.MutationFunction<
  DemoteWorkspaceMemberToGuestMutation,
  DemoteWorkspaceMemberToGuestMutationVariables
>;

/**
 * __useDemoteWorkspaceMemberToGuestMutation__
 *
 * To run a mutation, you first call `useDemoteWorkspaceMemberToGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDemoteWorkspaceMemberToGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [demoteWorkspaceMemberToGuestMutation, { data, loading, error }] = useDemoteWorkspaceMemberToGuestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDemoteWorkspaceMemberToGuestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DemoteWorkspaceMemberToGuestMutation,
    DemoteWorkspaceMemberToGuestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DemoteWorkspaceMemberToGuestMutation,
    DemoteWorkspaceMemberToGuestMutationVariables
  >(DemoteWorkspaceMemberToGuestDocument, options);
}
export type DemoteWorkspaceMemberToGuestMutationHookResult = ReturnType<
  typeof useDemoteWorkspaceMemberToGuestMutation
>;
export type DemoteWorkspaceMemberToGuestMutationResult =
  Apollo.MutationResult<DemoteWorkspaceMemberToGuestMutation>;
export type DemoteWorkspaceMemberToGuestMutationOptions =
  Apollo.BaseMutationOptions<
    DemoteWorkspaceMemberToGuestMutation,
    DemoteWorkspaceMemberToGuestMutationVariables
  >;
export const InviteGuestsToWorkspaceDocument = gql`
  mutation InviteGuestsToWorkspace($input: InviteGuestsToWorkspaceInput!) {
    inviteGuestsToWorkspace(input: $input) {
      ...Workspace
    }
  }
  ${WorkspaceFragmentDoc}
`;
export type InviteGuestsToWorkspaceMutationFn = Apollo.MutationFunction<
  InviteGuestsToWorkspaceMutation,
  InviteGuestsToWorkspaceMutationVariables
>;

/**
 * __useInviteGuestsToWorkspaceMutation__
 *
 * To run a mutation, you first call `useInviteGuestsToWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteGuestsToWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteGuestsToWorkspaceMutation, { data, loading, error }] = useInviteGuestsToWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteGuestsToWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteGuestsToWorkspaceMutation,
    InviteGuestsToWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteGuestsToWorkspaceMutation,
    InviteGuestsToWorkspaceMutationVariables
  >(InviteGuestsToWorkspaceDocument, options);
}
export type InviteGuestsToWorkspaceMutationHookResult = ReturnType<
  typeof useInviteGuestsToWorkspaceMutation
>;
export type InviteGuestsToWorkspaceMutationResult =
  Apollo.MutationResult<InviteGuestsToWorkspaceMutation>;
export type InviteGuestsToWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  InviteGuestsToWorkspaceMutation,
  InviteGuestsToWorkspaceMutationVariables
>;
export const PromoteWorkspaceGuestToMemberDocument = gql`
  mutation PromoteWorkspaceGuestToMember(
    $input: PromoteWorkspaceGuestToMemberInput!
  ) {
    promoteWorkspaceGuestToMember(input: $input) {
      ...Workspace
    }
  }
  ${WorkspaceFragmentDoc}
`;
export type PromoteWorkspaceGuestToMemberMutationFn = Apollo.MutationFunction<
  PromoteWorkspaceGuestToMemberMutation,
  PromoteWorkspaceGuestToMemberMutationVariables
>;

/**
 * __usePromoteWorkspaceGuestToMemberMutation__
 *
 * To run a mutation, you first call `usePromoteWorkspaceGuestToMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteWorkspaceGuestToMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteWorkspaceGuestToMemberMutation, { data, loading, error }] = usePromoteWorkspaceGuestToMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromoteWorkspaceGuestToMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PromoteWorkspaceGuestToMemberMutation,
    PromoteWorkspaceGuestToMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PromoteWorkspaceGuestToMemberMutation,
    PromoteWorkspaceGuestToMemberMutationVariables
  >(PromoteWorkspaceGuestToMemberDocument, options);
}
export type PromoteWorkspaceGuestToMemberMutationHookResult = ReturnType<
  typeof usePromoteWorkspaceGuestToMemberMutation
>;
export type PromoteWorkspaceGuestToMemberMutationResult =
  Apollo.MutationResult<PromoteWorkspaceGuestToMemberMutation>;
export type PromoteWorkspaceGuestToMemberMutationOptions =
  Apollo.BaseMutationOptions<
    PromoteWorkspaceGuestToMemberMutation,
    PromoteWorkspaceGuestToMemberMutationVariables
  >;
export const RemoveWorkspaceGuestDocument = gql`
  mutation RemoveWorkspaceGuest($input: RemoveWorkspaceGuestInput!) {
    removeWorkspaceGuest(input: $input) {
      _id
    }
  }
`;
export type RemoveWorkspaceGuestMutationFn = Apollo.MutationFunction<
  RemoveWorkspaceGuestMutation,
  RemoveWorkspaceGuestMutationVariables
>;

/**
 * __useRemoveWorkspaceGuestMutation__
 *
 * To run a mutation, you first call `useRemoveWorkspaceGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkspaceGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkspaceGuestMutation, { data, loading, error }] = useRemoveWorkspaceGuestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveWorkspaceGuestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveWorkspaceGuestMutation,
    RemoveWorkspaceGuestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveWorkspaceGuestMutation,
    RemoveWorkspaceGuestMutationVariables
  >(RemoveWorkspaceGuestDocument, options);
}
export type RemoveWorkspaceGuestMutationHookResult = ReturnType<
  typeof useRemoveWorkspaceGuestMutation
>;
export type RemoveWorkspaceGuestMutationResult =
  Apollo.MutationResult<RemoveWorkspaceGuestMutation>;
export type RemoveWorkspaceGuestMutationOptions = Apollo.BaseMutationOptions<
  RemoveWorkspaceGuestMutation,
  RemoveWorkspaceGuestMutationVariables
>;
export const UpdateWorkspaceProfileDocument = gql`
  mutation UpdateWorkspaceProfile($input: UpdateWorkspaceProfileInput!) {
    updateWorkspaceProfile(input: $input) {
      name
      logoFileId
    }
  }
`;
export type UpdateWorkspaceProfileMutationFn = Apollo.MutationFunction<
  UpdateWorkspaceProfileMutation,
  UpdateWorkspaceProfileMutationVariables
>;

/**
 * __useUpdateWorkspaceProfileMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceProfileMutation, { data, loading, error }] = useUpdateWorkspaceProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceProfileMutation,
    UpdateWorkspaceProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkspaceProfileMutation,
    UpdateWorkspaceProfileMutationVariables
  >(UpdateWorkspaceProfileDocument, options);
}
export type UpdateWorkspaceProfileMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceProfileMutation
>;
export type UpdateWorkspaceProfileMutationResult =
  Apollo.MutationResult<UpdateWorkspaceProfileMutation>;
export type UpdateWorkspaceProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkspaceProfileMutation,
  UpdateWorkspaceProfileMutationVariables
>;
export const GetFeedbackDocument = gql`
  query GetFeedback($params: FeedbackParams!) {
    feedback(params: $params) {
      ...FeedbackCycle
    }
  }
  ${FeedbackCycleFragmentDoc}
`;

/**
 * __useGetFeedbackQuery__
 *
 * To run a query within a React component, call `useGetFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetFeedbackQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeedbackQuery,
    GetFeedbackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeedbackQuery, GetFeedbackQueryVariables>(
    GetFeedbackDocument,
    options
  );
}
export function useGetFeedbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedbackQuery,
    GetFeedbackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeedbackQuery, GetFeedbackQueryVariables>(
    GetFeedbackDocument,
    options
  );
}
export type GetFeedbackQueryHookResult = ReturnType<typeof useGetFeedbackQuery>;
export type GetFeedbackLazyQueryHookResult = ReturnType<
  typeof useGetFeedbackLazyQuery
>;
export type GetFeedbackQueryResult = Apollo.QueryResult<
  GetFeedbackQuery,
  GetFeedbackQueryVariables
>;
export const GetAgreementTypesCountsDocument = gql`
  query GetAgreementTypesCounts($agreementsParams: AgreementsParams!) {
    draftsCount(params: $agreementsParams) {
      total
    }
    activeAgreementsCount(params: $agreementsParams) {
      total
      needsAction
    }
    proposalsCount(params: $agreementsParams) {
      total
      needsAction
    }
    templatesCount {
      total
    }
    allCollectionsCount {
      total
    }
  }
`;

/**
 * __useGetAgreementTypesCountsQuery__
 *
 * To run a query within a React component, call `useGetAgreementTypesCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementTypesCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementTypesCountsQuery({
 *   variables: {
 *      agreementsParams: // value for 'agreementsParams'
 *   },
 * });
 */
export function useGetAgreementTypesCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgreementTypesCountsQuery,
    GetAgreementTypesCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAgreementTypesCountsQuery,
    GetAgreementTypesCountsQueryVariables
  >(GetAgreementTypesCountsDocument, options);
}
export function useGetAgreementTypesCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgreementTypesCountsQuery,
    GetAgreementTypesCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAgreementTypesCountsQuery,
    GetAgreementTypesCountsQueryVariables
  >(GetAgreementTypesCountsDocument, options);
}
export type GetAgreementTypesCountsQueryHookResult = ReturnType<
  typeof useGetAgreementTypesCountsQuery
>;
export type GetAgreementTypesCountsLazyQueryHookResult = ReturnType<
  typeof useGetAgreementTypesCountsLazyQuery
>;
export type GetAgreementTypesCountsQueryResult = Apollo.QueryResult<
  GetAgreementTypesCountsQuery,
  GetAgreementTypesCountsQueryVariables
>;
export const AcceptInviteDocument = gql`
  mutation AcceptInvite($workspaceId: String!) {
    acceptInvite(workspaceId: $workspaceId) {
      _id
      displayName
      workspaceInvitations {
        _id
        workspace {
          name
          _id
        }
      }
      proposalInvitations {
        linkId
        workspace {
          name
          _id
        }
      }
      workspaces {
        name
        _id
      }
      guestOfWorkspaces {
        name
        _id
      }
    }
  }
`;
export type AcceptInviteMutationFn = Apollo.MutationFunction<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useAcceptInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptInviteMutation,
    AcceptInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptInviteMutation,
    AcceptInviteMutationVariables
  >(AcceptInviteDocument, options);
}
export type AcceptInviteMutationHookResult = ReturnType<
  typeof useAcceptInviteMutation
>;
export type AcceptInviteMutationResult =
  Apollo.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>;
export const CreatePromptDocument = gql`
  mutation CreatePrompt($input: CreatePromptInput!) {
    createPrompt(input: $input) {
      ...Prompt
    }
  }
  ${PromptFragmentDoc}
`;
export type CreatePromptMutationFn = Apollo.MutationFunction<
  CreatePromptMutation,
  CreatePromptMutationVariables
>;

/**
 * __useCreatePromptMutation__
 *
 * To run a mutation, you first call `useCreatePromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromptMutation, { data, loading, error }] = useCreatePromptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePromptMutation,
    CreatePromptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePromptMutation,
    CreatePromptMutationVariables
  >(CreatePromptDocument, options);
}
export type CreatePromptMutationHookResult = ReturnType<
  typeof useCreatePromptMutation
>;
export type CreatePromptMutationResult =
  Apollo.MutationResult<CreatePromptMutation>;
export type CreatePromptMutationOptions = Apollo.BaseMutationOptions<
  CreatePromptMutation,
  CreatePromptMutationVariables
>;
export const UpdatePromptDocument = gql`
  mutation UpdatePrompt($input: UpdatePromptInput!) {
    updatePrompt(input: $input) {
      ...Prompt
    }
  }
  ${PromptFragmentDoc}
`;
export type UpdatePromptMutationFn = Apollo.MutationFunction<
  UpdatePromptMutation,
  UpdatePromptMutationVariables
>;

/**
 * __useUpdatePromptMutation__
 *
 * To run a mutation, you first call `useUpdatePromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromptMutation, { data, loading, error }] = useUpdatePromptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePromptMutation,
    UpdatePromptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePromptMutation,
    UpdatePromptMutationVariables
  >(UpdatePromptDocument, options);
}
export type UpdatePromptMutationHookResult = ReturnType<
  typeof useUpdatePromptMutation
>;
export type UpdatePromptMutationResult =
  Apollo.MutationResult<UpdatePromptMutation>;
export type UpdatePromptMutationOptions = Apollo.BaseMutationOptions<
  UpdatePromptMutation,
  UpdatePromptMutationVariables
>;
export const AcknowledgeAgreementDocument = gql`
  mutation acknowledgeAgreement($input: AcknowledgeAgreementInput!) {
    acknowledgeAgreement(input: $input) {
      ...Acknowledge_Agreement
    }
  }
  ${Acknowledge_AgreementFragmentDoc}
`;
export type AcknowledgeAgreementMutationFn = Apollo.MutationFunction<
  AcknowledgeAgreementMutation,
  AcknowledgeAgreementMutationVariables
>;

/**
 * __useAcknowledgeAgreementMutation__
 *
 * To run a mutation, you first call `useAcknowledgeAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeAgreementMutation, { data, loading, error }] = useAcknowledgeAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcknowledgeAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcknowledgeAgreementMutation,
    AcknowledgeAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcknowledgeAgreementMutation,
    AcknowledgeAgreementMutationVariables
  >(AcknowledgeAgreementDocument, options);
}
export type AcknowledgeAgreementMutationHookResult = ReturnType<
  typeof useAcknowledgeAgreementMutation
>;
export type AcknowledgeAgreementMutationResult =
  Apollo.MutationResult<AcknowledgeAgreementMutation>;
export type AcknowledgeAgreementMutationOptions = Apollo.BaseMutationOptions<
  AcknowledgeAgreementMutation,
  AcknowledgeAgreementMutationVariables
>;
export const AddAcknowledgersDocument = gql`
  mutation addAcknowledgers($input: AddAcknowledgersInput!) {
    addAcknowledgers(input: $input) {
      ...Agreement_Roles
    }
  }
  ${Agreement_RolesFragmentDoc}
`;
export type AddAcknowledgersMutationFn = Apollo.MutationFunction<
  AddAcknowledgersMutation,
  AddAcknowledgersMutationVariables
>;

/**
 * __useAddAcknowledgersMutation__
 *
 * To run a mutation, you first call `useAddAcknowledgersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAcknowledgersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAcknowledgersMutation, { data, loading, error }] = useAddAcknowledgersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAcknowledgersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAcknowledgersMutation,
    AddAcknowledgersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAcknowledgersMutation,
    AddAcknowledgersMutationVariables
  >(AddAcknowledgersDocument, options);
}
export type AddAcknowledgersMutationHookResult = ReturnType<
  typeof useAddAcknowledgersMutation
>;
export type AddAcknowledgersMutationResult =
  Apollo.MutationResult<AddAcknowledgersMutation>;
export type AddAcknowledgersMutationOptions = Apollo.BaseMutationOptions<
  AddAcknowledgersMutation,
  AddAcknowledgersMutationVariables
>;
export const ArchiveAgreementDocument = gql`
  mutation ArchiveAgreement($input: ArchiveAgreementInput!) {
    archiveAgreement(input: $input) {
      _id
    }
  }
`;
export type ArchiveAgreementMutationFn = Apollo.MutationFunction<
  ArchiveAgreementMutation,
  ArchiveAgreementMutationVariables
>;

/**
 * __useArchiveAgreementMutation__
 *
 * To run a mutation, you first call `useArchiveAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAgreementMutation, { data, loading, error }] = useArchiveAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveAgreementMutation,
    ArchiveAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveAgreementMutation,
    ArchiveAgreementMutationVariables
  >(ArchiveAgreementDocument, options);
}
export type ArchiveAgreementMutationHookResult = ReturnType<
  typeof useArchiveAgreementMutation
>;
export type ArchiveAgreementMutationResult =
  Apollo.MutationResult<ArchiveAgreementMutation>;
export type ArchiveAgreementMutationOptions = Apollo.BaseMutationOptions<
  ArchiveAgreementMutation,
  ArchiveAgreementMutationVariables
>;
export const CreateDraftDocument = gql`
  mutation CreateDraft($input: CreateDraftInput!) {
    createDraft(input: $input) {
      _id
      version
    }
  }
`;
export type CreateDraftMutationFn = Apollo.MutationFunction<
  CreateDraftMutation,
  CreateDraftMutationVariables
>;

/**
 * __useCreateDraftMutation__
 *
 * To run a mutation, you first call `useCreateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftMutation, { data, loading, error }] = useCreateDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftMutation,
    CreateDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDraftMutation, CreateDraftMutationVariables>(
    CreateDraftDocument,
    options
  );
}
export type CreateDraftMutationHookResult = ReturnType<
  typeof useCreateDraftMutation
>;
export type CreateDraftMutationResult =
  Apollo.MutationResult<CreateDraftMutation>;
export type CreateDraftMutationOptions = Apollo.BaseMutationOptions<
  CreateDraftMutation,
  CreateDraftMutationVariables
>;
export const CreateDraftVersionFromActiveAgreementDocument = gql`
  mutation CreateDraftVersionFromActiveAgreement(
    $input: CreateDraftVersionFromActiveAgreementInput!
  ) {
    createDraftVersionFromActiveAgreement(input: $input) {
      _id
      workspace {
        _id
      }
      version
    }
  }
`;
export type CreateDraftVersionFromActiveAgreementMutationFn =
  Apollo.MutationFunction<
    CreateDraftVersionFromActiveAgreementMutation,
    CreateDraftVersionFromActiveAgreementMutationVariables
  >;

/**
 * __useCreateDraftVersionFromActiveAgreementMutation__
 *
 * To run a mutation, you first call `useCreateDraftVersionFromActiveAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftVersionFromActiveAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftVersionFromActiveAgreementMutation, { data, loading, error }] = useCreateDraftVersionFromActiveAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftVersionFromActiveAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftVersionFromActiveAgreementMutation,
    CreateDraftVersionFromActiveAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDraftVersionFromActiveAgreementMutation,
    CreateDraftVersionFromActiveAgreementMutationVariables
  >(CreateDraftVersionFromActiveAgreementDocument, options);
}
export type CreateDraftVersionFromActiveAgreementMutationHookResult =
  ReturnType<typeof useCreateDraftVersionFromActiveAgreementMutation>;
export type CreateDraftVersionFromActiveAgreementMutationResult =
  Apollo.MutationResult<CreateDraftVersionFromActiveAgreementMutation>;
export type CreateDraftVersionFromActiveAgreementMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDraftVersionFromActiveAgreementMutation,
    CreateDraftVersionFromActiveAgreementMutationVariables
  >;
export const DeleteDraftDocument = gql`
  mutation DeleteDraft($input: DeleteDraftInput!) {
    deleteDraft(input: $input) {
      _id
    }
  }
`;
export type DeleteDraftMutationFn = Apollo.MutationFunction<
  DeleteDraftMutation,
  DeleteDraftMutationVariables
>;

/**
 * __useDeleteDraftMutation__
 *
 * To run a mutation, you first call `useDeleteDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftMutation, { data, loading, error }] = useDeleteDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDraftMutation,
    DeleteDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDraftMutation, DeleteDraftMutationVariables>(
    DeleteDraftDocument,
    options
  );
}
export type DeleteDraftMutationHookResult = ReturnType<
  typeof useDeleteDraftMutation
>;
export type DeleteDraftMutationResult =
  Apollo.MutationResult<DeleteDraftMutation>;
export type DeleteDraftMutationOptions = Apollo.BaseMutationOptions<
  DeleteDraftMutation,
  DeleteDraftMutationVariables
>;
export const DraftFromExistingAgreementDocument = gql`
  mutation DraftFromExistingAgreement(
    $input: DraftFromExistingAgreementInput!
  ) {
    draftFromExistingAgreement(input: $input) {
      _id
      workspace {
        _id
      }
    }
  }
`;
export type DraftFromExistingAgreementMutationFn = Apollo.MutationFunction<
  DraftFromExistingAgreementMutation,
  DraftFromExistingAgreementMutationVariables
>;

/**
 * __useDraftFromExistingAgreementMutation__
 *
 * To run a mutation, you first call `useDraftFromExistingAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftFromExistingAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftFromExistingAgreementMutation, { data, loading, error }] = useDraftFromExistingAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDraftFromExistingAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DraftFromExistingAgreementMutation,
    DraftFromExistingAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DraftFromExistingAgreementMutation,
    DraftFromExistingAgreementMutationVariables
  >(DraftFromExistingAgreementDocument, options);
}
export type DraftFromExistingAgreementMutationHookResult = ReturnType<
  typeof useDraftFromExistingAgreementMutation
>;
export type DraftFromExistingAgreementMutationResult =
  Apollo.MutationResult<DraftFromExistingAgreementMutation>;
export type DraftFromExistingAgreementMutationOptions =
  Apollo.BaseMutationOptions<
    DraftFromExistingAgreementMutation,
    DraftFromExistingAgreementMutationVariables
  >;
export const ExtendExpirationDateDocument = gql`
  mutation ExtendExpirationDate($input: UpdateExpirationDateInput!) {
    updateExpirationDate(input: $input) {
      _id
      expirationDate
    }
  }
`;
export type ExtendExpirationDateMutationFn = Apollo.MutationFunction<
  ExtendExpirationDateMutation,
  ExtendExpirationDateMutationVariables
>;

/**
 * __useExtendExpirationDateMutation__
 *
 * To run a mutation, you first call `useExtendExpirationDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendExpirationDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendExpirationDateMutation, { data, loading, error }] = useExtendExpirationDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtendExpirationDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExtendExpirationDateMutation,
    ExtendExpirationDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExtendExpirationDateMutation,
    ExtendExpirationDateMutationVariables
  >(ExtendExpirationDateDocument, options);
}
export type ExtendExpirationDateMutationHookResult = ReturnType<
  typeof useExtendExpirationDateMutation
>;
export type ExtendExpirationDateMutationResult =
  Apollo.MutationResult<ExtendExpirationDateMutation>;
export type ExtendExpirationDateMutationOptions = Apollo.BaseMutationOptions<
  ExtendExpirationDateMutation,
  ExtendExpirationDateMutationVariables
>;
export const MarkAgreementSeenDocument = gql`
  mutation MarkAgreementSeen($input: MarkAgreementSeenInput!) {
    markAgreementSeen(input: $input) {
      seen
    }
  }
`;
export type MarkAgreementSeenMutationFn = Apollo.MutationFunction<
  MarkAgreementSeenMutation,
  MarkAgreementSeenMutationVariables
>;

/**
 * __useMarkAgreementSeenMutation__
 *
 * To run a mutation, you first call `useMarkAgreementSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAgreementSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAgreementSeenMutation, { data, loading, error }] = useMarkAgreementSeenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkAgreementSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAgreementSeenMutation,
    MarkAgreementSeenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAgreementSeenMutation,
    MarkAgreementSeenMutationVariables
  >(MarkAgreementSeenDocument, options);
}
export type MarkAgreementSeenMutationHookResult = ReturnType<
  typeof useMarkAgreementSeenMutation
>;
export type MarkAgreementSeenMutationResult =
  Apollo.MutationResult<MarkAgreementSeenMutation>;
export type MarkAgreementSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkAgreementSeenMutation,
  MarkAgreementSeenMutationVariables
>;
export const MarkDraftAsTemplateDocument = gql`
  mutation MarkDraftAsTemplate($_id: String!) {
    markDraftAsTemplate(_id: $_id) {
      ...Draft
    }
  }
  ${DraftFragmentDoc}
`;
export type MarkDraftAsTemplateMutationFn = Apollo.MutationFunction<
  MarkDraftAsTemplateMutation,
  MarkDraftAsTemplateMutationVariables
>;

/**
 * __useMarkDraftAsTemplateMutation__
 *
 * To run a mutation, you first call `useMarkDraftAsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDraftAsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDraftAsTemplateMutation, { data, loading, error }] = useMarkDraftAsTemplateMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useMarkDraftAsTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkDraftAsTemplateMutation,
    MarkDraftAsTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkDraftAsTemplateMutation,
    MarkDraftAsTemplateMutationVariables
  >(MarkDraftAsTemplateDocument, options);
}
export type MarkDraftAsTemplateMutationHookResult = ReturnType<
  typeof useMarkDraftAsTemplateMutation
>;
export type MarkDraftAsTemplateMutationResult =
  Apollo.MutationResult<MarkDraftAsTemplateMutation>;
export type MarkDraftAsTemplateMutationOptions = Apollo.BaseMutationOptions<
  MarkDraftAsTemplateMutation,
  MarkDraftAsTemplateMutationVariables
>;
export const ProposeAgreementDocument = gql`
  mutation ProposeAgreement($_id: String!, $input: ProposeDraftInput!) {
    proposeAgreement(_id: $_id, input: $input) {
      ...Proposal
      ...Agreement
    }
  }
  ${ProposalFragmentDoc}
  ${AgreementFragmentDoc}
`;
export type ProposeAgreementMutationFn = Apollo.MutationFunction<
  ProposeAgreementMutation,
  ProposeAgreementMutationVariables
>;

/**
 * __useProposeAgreementMutation__
 *
 * To run a mutation, you first call `useProposeAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProposeAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proposeAgreementMutation, { data, loading, error }] = useProposeAgreementMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProposeAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProposeAgreementMutation,
    ProposeAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProposeAgreementMutation,
    ProposeAgreementMutationVariables
  >(ProposeAgreementDocument, options);
}
export type ProposeAgreementMutationHookResult = ReturnType<
  typeof useProposeAgreementMutation
>;
export type ProposeAgreementMutationResult =
  Apollo.MutationResult<ProposeAgreementMutation>;
export type ProposeAgreementMutationOptions = Apollo.BaseMutationOptions<
  ProposeAgreementMutation,
  ProposeAgreementMutationVariables
>;
export const ProposeArchiveDocument = gql`
  mutation ProposeArchive($input: ProposeArchiveOrExtendInput!) {
    proposeArchive(input: $input) {
      ...Proposal
    }
  }
  ${ProposalFragmentDoc}
`;
export type ProposeArchiveMutationFn = Apollo.MutationFunction<
  ProposeArchiveMutation,
  ProposeArchiveMutationVariables
>;

/**
 * __useProposeArchiveMutation__
 *
 * To run a mutation, you first call `useProposeArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProposeArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proposeArchiveMutation, { data, loading, error }] = useProposeArchiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProposeArchiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProposeArchiveMutation,
    ProposeArchiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProposeArchiveMutation,
    ProposeArchiveMutationVariables
  >(ProposeArchiveDocument, options);
}
export type ProposeArchiveMutationHookResult = ReturnType<
  typeof useProposeArchiveMutation
>;
export type ProposeArchiveMutationResult =
  Apollo.MutationResult<ProposeArchiveMutation>;
export type ProposeArchiveMutationOptions = Apollo.BaseMutationOptions<
  ProposeArchiveMutation,
  ProposeArchiveMutationVariables
>;
export const ProposeExtendExpirationDocument = gql`
  mutation ProposeExtendExpiration($input: ProposeArchiveOrExtendInput!) {
    proposeExtendExpiration(input: $input) {
      ...Proposal
    }
  }
  ${ProposalFragmentDoc}
`;
export type ProposeExtendExpirationMutationFn = Apollo.MutationFunction<
  ProposeExtendExpirationMutation,
  ProposeExtendExpirationMutationVariables
>;

/**
 * __useProposeExtendExpirationMutation__
 *
 * To run a mutation, you first call `useProposeExtendExpirationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProposeExtendExpirationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proposeExtendExpirationMutation, { data, loading, error }] = useProposeExtendExpirationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProposeExtendExpirationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProposeExtendExpirationMutation,
    ProposeExtendExpirationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProposeExtendExpirationMutation,
    ProposeExtendExpirationMutationVariables
  >(ProposeExtendExpirationDocument, options);
}
export type ProposeExtendExpirationMutationHookResult = ReturnType<
  typeof useProposeExtendExpirationMutation
>;
export type ProposeExtendExpirationMutationResult =
  Apollo.MutationResult<ProposeExtendExpirationMutation>;
export type ProposeExtendExpirationMutationOptions = Apollo.BaseMutationOptions<
  ProposeExtendExpirationMutation,
  ProposeExtendExpirationMutationVariables
>;
export const UnarchiveAgreementDocument = gql`
  mutation UnarchiveAgreement($input: UnarchiveAgreementInput!) {
    unarchiveAgreement(input: $input) {
      _id
    }
  }
`;
export type UnarchiveAgreementMutationFn = Apollo.MutationFunction<
  UnarchiveAgreementMutation,
  UnarchiveAgreementMutationVariables
>;

/**
 * __useUnarchiveAgreementMutation__
 *
 * To run a mutation, you first call `useUnarchiveAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveAgreementMutation, { data, loading, error }] = useUnarchiveAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveAgreementMutation,
    UnarchiveAgreementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnarchiveAgreementMutation,
    UnarchiveAgreementMutationVariables
  >(UnarchiveAgreementDocument, options);
}
export type UnarchiveAgreementMutationHookResult = ReturnType<
  typeof useUnarchiveAgreementMutation
>;
export type UnarchiveAgreementMutationResult =
  Apollo.MutationResult<UnarchiveAgreementMutation>;
export type UnarchiveAgreementMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveAgreementMutation,
  UnarchiveAgreementMutationVariables
>;
export const UpdateAgreementPropertiesDocument = gql`
  mutation UpdateAgreementProperties($input: UpdatePropertiesInput!) {
    updateAgreementProperties(input: $input) {
      ...Agreement
    }
  }
  ${AgreementFragmentDoc}
`;
export type UpdateAgreementPropertiesMutationFn = Apollo.MutationFunction<
  UpdateAgreementPropertiesMutation,
  UpdateAgreementPropertiesMutationVariables
>;

/**
 * __useUpdateAgreementPropertiesMutation__
 *
 * To run a mutation, you first call `useUpdateAgreementPropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgreementPropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgreementPropertiesMutation, { data, loading, error }] = useUpdateAgreementPropertiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAgreementPropertiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAgreementPropertiesMutation,
    UpdateAgreementPropertiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAgreementPropertiesMutation,
    UpdateAgreementPropertiesMutationVariables
  >(UpdateAgreementPropertiesDocument, options);
}
export type UpdateAgreementPropertiesMutationHookResult = ReturnType<
  typeof useUpdateAgreementPropertiesMutation
>;
export type UpdateAgreementPropertiesMutationResult =
  Apollo.MutationResult<UpdateAgreementPropertiesMutation>;
export type UpdateAgreementPropertiesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAgreementPropertiesMutation,
    UpdateAgreementPropertiesMutationVariables
  >;
export const UpdateDraftDocument = gql`
  mutation UpdateDraft($_id: String!, $input: DraftInput!) {
    updateDraft(_id: $_id, input: $input) {
      ...Draft
    }
  }
  ${DraftFragmentDoc}
`;
export type UpdateDraftMutationFn = Apollo.MutationFunction<
  UpdateDraftMutation,
  UpdateDraftMutationVariables
>;

/**
 * __useUpdateDraftMutation__
 *
 * To run a mutation, you first call `useUpdateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftMutation, { data, loading, error }] = useUpdateDraftMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDraftMutation,
    UpdateDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDraftMutation, UpdateDraftMutationVariables>(
    UpdateDraftDocument,
    options
  );
}
export type UpdateDraftMutationHookResult = ReturnType<
  typeof useUpdateDraftMutation
>;
export type UpdateDraftMutationResult =
  Apollo.MutationResult<UpdateDraftMutation>;
export type UpdateDraftMutationOptions = Apollo.BaseMutationOptions<
  UpdateDraftMutation,
  UpdateDraftMutationVariables
>;
export const UpdateTemplateDocument = gql`
  mutation UpdateTemplate($_id: String!, $input: DraftInput!) {
    updateTemplate(_id: $_id, input: $input) {
      ...Draft
    }
  }
  ${DraftFragmentDoc}
`;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables
>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTemplateMutation,
    UpdateTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTemplateMutation,
    UpdateTemplateMutationVariables
  >(UpdateTemplateDocument, options);
}
export type UpdateTemplateMutationHookResult = ReturnType<
  typeof useUpdateTemplateMutation
>;
export type UpdateTemplateMutationResult =
  Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables
>;
export const UpdateTemplatePropertiesDocument = gql`
  mutation UpdateTemplateProperties($input: UpdatePropertiesInput!) {
    updateTemplateProperties(input: $input) {
      ...Draft
    }
  }
  ${DraftFragmentDoc}
`;
export type UpdateTemplatePropertiesMutationFn = Apollo.MutationFunction<
  UpdateTemplatePropertiesMutation,
  UpdateTemplatePropertiesMutationVariables
>;

/**
 * __useUpdateTemplatePropertiesMutation__
 *
 * To run a mutation, you first call `useUpdateTemplatePropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplatePropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplatePropertiesMutation, { data, loading, error }] = useUpdateTemplatePropertiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplatePropertiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTemplatePropertiesMutation,
    UpdateTemplatePropertiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTemplatePropertiesMutation,
    UpdateTemplatePropertiesMutationVariables
  >(UpdateTemplatePropertiesDocument, options);
}
export type UpdateTemplatePropertiesMutationHookResult = ReturnType<
  typeof useUpdateTemplatePropertiesMutation
>;
export type UpdateTemplatePropertiesMutationResult =
  Apollo.MutationResult<UpdateTemplatePropertiesMutation>;
export type UpdateTemplatePropertiesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTemplatePropertiesMutation,
    UpdateTemplatePropertiesMutationVariables
  >;
export const CreateCollectionDocument = gql`
  mutation CreateCollection {
    createCollection {
      ...PopulatedCollection
    }
  }
  ${PopulatedCollectionFragmentDoc}
`;
export type CreateCollectionMutationFn = Apollo.MutationFunction<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCollectionMutation,
    CreateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCollectionMutation,
    CreateCollectionMutationVariables
  >(CreateCollectionDocument, options);
}
export type CreateCollectionMutationHookResult = ReturnType<
  typeof useCreateCollectionMutation
>;
export type CreateCollectionMutationResult =
  Apollo.MutationResult<CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>;
export const UpdateCollectionDocument = gql`
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      ...PopulatedCollection
    }
  }
  ${PopulatedCollectionFragmentDoc}
`;
export type UpdateCollectionMutationFn = Apollo.MutationFunction<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>;

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionMutation,
    UpdateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCollectionMutation,
    UpdateCollectionMutationVariables
  >(UpdateCollectionDocument, options);
}
export type UpdateCollectionMutationHookResult = ReturnType<
  typeof useUpdateCollectionMutation
>;
export type UpdateCollectionMutationResult =
  Apollo.MutationResult<UpdateCollectionMutation>;
export type UpdateCollectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>;
export const CreateWorkspaceDocument = gql`
  mutation createWorkspace($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      _id
      name
    }
  }
`;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkspaceMutation,
    CreateWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWorkspaceMutation,
    CreateWorkspaceMutationVariables
  >(CreateWorkspaceDocument, options);
}
export type CreateWorkspaceMutationHookResult = ReturnType<
  typeof useCreateWorkspaceMutation
>;
export type CreateWorkspaceMutationResult =
  Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;
export const DeleteInvitationDocument = gql`
  mutation DeleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      _id
      invitedEmails {
        email
        workspaceGuest
      }
    }
  }
`;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvitationMutation,
    DeleteInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvitationMutation,
    DeleteInvitationMutationVariables
  >(DeleteInvitationDocument, options);
}
export type DeleteInvitationMutationHookResult = ReturnType<
  typeof useDeleteInvitationMutation
>;
export type DeleteInvitationMutationResult =
  Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;
export const UpdateDraftPropertiesDocument = gql`
  mutation UpdateDraftProperties($input: UpdatePropertiesInput!) {
    updateDraftProperties(input: $input) {
      ...Draft
    }
  }
  ${DraftFragmentDoc}
`;
export type UpdateDraftPropertiesMutationFn = Apollo.MutationFunction<
  UpdateDraftPropertiesMutation,
  UpdateDraftPropertiesMutationVariables
>;

/**
 * __useUpdateDraftPropertiesMutation__
 *
 * To run a mutation, you first call `useUpdateDraftPropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftPropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftPropertiesMutation, { data, loading, error }] = useUpdateDraftPropertiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDraftPropertiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDraftPropertiesMutation,
    UpdateDraftPropertiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDraftPropertiesMutation,
    UpdateDraftPropertiesMutationVariables
  >(UpdateDraftPropertiesDocument, options);
}
export type UpdateDraftPropertiesMutationHookResult = ReturnType<
  typeof useUpdateDraftPropertiesMutation
>;
export type UpdateDraftPropertiesMutationResult =
  Apollo.MutationResult<UpdateDraftPropertiesMutation>;
export type UpdateDraftPropertiesMutationOptions = Apollo.BaseMutationOptions<
  UpdateDraftPropertiesMutation,
  UpdateDraftPropertiesMutationVariables
>;
export const AddGroupNotificationPreferenceDocument = gql`
  mutation AddGroupNotificationPreference(
    $input: AddGroupNotificationPreferenceInput!
  ) {
    addGroupNotificationPreference(input: $input) {
      type
      channelUrl
      channelId
      groupId
    }
  }
`;
export type AddGroupNotificationPreferenceMutationFn = Apollo.MutationFunction<
  AddGroupNotificationPreferenceMutation,
  AddGroupNotificationPreferenceMutationVariables
>;

/**
 * __useAddGroupNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useAddGroupNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupNotificationPreferenceMutation, { data, loading, error }] = useAddGroupNotificationPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupNotificationPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupNotificationPreferenceMutation,
    AddGroupNotificationPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGroupNotificationPreferenceMutation,
    AddGroupNotificationPreferenceMutationVariables
  >(AddGroupNotificationPreferenceDocument, options);
}
export type AddGroupNotificationPreferenceMutationHookResult = ReturnType<
  typeof useAddGroupNotificationPreferenceMutation
>;
export type AddGroupNotificationPreferenceMutationResult =
  Apollo.MutationResult<AddGroupNotificationPreferenceMutation>;
export type AddGroupNotificationPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    AddGroupNotificationPreferenceMutation,
    AddGroupNotificationPreferenceMutationVariables
  >;
export const AddMembersToGroupDocument = gql`
  mutation AddMembersToGroup($input: AddMembersToGroupInput!) {
    addMembersToGroup(input: $input) {
      _id
      name
      description
      members {
        ...FullMember
      }
    }
  }
  ${FullMemberFragmentDoc}
`;
export type AddMembersToGroupMutationFn = Apollo.MutationFunction<
  AddMembersToGroupMutation,
  AddMembersToGroupMutationVariables
>;

/**
 * __useAddMembersToGroupMutation__
 *
 * To run a mutation, you first call `useAddMembersToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembersToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembersToGroupMutation, { data, loading, error }] = useAddMembersToGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMembersToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMembersToGroupMutation,
    AddMembersToGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddMembersToGroupMutation,
    AddMembersToGroupMutationVariables
  >(AddMembersToGroupDocument, options);
}
export type AddMembersToGroupMutationHookResult = ReturnType<
  typeof useAddMembersToGroupMutation
>;
export type AddMembersToGroupMutationResult =
  Apollo.MutationResult<AddMembersToGroupMutation>;
export type AddMembersToGroupMutationOptions = Apollo.BaseMutationOptions<
  AddMembersToGroupMutation,
  AddMembersToGroupMutationVariables
>;
export const CreateGroupDocument = gql`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      ...PopulatedGroup
    }
  }
  ${PopulatedGroupFragmentDoc}
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(
    CreateGroupDocument,
    options
  );
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult =
  Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation DeleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      _id
    }
  }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(
    DeleteGroupDocument,
    options
  );
}
export type DeleteGroupMutationHookResult = ReturnType<
  typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult =
  Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const RemoveGroupNotificationPreferenceDocument = gql`
  mutation RemoveGroupNotificationPreference(
    $input: RemoveGroupNotificationPreferenceInput!
  ) {
    removeGroupNotificationPreference(input: $input) {
      type
      channelId
      groupId
    }
  }
`;
export type RemoveGroupNotificationPreferenceMutationFn =
  Apollo.MutationFunction<
    RemoveGroupNotificationPreferenceMutation,
    RemoveGroupNotificationPreferenceMutationVariables
  >;

/**
 * __useRemoveGroupNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useRemoveGroupNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupNotificationPreferenceMutation, { data, loading, error }] = useRemoveGroupNotificationPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGroupNotificationPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupNotificationPreferenceMutation,
    RemoveGroupNotificationPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGroupNotificationPreferenceMutation,
    RemoveGroupNotificationPreferenceMutationVariables
  >(RemoveGroupNotificationPreferenceDocument, options);
}
export type RemoveGroupNotificationPreferenceMutationHookResult = ReturnType<
  typeof useRemoveGroupNotificationPreferenceMutation
>;
export type RemoveGroupNotificationPreferenceMutationResult =
  Apollo.MutationResult<RemoveGroupNotificationPreferenceMutation>;
export type RemoveGroupNotificationPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveGroupNotificationPreferenceMutation,
    RemoveGroupNotificationPreferenceMutationVariables
  >;
export const RemoveMembersFromGroupDocument = gql`
  mutation RemoveMembersFromGroup($input: RemoveMembersFromGroupInput!) {
    removeMembersFromGroup(input: $input) {
      _id
      name
      members {
        ...FullMember
      }
    }
  }
  ${FullMemberFragmentDoc}
`;
export type RemoveMembersFromGroupMutationFn = Apollo.MutationFunction<
  RemoveMembersFromGroupMutation,
  RemoveMembersFromGroupMutationVariables
>;

/**
 * __useRemoveMembersFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveMembersFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMembersFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMembersFromGroupMutation, { data, loading, error }] = useRemoveMembersFromGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMembersFromGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMembersFromGroupMutation,
    RemoveMembersFromGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMembersFromGroupMutation,
    RemoveMembersFromGroupMutationVariables
  >(RemoveMembersFromGroupDocument, options);
}
export type RemoveMembersFromGroupMutationHookResult = ReturnType<
  typeof useRemoveMembersFromGroupMutation
>;
export type RemoveMembersFromGroupMutationResult =
  Apollo.MutationResult<RemoveMembersFromGroupMutation>;
export type RemoveMembersFromGroupMutationOptions = Apollo.BaseMutationOptions<
  RemoveMembersFromGroupMutation,
  RemoveMembersFromGroupMutationVariables
>;
export const UpdateGroupSettingsDocument = gql`
  mutation UpdateGroupSettings($input: UpdateGroupSettingsInput!) {
    updateGroupSettings(input: $input) {
      _id
      name
      description
      members {
        ...FullMember
      }
    }
  }
  ${FullMemberFragmentDoc}
`;
export type UpdateGroupSettingsMutationFn = Apollo.MutationFunction<
  UpdateGroupSettingsMutation,
  UpdateGroupSettingsMutationVariables
>;

/**
 * __useUpdateGroupSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateGroupSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupSettingsMutation, { data, loading, error }] = useUpdateGroupSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupSettingsMutation,
    UpdateGroupSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGroupSettingsMutation,
    UpdateGroupSettingsMutationVariables
  >(UpdateGroupSettingsDocument, options);
}
export type UpdateGroupSettingsMutationHookResult = ReturnType<
  typeof useUpdateGroupSettingsMutation
>;
export type UpdateGroupSettingsMutationResult =
  Apollo.MutationResult<UpdateGroupSettingsMutation>;
export type UpdateGroupSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupSettingsMutation,
  UpdateGroupSettingsMutationVariables
>;
export const UpdateMemberRoleDocument = gql`
  mutation UpdateMemberRole($input: UpdateMemberRoleInput!) {
    updateMemberRole(input: $input) {
      _id
      name
      description
      members {
        ...FullMember
      }
    }
  }
  ${FullMemberFragmentDoc}
`;
export type UpdateMemberRoleMutationFn = Apollo.MutationFunction<
  UpdateMemberRoleMutation,
  UpdateMemberRoleMutationVariables
>;

/**
 * __useUpdateMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberRoleMutation, { data, loading, error }] = useUpdateMemberRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberRoleMutation,
    UpdateMemberRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberRoleMutation,
    UpdateMemberRoleMutationVariables
  >(UpdateMemberRoleDocument, options);
}
export type UpdateMemberRoleMutationHookResult = ReturnType<
  typeof useUpdateMemberRoleMutation
>;
export type UpdateMemberRoleMutationResult =
  Apollo.MutationResult<UpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberRoleMutation,
  UpdateMemberRoleMutationVariables
>;
export const MergeWorkspacesDocument = gql`
  mutation MergeWorkspaces($input: MergeWorkspacesInput!) {
    mergeWorkspaces(input: $input) {
      ...WorkspaceMergeData
    }
  }
  ${WorkspaceMergeDataFragmentDoc}
`;
export type MergeWorkspacesMutationFn = Apollo.MutationFunction<
  MergeWorkspacesMutation,
  MergeWorkspacesMutationVariables
>;

/**
 * __useMergeWorkspacesMutation__
 *
 * To run a mutation, you first call `useMergeWorkspacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeWorkspacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeWorkspacesMutation, { data, loading, error }] = useMergeWorkspacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeWorkspacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MergeWorkspacesMutation,
    MergeWorkspacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MergeWorkspacesMutation,
    MergeWorkspacesMutationVariables
  >(MergeWorkspacesDocument, options);
}
export type MergeWorkspacesMutationHookResult = ReturnType<
  typeof useMergeWorkspacesMutation
>;
export type MergeWorkspacesMutationResult =
  Apollo.MutationResult<MergeWorkspacesMutation>;
export type MergeWorkspacesMutationOptions = Apollo.BaseMutationOptions<
  MergeWorkspacesMutation,
  MergeWorkspacesMutationVariables
>;
export const AddInteractionReactionDocument = gql`
  mutation AddInteractionReaction($input: UpdateInteractionReactionInput!) {
    addInteractionReaction(input: $input) {
      ...InteractionReaction
    }
  }
  ${InteractionReactionFragmentDoc}
`;
export type AddInteractionReactionMutationFn = Apollo.MutationFunction<
  AddInteractionReactionMutation,
  AddInteractionReactionMutationVariables
>;

/**
 * __useAddInteractionReactionMutation__
 *
 * To run a mutation, you first call `useAddInteractionReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInteractionReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInteractionReactionMutation, { data, loading, error }] = useAddInteractionReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInteractionReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInteractionReactionMutation,
    AddInteractionReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddInteractionReactionMutation,
    AddInteractionReactionMutationVariables
  >(AddInteractionReactionDocument, options);
}
export type AddInteractionReactionMutationHookResult = ReturnType<
  typeof useAddInteractionReactionMutation
>;
export type AddInteractionReactionMutationResult =
  Apollo.MutationResult<AddInteractionReactionMutation>;
export type AddInteractionReactionMutationOptions = Apollo.BaseMutationOptions<
  AddInteractionReactionMutation,
  AddInteractionReactionMutationVariables
>;
export const AdvanceProposalForProposerDocument = gql`
  mutation AdvanceProposalForProposer(
    $input: AdvanceProposalForProposerInput!
  ) {
    advanceProposalForProposer(input: $input) {
      ...Proposal
    }
  }
  ${ProposalFragmentDoc}
`;
export type AdvanceProposalForProposerMutationFn = Apollo.MutationFunction<
  AdvanceProposalForProposerMutation,
  AdvanceProposalForProposerMutationVariables
>;

/**
 * __useAdvanceProposalForProposerMutation__
 *
 * To run a mutation, you first call `useAdvanceProposalForProposerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvanceProposalForProposerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advanceProposalForProposerMutation, { data, loading, error }] = useAdvanceProposalForProposerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvanceProposalForProposerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdvanceProposalForProposerMutation,
    AdvanceProposalForProposerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdvanceProposalForProposerMutation,
    AdvanceProposalForProposerMutationVariables
  >(AdvanceProposalForProposerDocument, options);
}
export type AdvanceProposalForProposerMutationHookResult = ReturnType<
  typeof useAdvanceProposalForProposerMutation
>;
export type AdvanceProposalForProposerMutationResult =
  Apollo.MutationResult<AdvanceProposalForProposerMutation>;
export type AdvanceProposalForProposerMutationOptions =
  Apollo.BaseMutationOptions<
    AdvanceProposalForProposerMutation,
    AdvanceProposalForProposerMutationVariables
  >;
export const CreateInteractionDocument = gql`
  mutation CreateInteraction($input: CreateInteractionInput!) {
    createInteraction(input: $input) {
      ...Interaction
    }
  }
  ${InteractionFragmentDoc}
`;
export type CreateInteractionMutationFn = Apollo.MutationFunction<
  CreateInteractionMutation,
  CreateInteractionMutationVariables
>;

/**
 * __useCreateInteractionMutation__
 *
 * To run a mutation, you first call `useCreateInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInteractionMutation, { data, loading, error }] = useCreateInteractionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInteractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInteractionMutation,
    CreateInteractionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInteractionMutation,
    CreateInteractionMutationVariables
  >(CreateInteractionDocument, options);
}
export type CreateInteractionMutationHookResult = ReturnType<
  typeof useCreateInteractionMutation
>;
export type CreateInteractionMutationResult =
  Apollo.MutationResult<CreateInteractionMutation>;
export type CreateInteractionMutationOptions = Apollo.BaseMutationOptions<
  CreateInteractionMutation,
  CreateInteractionMutationVariables
>;
export const CreateInteractionResponseDocument = gql`
  mutation CreateInteractionResponse($input: CreateInteractionResponseInput!) {
    createInteractionResponse(input: $input) {
      ...InteractionResponse
    }
  }
  ${InteractionResponseFragmentDoc}
`;
export type CreateInteractionResponseMutationFn = Apollo.MutationFunction<
  CreateInteractionResponseMutation,
  CreateInteractionResponseMutationVariables
>;

/**
 * __useCreateInteractionResponseMutation__
 *
 * To run a mutation, you first call `useCreateInteractionResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInteractionResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInteractionResponseMutation, { data, loading, error }] = useCreateInteractionResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInteractionResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInteractionResponseMutation,
    CreateInteractionResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInteractionResponseMutation,
    CreateInteractionResponseMutationVariables
  >(CreateInteractionResponseDocument, options);
}
export type CreateInteractionResponseMutationHookResult = ReturnType<
  typeof useCreateInteractionResponseMutation
>;
export type CreateInteractionResponseMutationResult =
  Apollo.MutationResult<CreateInteractionResponseMutation>;
export type CreateInteractionResponseMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInteractionResponseMutation,
    CreateInteractionResponseMutationVariables
  >;
export const DelayProposalAdvancementDocument = gql`
  mutation DelayProposalAdvancement($input: DelayProposalAdvancementInput!) {
    delayProposalAdvancement(input: $input) {
      ...Proposal
    }
  }
  ${ProposalFragmentDoc}
`;
export type DelayProposalAdvancementMutationFn = Apollo.MutationFunction<
  DelayProposalAdvancementMutation,
  DelayProposalAdvancementMutationVariables
>;

/**
 * __useDelayProposalAdvancementMutation__
 *
 * To run a mutation, you first call `useDelayProposalAdvancementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelayProposalAdvancementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [delayProposalAdvancementMutation, { data, loading, error }] = useDelayProposalAdvancementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelayProposalAdvancementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DelayProposalAdvancementMutation,
    DelayProposalAdvancementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DelayProposalAdvancementMutation,
    DelayProposalAdvancementMutationVariables
  >(DelayProposalAdvancementDocument, options);
}
export type DelayProposalAdvancementMutationHookResult = ReturnType<
  typeof useDelayProposalAdvancementMutation
>;
export type DelayProposalAdvancementMutationResult =
  Apollo.MutationResult<DelayProposalAdvancementMutation>;
export type DelayProposalAdvancementMutationOptions =
  Apollo.BaseMutationOptions<
    DelayProposalAdvancementMutation,
    DelayProposalAdvancementMutationVariables
  >;
export const DeleteInteractionFromProposalDocument = gql`
  mutation DeleteInteractionFromProposal($input: DeleteInteractionInput!) {
    deleteInteraction(input: $input) {
      _id
      proposalId
    }
  }
`;
export type DeleteInteractionFromProposalMutationFn = Apollo.MutationFunction<
  DeleteInteractionFromProposalMutation,
  DeleteInteractionFromProposalMutationVariables
>;

/**
 * __useDeleteInteractionFromProposalMutation__
 *
 * To run a mutation, you first call `useDeleteInteractionFromProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInteractionFromProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInteractionFromProposalMutation, { data, loading, error }] = useDeleteInteractionFromProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInteractionFromProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInteractionFromProposalMutation,
    DeleteInteractionFromProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInteractionFromProposalMutation,
    DeleteInteractionFromProposalMutationVariables
  >(DeleteInteractionFromProposalDocument, options);
}
export type DeleteInteractionFromProposalMutationHookResult = ReturnType<
  typeof useDeleteInteractionFromProposalMutation
>;
export type DeleteInteractionFromProposalMutationResult =
  Apollo.MutationResult<DeleteInteractionFromProposalMutation>;
export type DeleteInteractionFromProposalMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteInteractionFromProposalMutation,
    DeleteInteractionFromProposalMutationVariables
  >;
export const DeleteInteractionResponseDocument = gql`
  mutation DeleteInteractionResponse($input: DeleteInteractionResponseInput!) {
    deleteInteractionResponse(input: $input) {
      _id
      proposalId
    }
  }
`;
export type DeleteInteractionResponseMutationFn = Apollo.MutationFunction<
  DeleteInteractionResponseMutation,
  DeleteInteractionResponseMutationVariables
>;

/**
 * __useDeleteInteractionResponseMutation__
 *
 * To run a mutation, you first call `useDeleteInteractionResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInteractionResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInteractionResponseMutation, { data, loading, error }] = useDeleteInteractionResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInteractionResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInteractionResponseMutation,
    DeleteInteractionResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInteractionResponseMutation,
    DeleteInteractionResponseMutationVariables
  >(DeleteInteractionResponseDocument, options);
}
export type DeleteInteractionResponseMutationHookResult = ReturnType<
  typeof useDeleteInteractionResponseMutation
>;
export type DeleteInteractionResponseMutationResult =
  Apollo.MutationResult<DeleteInteractionResponseMutation>;
export type DeleteInteractionResponseMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteInteractionResponseMutation,
    DeleteInteractionResponseMutationVariables
  >;
export const AdvanceProposalForParticipantDocument = gql`
  mutation AdvanceProposalForParticipant($_id: String!, $input: AdvanceInput!) {
    advanceProposalForUser(_id: $_id, input: $input) {
      ...Proposal
      ...Draft
      ...Agreement
    }
  }
  ${ProposalFragmentDoc}
  ${DraftFragmentDoc}
  ${AgreementFragmentDoc}
`;
export type AdvanceProposalForParticipantMutationFn = Apollo.MutationFunction<
  AdvanceProposalForParticipantMutation,
  AdvanceProposalForParticipantMutationVariables
>;

/**
 * __useAdvanceProposalForParticipantMutation__
 *
 * To run a mutation, you first call `useAdvanceProposalForParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvanceProposalForParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advanceProposalForParticipantMutation, { data, loading, error }] = useAdvanceProposalForParticipantMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvanceProposalForParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdvanceProposalForParticipantMutation,
    AdvanceProposalForParticipantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdvanceProposalForParticipantMutation,
    AdvanceProposalForParticipantMutationVariables
  >(AdvanceProposalForParticipantDocument, options);
}
export type AdvanceProposalForParticipantMutationHookResult = ReturnType<
  typeof useAdvanceProposalForParticipantMutation
>;
export type AdvanceProposalForParticipantMutationResult =
  Apollo.MutationResult<AdvanceProposalForParticipantMutation>;
export type AdvanceProposalForParticipantMutationOptions =
  Apollo.BaseMutationOptions<
    AdvanceProposalForParticipantMutation,
    AdvanceProposalForParticipantMutationVariables
  >;
export const RemoveInteractionReactionDocument = gql`
  mutation RemoveInteractionReaction($input: UpdateInteractionReactionInput!) {
    removeInteractionReaction(input: $input) {
      ...Interaction
    }
  }
  ${InteractionFragmentDoc}
`;
export type RemoveInteractionReactionMutationFn = Apollo.MutationFunction<
  RemoveInteractionReactionMutation,
  RemoveInteractionReactionMutationVariables
>;

/**
 * __useRemoveInteractionReactionMutation__
 *
 * To run a mutation, you first call `useRemoveInteractionReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInteractionReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInteractionReactionMutation, { data, loading, error }] = useRemoveInteractionReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveInteractionReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveInteractionReactionMutation,
    RemoveInteractionReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveInteractionReactionMutation,
    RemoveInteractionReactionMutationVariables
  >(RemoveInteractionReactionDocument, options);
}
export type RemoveInteractionReactionMutationHookResult = ReturnType<
  typeof useRemoveInteractionReactionMutation
>;
export type RemoveInteractionReactionMutationResult =
  Apollo.MutationResult<RemoveInteractionReactionMutation>;
export type RemoveInteractionReactionMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveInteractionReactionMutation,
    RemoveInteractionReactionMutationVariables
  >;
export const RevertProposalToDraftDocument = gql`
  mutation RevertProposalToDraft($input: RevertProposalToDraftInput!) {
    revertProposalToDraft(input: $input) {
      _id
      version
    }
  }
`;
export type RevertProposalToDraftMutationFn = Apollo.MutationFunction<
  RevertProposalToDraftMutation,
  RevertProposalToDraftMutationVariables
>;

/**
 * __useRevertProposalToDraftMutation__
 *
 * To run a mutation, you first call `useRevertProposalToDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertProposalToDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertProposalToDraftMutation, { data, loading, error }] = useRevertProposalToDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevertProposalToDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevertProposalToDraftMutation,
    RevertProposalToDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevertProposalToDraftMutation,
    RevertProposalToDraftMutationVariables
  >(RevertProposalToDraftDocument, options);
}
export type RevertProposalToDraftMutationHookResult = ReturnType<
  typeof useRevertProposalToDraftMutation
>;
export type RevertProposalToDraftMutationResult =
  Apollo.MutationResult<RevertProposalToDraftMutation>;
export type RevertProposalToDraftMutationOptions = Apollo.BaseMutationOptions<
  RevertProposalToDraftMutation,
  RevertProposalToDraftMutationVariables
>;
export const SaveProposalEditsDocument = gql`
  mutation SaveProposalEdits($input: SaveProposalEditsInput!) {
    saveProposalEdits(input: $input) {
      failedIndexes
    }
  }
`;
export type SaveProposalEditsMutationFn = Apollo.MutationFunction<
  SaveProposalEditsMutation,
  SaveProposalEditsMutationVariables
>;

/**
 * __useSaveProposalEditsMutation__
 *
 * To run a mutation, you first call `useSaveProposalEditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProposalEditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProposalEditsMutation, { data, loading, error }] = useSaveProposalEditsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveProposalEditsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveProposalEditsMutation,
    SaveProposalEditsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveProposalEditsMutation,
    SaveProposalEditsMutationVariables
  >(SaveProposalEditsDocument, options);
}
export type SaveProposalEditsMutationHookResult = ReturnType<
  typeof useSaveProposalEditsMutation
>;
export type SaveProposalEditsMutationResult =
  Apollo.MutationResult<SaveProposalEditsMutation>;
export type SaveProposalEditsMutationOptions = Apollo.BaseMutationOptions<
  SaveProposalEditsMutation,
  SaveProposalEditsMutationVariables
>;
export const UndoParticipantFinishedDocument = gql`
  mutation UndoParticipantFinished($input: UndoParticipantFinishedInput!) {
    undoParticipantFinished(input: $input) {
      ...Proposal
    }
  }
  ${ProposalFragmentDoc}
`;
export type UndoParticipantFinishedMutationFn = Apollo.MutationFunction<
  UndoParticipantFinishedMutation,
  UndoParticipantFinishedMutationVariables
>;

/**
 * __useUndoParticipantFinishedMutation__
 *
 * To run a mutation, you first call `useUndoParticipantFinishedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoParticipantFinishedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoParticipantFinishedMutation, { data, loading, error }] = useUndoParticipantFinishedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUndoParticipantFinishedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UndoParticipantFinishedMutation,
    UndoParticipantFinishedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UndoParticipantFinishedMutation,
    UndoParticipantFinishedMutationVariables
  >(UndoParticipantFinishedDocument, options);
}
export type UndoParticipantFinishedMutationHookResult = ReturnType<
  typeof useUndoParticipantFinishedMutation
>;
export type UndoParticipantFinishedMutationResult =
  Apollo.MutationResult<UndoParticipantFinishedMutation>;
export type UndoParticipantFinishedMutationOptions = Apollo.BaseMutationOptions<
  UndoParticipantFinishedMutation,
  UndoParticipantFinishedMutationVariables
>;
export const UpdateInteractionDocument = gql`
  mutation UpdateInteraction($input: UpdateInteractionInput!) {
    updateInteraction(input: $input) {
      ...Interaction
    }
  }
  ${InteractionFragmentDoc}
`;
export type UpdateInteractionMutationFn = Apollo.MutationFunction<
  UpdateInteractionMutation,
  UpdateInteractionMutationVariables
>;

/**
 * __useUpdateInteractionMutation__
 *
 * To run a mutation, you first call `useUpdateInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInteractionMutation, { data, loading, error }] = useUpdateInteractionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInteractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInteractionMutation,
    UpdateInteractionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInteractionMutation,
    UpdateInteractionMutationVariables
  >(UpdateInteractionDocument, options);
}
export type UpdateInteractionMutationHookResult = ReturnType<
  typeof useUpdateInteractionMutation
>;
export type UpdateInteractionMutationResult =
  Apollo.MutationResult<UpdateInteractionMutation>;
export type UpdateInteractionMutationOptions = Apollo.BaseMutationOptions<
  UpdateInteractionMutation,
  UpdateInteractionMutationVariables
>;
export const UpdateInteractionAcknowledgementDocument = gql`
  mutation UpdateInteractionAcknowledgement(
    $input: UpdateInteractionAcknowledgementInput!
  ) {
    updateInteractionAcknowledgement(input: $input) {
      _id
      acknowledgedByProposer
    }
  }
`;
export type UpdateInteractionAcknowledgementMutationFn =
  Apollo.MutationFunction<
    UpdateInteractionAcknowledgementMutation,
    UpdateInteractionAcknowledgementMutationVariables
  >;

/**
 * __useUpdateInteractionAcknowledgementMutation__
 *
 * To run a mutation, you first call `useUpdateInteractionAcknowledgementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInteractionAcknowledgementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInteractionAcknowledgementMutation, { data, loading, error }] = useUpdateInteractionAcknowledgementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInteractionAcknowledgementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInteractionAcknowledgementMutation,
    UpdateInteractionAcknowledgementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInteractionAcknowledgementMutation,
    UpdateInteractionAcknowledgementMutationVariables
  >(UpdateInteractionAcknowledgementDocument, options);
}
export type UpdateInteractionAcknowledgementMutationHookResult = ReturnType<
  typeof useUpdateInteractionAcknowledgementMutation
>;
export type UpdateInteractionAcknowledgementMutationResult =
  Apollo.MutationResult<UpdateInteractionAcknowledgementMutation>;
export type UpdateInteractionAcknowledgementMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateInteractionAcknowledgementMutation,
    UpdateInteractionAcknowledgementMutationVariables
  >;
export const UpdateInteractionReactionDocument = gql`
  mutation UpdateInteractionReaction($input: UpdateInteractionReactionInput!) {
    updateInteractionReaction(input: $input) {
      ...InteractionReaction
    }
  }
  ${InteractionReactionFragmentDoc}
`;
export type UpdateInteractionReactionMutationFn = Apollo.MutationFunction<
  UpdateInteractionReactionMutation,
  UpdateInteractionReactionMutationVariables
>;

/**
 * __useUpdateInteractionReactionMutation__
 *
 * To run a mutation, you first call `useUpdateInteractionReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInteractionReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInteractionReactionMutation, { data, loading, error }] = useUpdateInteractionReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInteractionReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInteractionReactionMutation,
    UpdateInteractionReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInteractionReactionMutation,
    UpdateInteractionReactionMutationVariables
  >(UpdateInteractionReactionDocument, options);
}
export type UpdateInteractionReactionMutationHookResult = ReturnType<
  typeof useUpdateInteractionReactionMutation
>;
export type UpdateInteractionReactionMutationResult =
  Apollo.MutationResult<UpdateInteractionReactionMutation>;
export type UpdateInteractionReactionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateInteractionReactionMutation,
    UpdateInteractionReactionMutationVariables
  >;
export const UpdateProposalDocument = gql`
  mutation UpdateProposal($input: UpdateProposalInput!) {
    updateProposal(input: $input) {
      ...Proposal
    }
  }
  ${ProposalFragmentDoc}
`;
export type UpdateProposalMutationFn = Apollo.MutationFunction<
  UpdateProposalMutation,
  UpdateProposalMutationVariables
>;

/**
 * __useUpdateProposalMutation__
 *
 * To run a mutation, you first call `useUpdateProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposalMutation, { data, loading, error }] = useUpdateProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProposalMutation,
    UpdateProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProposalMutation,
    UpdateProposalMutationVariables
  >(UpdateProposalDocument, options);
}
export type UpdateProposalMutationHookResult = ReturnType<
  typeof useUpdateProposalMutation
>;
export type UpdateProposalMutationResult =
  Apollo.MutationResult<UpdateProposalMutation>;
export type UpdateProposalMutationOptions = Apollo.BaseMutationOptions<
  UpdateProposalMutation,
  UpdateProposalMutationVariables
>;
export const UpdateProposalPropertiesDocument = gql`
  mutation UpdateProposalProperties($input: UpdatePropertiesInput!) {
    updateProposalProperties(input: $input) {
      ...Proposal
    }
  }
  ${ProposalFragmentDoc}
`;
export type UpdateProposalPropertiesMutationFn = Apollo.MutationFunction<
  UpdateProposalPropertiesMutation,
  UpdateProposalPropertiesMutationVariables
>;

/**
 * __useUpdateProposalPropertiesMutation__
 *
 * To run a mutation, you first call `useUpdateProposalPropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposalPropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposalPropertiesMutation, { data, loading, error }] = useUpdateProposalPropertiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProposalPropertiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProposalPropertiesMutation,
    UpdateProposalPropertiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProposalPropertiesMutation,
    UpdateProposalPropertiesMutationVariables
  >(UpdateProposalPropertiesDocument, options);
}
export type UpdateProposalPropertiesMutationHookResult = ReturnType<
  typeof useUpdateProposalPropertiesMutation
>;
export type UpdateProposalPropertiesMutationResult =
  Apollo.MutationResult<UpdateProposalPropertiesMutation>;
export type UpdateProposalPropertiesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProposalPropertiesMutation,
    UpdateProposalPropertiesMutationVariables
  >;
export const RemoveMemberDocument = gql`
  mutation RemoveMember($input: RemoveMemberInput!) {
    removeMember(input: $input) {
      _id
      name
      allMembersGroup {
        _id
        members {
          ...FullMember
        }
      }
    }
  }
  ${FullMemberFragmentDoc}
`;
export type RemoveMemberMutationFn = Apollo.MutationFunction<
  RemoveMemberMutation,
  RemoveMemberMutationVariables
>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMemberMutation,
    RemoveMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMemberMutation,
    RemoveMemberMutationVariables
  >(RemoveMemberDocument, options);
}
export type RemoveMemberMutationHookResult = ReturnType<
  typeof useRemoveMemberMutation
>;
export type RemoveMemberMutationResult =
  Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<
  RemoveMemberMutation,
  RemoveMemberMutationVariables
>;
export const UpdateAuthenticatedUserDocument = gql`
  mutation updateAuthenticatedUser(
    $displayName: String!
    $email: String!
    $avatar: String!
  ) {
    updateAuthenticatedUser(
      input: { displayName: $displayName, email: $email, avatar: $avatar }
    ) {
      avatar
      email
      displayName
      _id
      workspaces {
        _id
      }
    }
  }
`;
export type UpdateAuthenticatedUserMutationFn = Apollo.MutationFunction<
  UpdateAuthenticatedUserMutation,
  UpdateAuthenticatedUserMutationVariables
>;

/**
 * __useUpdateAuthenticatedUserMutation__
 *
 * To run a mutation, you first call `useUpdateAuthenticatedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthenticatedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthenticatedUserMutation, { data, loading, error }] = useUpdateAuthenticatedUserMutation({
 *   variables: {
 *      displayName: // value for 'displayName'
 *      email: // value for 'email'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateAuthenticatedUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAuthenticatedUserMutation,
    UpdateAuthenticatedUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAuthenticatedUserMutation,
    UpdateAuthenticatedUserMutationVariables
  >(UpdateAuthenticatedUserDocument, options);
}
export type UpdateAuthenticatedUserMutationHookResult = ReturnType<
  typeof useUpdateAuthenticatedUserMutation
>;
export type UpdateAuthenticatedUserMutationResult =
  Apollo.MutationResult<UpdateAuthenticatedUserMutation>;
export type UpdateAuthenticatedUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateAuthenticatedUserMutation,
  UpdateAuthenticatedUserMutationVariables
>;
export const MarkFeatureAsSeenByUserDocument = gql`
  mutation MarkFeatureAsSeenByUser($featureName: String!) {
    markFeatureAsSeenByUser(featureName: $featureName) {
      ...UserWithSession
    }
  }
  ${UserWithSessionFragmentDoc}
`;
export type MarkFeatureAsSeenByUserMutationFn = Apollo.MutationFunction<
  MarkFeatureAsSeenByUserMutation,
  MarkFeatureAsSeenByUserMutationVariables
>;

/**
 * __useMarkFeatureAsSeenByUserMutation__
 *
 * To run a mutation, you first call `useMarkFeatureAsSeenByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFeatureAsSeenByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFeatureAsSeenByUserMutation, { data, loading, error }] = useMarkFeatureAsSeenByUserMutation({
 *   variables: {
 *      featureName: // value for 'featureName'
 *   },
 * });
 */
export function useMarkFeatureAsSeenByUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkFeatureAsSeenByUserMutation,
    MarkFeatureAsSeenByUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkFeatureAsSeenByUserMutation,
    MarkFeatureAsSeenByUserMutationVariables
  >(MarkFeatureAsSeenByUserDocument, options);
}
export type MarkFeatureAsSeenByUserMutationHookResult = ReturnType<
  typeof useMarkFeatureAsSeenByUserMutation
>;
export type MarkFeatureAsSeenByUserMutationResult =
  Apollo.MutationResult<MarkFeatureAsSeenByUserMutation>;
export type MarkFeatureAsSeenByUserMutationOptions = Apollo.BaseMutationOptions<
  MarkFeatureAsSeenByUserMutation,
  MarkFeatureAsSeenByUserMutationVariables
>;
export const UpdateUserPreferencesDocument = gql`
  mutation UpdateUserPreferences($preferences: UpdateUserPreferencesInput!) {
    updateUserPreferences(preferences: $preferences) {
      ...UserWithSession
    }
  }
  ${UserWithSessionFragmentDoc}
`;
export type UpdateUserPreferencesMutationFn = Apollo.MutationFunction<
  UpdateUserPreferencesMutation,
  UpdateUserPreferencesMutationVariables
>;

/**
 * __useUpdateUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPreferencesMutation, { data, loading, error }] = useUpdateUserPreferencesMutation({
 *   variables: {
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useUpdateUserPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPreferencesMutation,
    UpdateUserPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserPreferencesMutation,
    UpdateUserPreferencesMutationVariables
  >(UpdateUserPreferencesDocument, options);
}
export type UpdateUserPreferencesMutationHookResult = ReturnType<
  typeof useUpdateUserPreferencesMutation
>;
export type UpdateUserPreferencesMutationResult =
  Apollo.MutationResult<UpdateUserPreferencesMutation>;
export type UpdateUserPreferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPreferencesMutation,
  UpdateUserPreferencesMutationVariables
>;
export const UpdateAuthenticatedUserNotificationPreferenceDocument = gql`
  mutation updateAuthenticatedUserNotificationPreference(
    $input: UpdatedAuthenticatedUserNotificationsInput!
  ) {
    updateAuthenticatedUserNotificationPreference(input: $input) {
      _id
      notificationPreferences {
        type
        enabled
        workspace {
          _id
        }
      }
    }
  }
`;
export type UpdateAuthenticatedUserNotificationPreferenceMutationFn =
  Apollo.MutationFunction<
    UpdateAuthenticatedUserNotificationPreferenceMutation,
    UpdateAuthenticatedUserNotificationPreferenceMutationVariables
  >;

/**
 * __useUpdateAuthenticatedUserNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateAuthenticatedUserNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthenticatedUserNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthenticatedUserNotificationPreferenceMutation, { data, loading, error }] = useUpdateAuthenticatedUserNotificationPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAuthenticatedUserNotificationPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAuthenticatedUserNotificationPreferenceMutation,
    UpdateAuthenticatedUserNotificationPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAuthenticatedUserNotificationPreferenceMutation,
    UpdateAuthenticatedUserNotificationPreferenceMutationVariables
  >(UpdateAuthenticatedUserNotificationPreferenceDocument, options);
}
export type UpdateAuthenticatedUserNotificationPreferenceMutationHookResult =
  ReturnType<typeof useUpdateAuthenticatedUserNotificationPreferenceMutation>;
export type UpdateAuthenticatedUserNotificationPreferenceMutationResult =
  Apollo.MutationResult<UpdateAuthenticatedUserNotificationPreferenceMutation>;
export type UpdateAuthenticatedUserNotificationPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAuthenticatedUserNotificationPreferenceMutation,
    UpdateAuthenticatedUserNotificationPreferenceMutationVariables
  >;
export const InviteMembersDocument = gql`
  mutation InviteMembers($input: InviteMembersToWorkspaceInput!) {
    inviteMembersToWorkspace(input: $input) {
      _id
      name
      invitedEmails {
        email
        workspaceGuest
      }
    }
  }
`;
export type InviteMembersMutationFn = Apollo.MutationFunction<
  InviteMembersMutation,
  InviteMembersMutationVariables
>;

/**
 * __useInviteMembersMutation__
 *
 * To run a mutation, you first call `useInviteMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMembersMutation, { data, loading, error }] = useInviteMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteMembersMutation,
    InviteMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteMembersMutation,
    InviteMembersMutationVariables
  >(InviteMembersDocument, options);
}
export type InviteMembersMutationHookResult = ReturnType<
  typeof useInviteMembersMutation
>;
export type InviteMembersMutationResult =
  Apollo.MutationResult<InviteMembersMutation>;
export type InviteMembersMutationOptions = Apollo.BaseMutationOptions<
  InviteMembersMutation,
  InviteMembersMutationVariables
>;
export const SetMembersBillingAdminStatusDocument = gql`
  mutation SetMembersBillingAdminStatus(
    $input: SetMembersBillingAdminStatusInput!
  ) {
    setMembersBillingAdminStatus(input: $input) {
      ...Workspace
    }
  }
  ${WorkspaceFragmentDoc}
`;
export type SetMembersBillingAdminStatusMutationFn = Apollo.MutationFunction<
  SetMembersBillingAdminStatusMutation,
  SetMembersBillingAdminStatusMutationVariables
>;

/**
 * __useSetMembersBillingAdminStatusMutation__
 *
 * To run a mutation, you first call `useSetMembersBillingAdminStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMembersBillingAdminStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMembersBillingAdminStatusMutation, { data, loading, error }] = useSetMembersBillingAdminStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMembersBillingAdminStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetMembersBillingAdminStatusMutation,
    SetMembersBillingAdminStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetMembersBillingAdminStatusMutation,
    SetMembersBillingAdminStatusMutationVariables
  >(SetMembersBillingAdminStatusDocument, options);
}
export type SetMembersBillingAdminStatusMutationHookResult = ReturnType<
  typeof useSetMembersBillingAdminStatusMutation
>;
export type SetMembersBillingAdminStatusMutationResult =
  Apollo.MutationResult<SetMembersBillingAdminStatusMutation>;
export type SetMembersBillingAdminStatusMutationOptions =
  Apollo.BaseMutationOptions<
    SetMembersBillingAdminStatusMutation,
    SetMembersBillingAdminStatusMutationVariables
  >;
export const GetAllPromptsDocument = gql`
  query GetAllPrompts {
    getAllPrompts {
      ...Prompt
      hasResponses
      tags
      disabled
      priorityOrder
    }
  }
  ${PromptFragmentDoc}
`;

/**
 * __useGetAllPromptsQuery__
 *
 * To run a query within a React component, call `useGetAllPromptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPromptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPromptsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPromptsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllPromptsQuery,
    GetAllPromptsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPromptsQuery, GetAllPromptsQueryVariables>(
    GetAllPromptsDocument,
    options
  );
}
export function useGetAllPromptsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPromptsQuery,
    GetAllPromptsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPromptsQuery, GetAllPromptsQueryVariables>(
    GetAllPromptsDocument,
    options
  );
}
export type GetAllPromptsQueryHookResult = ReturnType<
  typeof useGetAllPromptsQuery
>;
export type GetAllPromptsLazyQueryHookResult = ReturnType<
  typeof useGetAllPromptsLazyQuery
>;
export type GetAllPromptsQueryResult = Apollo.QueryResult<
  GetAllPromptsQuery,
  GetAllPromptsQueryVariables
>;
export const AdminOverviewDocument = gql`
  query AdminOverview {
    adminWorkspaces {
      _id
      name
      agreementCounts {
        active
        proposal
        draft
      }
      allMembersGroup {
        _id
        members {
          ...FullMember
        }
      }
    }
  }
  ${FullMemberFragmentDoc}
`;

/**
 * __useAdminOverviewQuery__
 *
 * To run a query within a React component, call `useAdminOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminOverviewQuery,
    AdminOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminOverviewQuery, AdminOverviewQueryVariables>(
    AdminOverviewDocument,
    options
  );
}
export function useAdminOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminOverviewQuery,
    AdminOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminOverviewQuery, AdminOverviewQueryVariables>(
    AdminOverviewDocument,
    options
  );
}
export type AdminOverviewQueryHookResult = ReturnType<
  typeof useAdminOverviewQuery
>;
export type AdminOverviewLazyQueryHookResult = ReturnType<
  typeof useAdminOverviewLazyQuery
>;
export type AdminOverviewQueryResult = Apollo.QueryResult<
  AdminOverviewQuery,
  AdminOverviewQueryVariables
>;
export const OopsDocument = gql`
  query Oops {
    oops {
      _id
    }
  }
`;

/**
 * __useOopsQuery__
 *
 * To run a query within a React component, call `useOopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOopsQuery(
  baseOptions?: Apollo.QueryHookOptions<OopsQuery, OopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OopsQuery, OopsQueryVariables>(OopsDocument, options);
}
export function useOopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OopsQuery, OopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OopsQuery, OopsQueryVariables>(
    OopsDocument,
    options
  );
}
export type OopsQueryHookResult = ReturnType<typeof useOopsQuery>;
export type OopsLazyQueryHookResult = ReturnType<typeof useOopsLazyQuery>;
export type OopsQueryResult = Apollo.QueryResult<OopsQuery, OopsQueryVariables>;
export const PreviewWorkspacesMergeDocument = gql`
  query PreviewWorkspacesMerge($params: PreviewWorkspacesMergeParams!) {
    previewWorkspacesMerge(params: $params) {
      ...WorkspaceMergeData
    }
  }
  ${WorkspaceMergeDataFragmentDoc}
`;

/**
 * __usePreviewWorkspacesMergeQuery__
 *
 * To run a query within a React component, call `usePreviewWorkspacesMergeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewWorkspacesMergeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewWorkspacesMergeQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePreviewWorkspacesMergeQuery(
  baseOptions: Apollo.QueryHookOptions<
    PreviewWorkspacesMergeQuery,
    PreviewWorkspacesMergeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PreviewWorkspacesMergeQuery,
    PreviewWorkspacesMergeQueryVariables
  >(PreviewWorkspacesMergeDocument, options);
}
export function usePreviewWorkspacesMergeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreviewWorkspacesMergeQuery,
    PreviewWorkspacesMergeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PreviewWorkspacesMergeQuery,
    PreviewWorkspacesMergeQueryVariables
  >(PreviewWorkspacesMergeDocument, options);
}
export type PreviewWorkspacesMergeQueryHookResult = ReturnType<
  typeof usePreviewWorkspacesMergeQuery
>;
export type PreviewWorkspacesMergeLazyQueryHookResult = ReturnType<
  typeof usePreviewWorkspacesMergeLazyQuery
>;
export type PreviewWorkspacesMergeQueryResult = Apollo.QueryResult<
  PreviewWorkspacesMergeQuery,
  PreviewWorkspacesMergeQueryVariables
>;
export const GetAgreementDocument = gql`
  query GetAgreement($params: AgreementParams!) {
    agreement(params: $params) {
      ...Agreement
      expirationDate
      userParticipationRole
    }
  }
  ${AgreementFragmentDoc}
`;

/**
 * __useGetAgreementQuery__
 *
 * To run a query within a React component, call `useGetAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetAgreementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgreementQuery,
    GetAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAgreementQuery, GetAgreementQueryVariables>(
    GetAgreementDocument,
    options
  );
}
export function useGetAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgreementQuery,
    GetAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAgreementQuery, GetAgreementQueryVariables>(
    GetAgreementDocument,
    options
  );
}
export type GetAgreementQueryHookResult = ReturnType<
  typeof useGetAgreementQuery
>;
export type GetAgreementLazyQueryHookResult = ReturnType<
  typeof useGetAgreementLazyQuery
>;
export type GetAgreementQueryResult = Apollo.QueryResult<
  GetAgreementQuery,
  GetAgreementQueryVariables
>;
export const GetAgreementsDocument = gql`
  query GetAgreements($agreementsParams: AgreementsParams!) {
    agreements(params: $agreementsParams) {
      ...AgreementSummary
      expirationDate
    }
  }
  ${AgreementSummaryFragmentDoc}
`;

/**
 * __useGetAgreementsQuery__
 *
 * To run a query within a React component, call `useGetAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementsQuery({
 *   variables: {
 *      agreementsParams: // value for 'agreementsParams'
 *   },
 * });
 */
export function useGetAgreementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgreementsQuery,
    GetAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAgreementsQuery, GetAgreementsQueryVariables>(
    GetAgreementsDocument,
    options
  );
}
export function useGetAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgreementsQuery,
    GetAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAgreementsQuery, GetAgreementsQueryVariables>(
    GetAgreementsDocument,
    options
  );
}
export type GetAgreementsQueryHookResult = ReturnType<
  typeof useGetAgreementsQuery
>;
export type GetAgreementsLazyQueryHookResult = ReturnType<
  typeof useGetAgreementsLazyQuery
>;
export type GetAgreementsQueryResult = Apollo.QueryResult<
  GetAgreementsQuery,
  GetAgreementsQueryVariables
>;
export const GetAllTemplatesDocument = gql`
  query GetAllTemplates {
    allTemplates {
      ...DraftSummary
    }
  }
  ${DraftSummaryFragmentDoc}
`;

/**
 * __useGetAllTemplatesQuery__
 *
 * To run a query within a React component, call `useGetAllTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTemplatesQuery,
    GetAllTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTemplatesQuery, GetAllTemplatesQueryVariables>(
    GetAllTemplatesDocument,
    options
  );
}
export function useGetAllTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTemplatesQuery,
    GetAllTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllTemplatesQuery,
    GetAllTemplatesQueryVariables
  >(GetAllTemplatesDocument, options);
}
export type GetAllTemplatesQueryHookResult = ReturnType<
  typeof useGetAllTemplatesQuery
>;
export type GetAllTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetAllTemplatesLazyQuery
>;
export type GetAllTemplatesQueryResult = Apollo.QueryResult<
  GetAllTemplatesQuery,
  GetAllTemplatesQueryVariables
>;
export const GetArchivedAgreementDocument = gql`
  query GetArchivedAgreement($params: ArchivedAgreementParams!) {
    archivedAgreement(params: $params) {
      ...Agreement
    }
  }
  ${AgreementFragmentDoc}
`;

/**
 * __useGetArchivedAgreementQuery__
 *
 * To run a query within a React component, call `useGetArchivedAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedAgreementQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetArchivedAgreementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetArchivedAgreementQuery,
    GetArchivedAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetArchivedAgreementQuery,
    GetArchivedAgreementQueryVariables
  >(GetArchivedAgreementDocument, options);
}
export function useGetArchivedAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArchivedAgreementQuery,
    GetArchivedAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetArchivedAgreementQuery,
    GetArchivedAgreementQueryVariables
  >(GetArchivedAgreementDocument, options);
}
export type GetArchivedAgreementQueryHookResult = ReturnType<
  typeof useGetArchivedAgreementQuery
>;
export type GetArchivedAgreementLazyQueryHookResult = ReturnType<
  typeof useGetArchivedAgreementLazyQuery
>;
export type GetArchivedAgreementQueryResult = Apollo.QueryResult<
  GetArchivedAgreementQuery,
  GetArchivedAgreementQueryVariables
>;
export const GetArchivedAgreementsDocument = gql`
  query GetArchivedAgreements($agreementsParams: AgreementsParams!) {
    archivedAgreements(params: $agreementsParams) {
      ...AgreementSummary
    }
  }
  ${AgreementSummaryFragmentDoc}
`;

/**
 * __useGetArchivedAgreementsQuery__
 *
 * To run a query within a React component, call `useGetArchivedAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedAgreementsQuery({
 *   variables: {
 *      agreementsParams: // value for 'agreementsParams'
 *   },
 * });
 */
export function useGetArchivedAgreementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetArchivedAgreementsQuery,
    GetArchivedAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetArchivedAgreementsQuery,
    GetArchivedAgreementsQueryVariables
  >(GetArchivedAgreementsDocument, options);
}
export function useGetArchivedAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArchivedAgreementsQuery,
    GetArchivedAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetArchivedAgreementsQuery,
    GetArchivedAgreementsQueryVariables
  >(GetArchivedAgreementsDocument, options);
}
export type GetArchivedAgreementsQueryHookResult = ReturnType<
  typeof useGetArchivedAgreementsQuery
>;
export type GetArchivedAgreementsLazyQueryHookResult = ReturnType<
  typeof useGetArchivedAgreementsLazyQuery
>;
export type GetArchivedAgreementsQueryResult = Apollo.QueryResult<
  GetArchivedAgreementsQuery,
  GetArchivedAgreementsQueryVariables
>;
export const GetDraftDocument = gql`
  query GetDraft($params: DraftParams!) {
    draft(params: $params) {
      ...Draft
    }
  }
  ${DraftFragmentDoc}
`;

/**
 * __useGetDraftQuery__
 *
 * To run a query within a React component, call `useGetDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetDraftQuery(
  baseOptions: Apollo.QueryHookOptions<GetDraftQuery, GetDraftQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDraftQuery, GetDraftQueryVariables>(
    GetDraftDocument,
    options
  );
}
export function useGetDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftQuery,
    GetDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDraftQuery, GetDraftQueryVariables>(
    GetDraftDocument,
    options
  );
}
export type GetDraftQueryHookResult = ReturnType<typeof useGetDraftQuery>;
export type GetDraftLazyQueryHookResult = ReturnType<
  typeof useGetDraftLazyQuery
>;
export type GetDraftQueryResult = Apollo.QueryResult<
  GetDraftQuery,
  GetDraftQueryVariables
>;
export const GetDraftsDocument = gql`
  query GetDrafts($agreementsParams: AgreementsParams!) {
    drafts(params: $agreementsParams) {
      ...DraftSummary
    }
  }
  ${DraftSummaryFragmentDoc}
`;

/**
 * __useGetDraftsQuery__
 *
 * To run a query within a React component, call `useGetDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftsQuery({
 *   variables: {
 *      agreementsParams: // value for 'agreementsParams'
 *   },
 * });
 */
export function useGetDraftsQuery(
  baseOptions: Apollo.QueryHookOptions<GetDraftsQuery, GetDraftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDraftsQuery, GetDraftsQueryVariables>(
    GetDraftsDocument,
    options
  );
}
export function useGetDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftsQuery,
    GetDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDraftsQuery, GetDraftsQueryVariables>(
    GetDraftsDocument,
    options
  );
}
export type GetDraftsQueryHookResult = ReturnType<typeof useGetDraftsQuery>;
export type GetDraftsLazyQueryHookResult = ReturnType<
  typeof useGetDraftsLazyQuery
>;
export type GetDraftsQueryResult = Apollo.QueryResult<
  GetDraftsQuery,
  GetDraftsQueryVariables
>;
export const GetGroupAgreementsDocument = gql`
  query GetGroupAgreements($workspaceId: String!, $groupId: String!) {
    groupAgreements(workspaceId: $workspaceId, groupId: $groupId) {
      ...AgreementSummary
    }
  }
  ${AgreementSummaryFragmentDoc}
`;

/**
 * __useGetGroupAgreementsQuery__
 *
 * To run a query within a React component, call `useGetGroupAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupAgreementsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGroupAgreementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGroupAgreementsQuery,
    GetGroupAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGroupAgreementsQuery,
    GetGroupAgreementsQueryVariables
  >(GetGroupAgreementsDocument, options);
}
export function useGetGroupAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupAgreementsQuery,
    GetGroupAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGroupAgreementsQuery,
    GetGroupAgreementsQueryVariables
  >(GetGroupAgreementsDocument, options);
}
export type GetGroupAgreementsQueryHookResult = ReturnType<
  typeof useGetGroupAgreementsQuery
>;
export type GetGroupAgreementsLazyQueryHookResult = ReturnType<
  typeof useGetGroupAgreementsLazyQuery
>;
export type GetGroupAgreementsQueryResult = Apollo.QueryResult<
  GetGroupAgreementsQuery,
  GetGroupAgreementsQueryVariables
>;
export const GetGroupProposalsDocument = gql`
  query GetGroupProposals($workspaceId: String!, $groupId: String!) {
    groupProposals(workspaceId: $workspaceId, groupId: $groupId) {
      ...ProposalSummary
    }
  }
  ${ProposalSummaryFragmentDoc}
`;

/**
 * __useGetGroupProposalsQuery__
 *
 * To run a query within a React component, call `useGetGroupProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupProposalsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGroupProposalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGroupProposalsQuery,
    GetGroupProposalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGroupProposalsQuery,
    GetGroupProposalsQueryVariables
  >(GetGroupProposalsDocument, options);
}
export function useGetGroupProposalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupProposalsQuery,
    GetGroupProposalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGroupProposalsQuery,
    GetGroupProposalsQueryVariables
  >(GetGroupProposalsDocument, options);
}
export type GetGroupProposalsQueryHookResult = ReturnType<
  typeof useGetGroupProposalsQuery
>;
export type GetGroupProposalsLazyQueryHookResult = ReturnType<
  typeof useGetGroupProposalsLazyQuery
>;
export type GetGroupProposalsQueryResult = Apollo.QueryResult<
  GetGroupProposalsQuery,
  GetGroupProposalsQueryVariables
>;
export const GetProposalDocument = gql`
  query GetProposal($params: AgreementParams!) {
    proposal(params: $params) {
      ...Proposal
    }
  }
  ${ProposalFragmentDoc}
`;

/**
 * __useGetProposalQuery__
 *
 * To run a query within a React component, call `useGetProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetProposalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProposalQuery,
    GetProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProposalQuery, GetProposalQueryVariables>(
    GetProposalDocument,
    options
  );
}
export function useGetProposalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProposalQuery,
    GetProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProposalQuery, GetProposalQueryVariables>(
    GetProposalDocument,
    options
  );
}
export type GetProposalQueryHookResult = ReturnType<typeof useGetProposalQuery>;
export type GetProposalLazyQueryHookResult = ReturnType<
  typeof useGetProposalLazyQuery
>;
export type GetProposalQueryResult = Apollo.QueryResult<
  GetProposalQuery,
  GetProposalQueryVariables
>;
export const GetProposalEditsDocument = gql`
  query GetProposalEdits($params: ProposalEditsForAgreementParams!) {
    proposalEditsForAgreement(params: $params) {
      authoredAt
      _id
      ulid
      operation
      propertyFor
      round
      sequence
      finalizeCycle
    }
  }
`;

/**
 * __useGetProposalEditsQuery__
 *
 * To run a query within a React component, call `useGetProposalEditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalEditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalEditsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetProposalEditsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProposalEditsQuery,
    GetProposalEditsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProposalEditsQuery, GetProposalEditsQueryVariables>(
    GetProposalEditsDocument,
    options
  );
}
export function useGetProposalEditsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProposalEditsQuery,
    GetProposalEditsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProposalEditsQuery,
    GetProposalEditsQueryVariables
  >(GetProposalEditsDocument, options);
}
export type GetProposalEditsQueryHookResult = ReturnType<
  typeof useGetProposalEditsQuery
>;
export type GetProposalEditsLazyQueryHookResult = ReturnType<
  typeof useGetProposalEditsLazyQuery
>;
export type GetProposalEditsQueryResult = Apollo.QueryResult<
  GetProposalEditsQuery,
  GetProposalEditsQueryVariables
>;
export const GetProposalsDocument = gql`
  query GetProposals($agreementsParams: AgreementsParams!) {
    proposals(params: $agreementsParams) {
      ...ProposalSummary
    }
  }
  ${ProposalSummaryFragmentDoc}
`;

/**
 * __useGetProposalsQuery__
 *
 * To run a query within a React component, call `useGetProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalsQuery({
 *   variables: {
 *      agreementsParams: // value for 'agreementsParams'
 *   },
 * });
 */
export function useGetProposalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProposalsQuery,
    GetProposalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProposalsQuery, GetProposalsQueryVariables>(
    GetProposalsDocument,
    options
  );
}
export function useGetProposalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProposalsQuery,
    GetProposalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProposalsQuery, GetProposalsQueryVariables>(
    GetProposalsDocument,
    options
  );
}
export type GetProposalsQueryHookResult = ReturnType<
  typeof useGetProposalsQuery
>;
export type GetProposalsLazyQueryHookResult = ReturnType<
  typeof useGetProposalsLazyQuery
>;
export type GetProposalsQueryResult = Apollo.QueryResult<
  GetProposalsQuery,
  GetProposalsQueryVariables
>;
export const ViewAgreementDocument = gql`
  query ViewAgreement($params: AgreementParams!) {
    agreement(params: $params) {
      seen
    }
  }
`;

/**
 * __useViewAgreementQuery__
 *
 * To run a query within a React component, call `useViewAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewAgreementQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useViewAgreementQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewAgreementQuery,
    ViewAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewAgreementQuery, ViewAgreementQueryVariables>(
    ViewAgreementDocument,
    options
  );
}
export function useViewAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewAgreementQuery,
    ViewAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewAgreementQuery, ViewAgreementQueryVariables>(
    ViewAgreementDocument,
    options
  );
}
export type ViewAgreementQueryHookResult = ReturnType<
  typeof useViewAgreementQuery
>;
export type ViewAgreementLazyQueryHookResult = ReturnType<
  typeof useViewAgreementLazyQuery
>;
export type ViewAgreementQueryResult = Apollo.QueryResult<
  ViewAgreementQuery,
  ViewAgreementQueryVariables
>;
export const GetAllCollectionsDocument = gql`
  query GetAllCollections {
    allCollections {
      ...PopulatedCollection
    }
  }
  ${PopulatedCollectionFragmentDoc}
`;

/**
 * __useGetAllCollectionsQuery__
 *
 * To run a query within a React component, call `useGetAllCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCollectionsQuery,
    GetAllCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCollectionsQuery,
    GetAllCollectionsQueryVariables
  >(GetAllCollectionsDocument, options);
}
export function useGetAllCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCollectionsQuery,
    GetAllCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCollectionsQuery,
    GetAllCollectionsQueryVariables
  >(GetAllCollectionsDocument, options);
}
export type GetAllCollectionsQueryHookResult = ReturnType<
  typeof useGetAllCollectionsQuery
>;
export type GetAllCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetAllCollectionsLazyQuery
>;
export type GetAllCollectionsQueryResult = Apollo.QueryResult<
  GetAllCollectionsQuery,
  GetAllCollectionsQueryVariables
>;
export const GetPublicCollectionsDocument = gql`
  query GetPublicCollections {
    collections {
      ...PopulatedCollection
    }
  }
  ${PopulatedCollectionFragmentDoc}
`;

/**
 * __useGetPublicCollectionsQuery__
 *
 * To run a query within a React component, call `useGetPublicCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPublicCollectionsQuery,
    GetPublicCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPublicCollectionsQuery,
    GetPublicCollectionsQueryVariables
  >(GetPublicCollectionsDocument, options);
}
export function useGetPublicCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicCollectionsQuery,
    GetPublicCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPublicCollectionsQuery,
    GetPublicCollectionsQueryVariables
  >(GetPublicCollectionsDocument, options);
}
export type GetPublicCollectionsQueryHookResult = ReturnType<
  typeof useGetPublicCollectionsQuery
>;
export type GetPublicCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetPublicCollectionsLazyQuery
>;
export type GetPublicCollectionsQueryResult = Apollo.QueryResult<
  GetPublicCollectionsQuery,
  GetPublicCollectionsQueryVariables
>;
export const ExploreDocument = gql`
  query Explore($publicAgreementParams: PublicAgreementsParams) {
    collections {
      ...PopulatedCollection
    }
    templates {
      ...Template
    }
    publicAgreements(params: $publicAgreementParams) {
      ...PublicAgreementSummary
    }
    publicCreatorProfiles {
      ...CreatorProfile
    }
  }
  ${PopulatedCollectionFragmentDoc}
  ${TemplateFragmentDoc}
  ${PublicAgreementSummaryFragmentDoc}
  ${CreatorProfileFragmentDoc}
`;

/**
 * __useExploreQuery__
 *
 * To run a query within a React component, call `useExploreQuery` and pass it any options that fit your needs.
 * When your component renders, `useExploreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExploreQuery({
 *   variables: {
 *      publicAgreementParams: // value for 'publicAgreementParams'
 *   },
 * });
 */
export function useExploreQuery(
  baseOptions?: Apollo.QueryHookOptions<ExploreQuery, ExploreQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExploreQuery, ExploreQueryVariables>(
    ExploreDocument,
    options
  );
}
export function useExploreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExploreQuery, ExploreQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExploreQuery, ExploreQueryVariables>(
    ExploreDocument,
    options
  );
}
export type ExploreQueryHookResult = ReturnType<typeof useExploreQuery>;
export type ExploreLazyQueryHookResult = ReturnType<typeof useExploreLazyQuery>;
export type ExploreQueryResult = Apollo.QueryResult<
  ExploreQuery,
  ExploreQueryVariables
>;
export const GetGroupDocument = gql`
  query GetGroup($params: GetGroupParams!) {
    group(params: $params) {
      ...PopulatedGroup
    }
  }
  ${PopulatedGroupFragmentDoc}
`;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGroupQuery(
  baseOptions: Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    options
  );
}
export function useGetGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupQuery,
    GetGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    options
  );
}
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<
  typeof useGetGroupLazyQuery
>;
export type GetGroupQueryResult = Apollo.QueryResult<
  GetGroupQuery,
  GetGroupQueryVariables
>;
export const GetGroupAgreementCountsDocument = gql`
  query GetGroupAgreementCounts(
    $params: GetGroupProposalAndAgreementCountParams!
  ) {
    groupProposalAndAgreementCount(params: $params) {
      activeCount
      proposalCount
    }
  }
`;

/**
 * __useGetGroupAgreementCountsQuery__
 *
 * To run a query within a React component, call `useGetGroupAgreementCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupAgreementCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupAgreementCountsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGroupAgreementCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGroupAgreementCountsQuery,
    GetGroupAgreementCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGroupAgreementCountsQuery,
    GetGroupAgreementCountsQueryVariables
  >(GetGroupAgreementCountsDocument, options);
}
export function useGetGroupAgreementCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupAgreementCountsQuery,
    GetGroupAgreementCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGroupAgreementCountsQuery,
    GetGroupAgreementCountsQueryVariables
  >(GetGroupAgreementCountsDocument, options);
}
export type GetGroupAgreementCountsQueryHookResult = ReturnType<
  typeof useGetGroupAgreementCountsQuery
>;
export type GetGroupAgreementCountsLazyQueryHookResult = ReturnType<
  typeof useGetGroupAgreementCountsLazyQuery
>;
export type GetGroupAgreementCountsQueryResult = Apollo.QueryResult<
  GetGroupAgreementCountsQuery,
  GetGroupAgreementCountsQueryVariables
>;
export const GetUserWorkspaceGroupsDocument = gql`
  query GetUserWorkspaceGroups($params: WorkspaceGroupsParams!) {
    userWorkspaceGroups(params: $params) {
      ...Group
    }
  }
  ${GroupFragmentDoc}
`;

/**
 * __useGetUserWorkspaceGroupsQuery__
 *
 * To run a query within a React component, call `useGetUserWorkspaceGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkspaceGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkspaceGroupsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetUserWorkspaceGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserWorkspaceGroupsQuery,
    GetUserWorkspaceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserWorkspaceGroupsQuery,
    GetUserWorkspaceGroupsQueryVariables
  >(GetUserWorkspaceGroupsDocument, options);
}
export function useGetUserWorkspaceGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserWorkspaceGroupsQuery,
    GetUserWorkspaceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserWorkspaceGroupsQuery,
    GetUserWorkspaceGroupsQueryVariables
  >(GetUserWorkspaceGroupsDocument, options);
}
export type GetUserWorkspaceGroupsQueryHookResult = ReturnType<
  typeof useGetUserWorkspaceGroupsQuery
>;
export type GetUserWorkspaceGroupsLazyQueryHookResult = ReturnType<
  typeof useGetUserWorkspaceGroupsLazyQuery
>;
export type GetUserWorkspaceGroupsQueryResult = Apollo.QueryResult<
  GetUserWorkspaceGroupsQuery,
  GetUserWorkspaceGroupsQueryVariables
>;
export const GetWorkspaceGroupsDocument = gql`
  query GetWorkspaceGroups($params: WorkspaceGroupsParams!) {
    workspaceGroups(params: $params) {
      ...Group
    }
  }
  ${GroupFragmentDoc}
`;

/**
 * __useGetWorkspaceGroupsQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceGroupsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetWorkspaceGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkspaceGroupsQuery,
    GetWorkspaceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkspaceGroupsQuery,
    GetWorkspaceGroupsQueryVariables
  >(GetWorkspaceGroupsDocument, options);
}
export function useGetWorkspaceGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkspaceGroupsQuery,
    GetWorkspaceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkspaceGroupsQuery,
    GetWorkspaceGroupsQueryVariables
  >(GetWorkspaceGroupsDocument, options);
}
export type GetWorkspaceGroupsQueryHookResult = ReturnType<
  typeof useGetWorkspaceGroupsQuery
>;
export type GetWorkspaceGroupsLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceGroupsLazyQuery
>;
export type GetWorkspaceGroupsQueryResult = Apollo.QueryResult<
  GetWorkspaceGroupsQuery,
  GetWorkspaceGroupsQueryVariables
>;
export const GetAllWorkspaceGroupsDocument = gql`
  query GetAllWorkspaceGroups($params: WorkspaceGroupsParams!) {
    allWorkspaceGroups(params: $params) {
      ...Group
    }
  }
  ${GroupFragmentDoc}
`;

/**
 * __useGetAllWorkspaceGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllWorkspaceGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllWorkspaceGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllWorkspaceGroupsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetAllWorkspaceGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllWorkspaceGroupsQuery,
    GetAllWorkspaceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllWorkspaceGroupsQuery,
    GetAllWorkspaceGroupsQueryVariables
  >(GetAllWorkspaceGroupsDocument, options);
}
export function useGetAllWorkspaceGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllWorkspaceGroupsQuery,
    GetAllWorkspaceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllWorkspaceGroupsQuery,
    GetAllWorkspaceGroupsQueryVariables
  >(GetAllWorkspaceGroupsDocument, options);
}
export type GetAllWorkspaceGroupsQueryHookResult = ReturnType<
  typeof useGetAllWorkspaceGroupsQuery
>;
export type GetAllWorkspaceGroupsLazyQueryHookResult = ReturnType<
  typeof useGetAllWorkspaceGroupsLazyQuery
>;
export type GetAllWorkspaceGroupsQueryResult = Apollo.QueryResult<
  GetAllWorkspaceGroupsQuery,
  GetAllWorkspaceGroupsQueryVariables
>;
export const GetProposalLinkInvitationDocument = gql`
  query GetProposalLinkInvitation($params: GetProposalLinkInvitationParams!) {
    proposalLinkInvitation(params: $params) {
      linkId
      proposer
      proposalTitle
      workspaceName
      enabled
    }
  }
`;

/**
 * __useGetProposalLinkInvitationQuery__
 *
 * To run a query within a React component, call `useGetProposalLinkInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalLinkInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalLinkInvitationQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetProposalLinkInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProposalLinkInvitationQuery,
    GetProposalLinkInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProposalLinkInvitationQuery,
    GetProposalLinkInvitationQueryVariables
  >(GetProposalLinkInvitationDocument, options);
}
export function useGetProposalLinkInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProposalLinkInvitationQuery,
    GetProposalLinkInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProposalLinkInvitationQuery,
    GetProposalLinkInvitationQueryVariables
  >(GetProposalLinkInvitationDocument, options);
}
export type GetProposalLinkInvitationQueryHookResult = ReturnType<
  typeof useGetProposalLinkInvitationQuery
>;
export type GetProposalLinkInvitationLazyQueryHookResult = ReturnType<
  typeof useGetProposalLinkInvitationLazyQuery
>;
export type GetProposalLinkInvitationQueryResult = Apollo.QueryResult<
  GetProposalLinkInvitationQuery,
  GetProposalLinkInvitationQueryVariables
>;
export const GetLinkInvitationDocument = gql`
  query GetLinkInvitation($params: GetLinkInvitationParams!) {
    linkInvitation(params: $params) {
      linkId
      expires
      workspace {
        _id
        name
      }
    }
  }
`;

/**
 * __useGetLinkInvitationQuery__
 *
 * To run a query within a React component, call `useGetLinkInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkInvitationQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetLinkInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLinkInvitationQuery,
    GetLinkInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLinkInvitationQuery,
    GetLinkInvitationQueryVariables
  >(GetLinkInvitationDocument, options);
}
export function useGetLinkInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLinkInvitationQuery,
    GetLinkInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLinkInvitationQuery,
    GetLinkInvitationQueryVariables
  >(GetLinkInvitationDocument, options);
}
export type GetLinkInvitationQueryHookResult = ReturnType<
  typeof useGetLinkInvitationQuery
>;
export type GetLinkInvitationLazyQueryHookResult = ReturnType<
  typeof useGetLinkInvitationLazyQuery
>;
export type GetLinkInvitationQueryResult = Apollo.QueryResult<
  GetLinkInvitationQuery,
  GetLinkInvitationQueryVariables
>;
export const GetNotificationsDocument = gql`
  query GetNotifications($params: NotificationsParams!) {
    notifications(params: $params) {
      ...Notification
    }
  }
  ${NotificationFragmentDoc}
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options
  );
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >(GetNotificationsDocument, options);
}
export type GetNotificationsQueryHookResult = ReturnType<
  typeof useGetNotificationsQuery
>;
export type GetNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsLazyQuery
>;
export type GetNotificationsQueryResult = Apollo.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>;
export const GetTemplateDocument = gql`
  query GetTemplate($_id: String!) {
    template(_id: $_id) {
      ...PopulatedTemplate
    }
  }
  ${PopulatedTemplateFragmentDoc}
`;

/**
 * __useGetTemplateQuery__
 *
 * To run a query within a React component, call `useGetTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTemplateQuery,
    GetTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTemplateQuery, GetTemplateQueryVariables>(
    GetTemplateDocument,
    options
  );
}
export function useGetTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTemplateQuery,
    GetTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTemplateQuery, GetTemplateQueryVariables>(
    GetTemplateDocument,
    options
  );
}
export type GetTemplateQueryHookResult = ReturnType<typeof useGetTemplateQuery>;
export type GetTemplateLazyQueryHookResult = ReturnType<
  typeof useGetTemplateLazyQuery
>;
export type GetTemplateQueryResult = Apollo.QueryResult<
  GetTemplateQuery,
  GetTemplateQueryVariables
>;
export const GetTemplateDraftDocument = gql`
  query GetTemplateDraft($_id: String!) {
    template(_id: $_id) {
      ...Draft
    }
    collections {
      ...PopulatedCollection
    }
  }
  ${DraftFragmentDoc}
  ${PopulatedCollectionFragmentDoc}
`;

/**
 * __useGetTemplateDraftQuery__
 *
 * To run a query within a React component, call `useGetTemplateDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateDraftQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetTemplateDraftQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTemplateDraftQuery,
    GetTemplateDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTemplateDraftQuery, GetTemplateDraftQueryVariables>(
    GetTemplateDraftDocument,
    options
  );
}
export function useGetTemplateDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTemplateDraftQuery,
    GetTemplateDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTemplateDraftQuery,
    GetTemplateDraftQueryVariables
  >(GetTemplateDraftDocument, options);
}
export type GetTemplateDraftQueryHookResult = ReturnType<
  typeof useGetTemplateDraftQuery
>;
export type GetTemplateDraftLazyQueryHookResult = ReturnType<
  typeof useGetTemplateDraftLazyQuery
>;
export type GetTemplateDraftQueryResult = Apollo.QueryResult<
  GetTemplateDraftQuery,
  GetTemplateDraftQueryVariables
>;
export const GetWorkspaceInvitationLinkDocument = gql`
  query GetWorkspaceInvitationLink($params: WorkspaceInvitationLinkParams!) {
    workspaceInvitationLink(params: $params) {
      linkId
      expires
      workspace {
        _id
      }
    }
  }
`;

/**
 * __useGetWorkspaceInvitationLinkQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceInvitationLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceInvitationLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceInvitationLinkQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetWorkspaceInvitationLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkspaceInvitationLinkQuery,
    GetWorkspaceInvitationLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkspaceInvitationLinkQuery,
    GetWorkspaceInvitationLinkQueryVariables
  >(GetWorkspaceInvitationLinkDocument, options);
}
export function useGetWorkspaceInvitationLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkspaceInvitationLinkQuery,
    GetWorkspaceInvitationLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkspaceInvitationLinkQuery,
    GetWorkspaceInvitationLinkQueryVariables
  >(GetWorkspaceInvitationLinkDocument, options);
}
export type GetWorkspaceInvitationLinkQueryHookResult = ReturnType<
  typeof useGetWorkspaceInvitationLinkQuery
>;
export type GetWorkspaceInvitationLinkLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceInvitationLinkLazyQuery
>;
export type GetWorkspaceInvitationLinkQueryResult = Apollo.QueryResult<
  GetWorkspaceInvitationLinkQuery,
  GetWorkspaceInvitationLinkQueryVariables
>;
export const GetWorkspaceDocument = gql`
  query GetWorkspace($params: WorkspaceParams!) {
    workspace(params: $params) {
      ...Workspace
    }
  }
  ${WorkspaceFragmentDoc}
`;

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkspaceQuery,
    GetWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(
    GetWorkspaceDocument,
    options
  );
}
export function useGetWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkspaceQuery,
    GetWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(
    GetWorkspaceDocument,
    options
  );
}
export type GetWorkspaceQueryHookResult = ReturnType<
  typeof useGetWorkspaceQuery
>;
export type GetWorkspaceLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceLazyQuery
>;
export type GetWorkspaceQueryResult = Apollo.QueryResult<
  GetWorkspaceQuery,
  GetWorkspaceQueryVariables
>;

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: "UNION",
        name: "Activity",
        possibleTypes: [
          {
            name: "AgreementActivity",
          },
          {
            name: "ProposalActivity",
          },
        ],
      },
      {
        kind: "UNION",
        name: "DraftProposalAgreement",
        possibleTypes: [
          {
            name: "Draft",
          },
          {
            name: "Proposal",
          },
          {
            name: "Agreement",
          },
        ],
      },
      {
        kind: "UNION",
        name: "DraftOrProposalOrAgreement",
        possibleTypes: [
          {
            name: "Draft",
          },
          {
            name: "Proposal",
          },
          {
            name: "Agreement",
          },
        ],
      },
      {
        kind: "UNION",
        name: "ProposalOrAgreement",
        possibleTypes: [
          {
            name: "Proposal",
          },
          {
            name: "Agreement",
          },
        ],
      },
    ],
  },
};
export default result;
