import { useState } from "react";
import { css } from "styled-components";

import useInterval from "~/hooks/useInterval";
import { inflectWithCount } from "~/utils/common";
const inflectSeconds = inflectWithCount("second");

export const MaintenanceMode = () => {
  const [paused, setPaused] = useState(false);
  const countdownState = useState(
    process.env.NEXT_PUBLIC_IS_PRODUCTION ? 60 : 5
  );
  let countdown = countdownState[0];
  const setCountdown = countdownState[1];

  useInterval(() => {
    if (countdown === 0) {
      window.location.reload();
    } else if (!paused) {
      countdown--;
      setCountdown(countdown);
    }
  }, 1000);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100vw;
      `}
    >
      <div
        css={`
          background-color: #fff;
          border-radius: 4px;
          padding: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          border: 1px solid #ccc;
          max-width: 50rem;
          text-align: center;
          h1 {
            font-size: 1.5rem;
          }
        `}
      >
        <h1>
          🏗️{" "}
          {process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "scheduled"
            ? "Scheduled"
            : "Unscheduled"}{" "}
          Maintenance Underway👷‍♀️
        </h1>
        <p>
          We're sorry, but you can't use Murmur right now. We're performing some
          critical maintenance
          {process.env.NEXT_PUBLIC_MAINTENANCE_WINDOW ||
            " for the next 10 minutes or so"}
          . All in-flight proposals will remain paused until we are done. We
          appreciate your patience!
        </p>
        <p>
          <strong>
            This page will automatically refresh{" "}
            {countdown ? "in " + inflectSeconds(countdown) : "now"}.
          </strong>
        </p>
        {!process.env.NEXT_PUBLIC_IS_PRODUCTION && (
          <button onClick={() => setPaused(!paused)}>
            [Dev Mode] {paused ? "Resume countdown" : "Pause countdown"}
          </button>
        )}
        <p>
          Here's a poem to help pass the time:
          <br />
          <br />
          <em>
            Roses are red, <br />
            Violets are blue, <br />
            I'm sorry for the downtime, <br />
            But I'm thankful for you.
          </em>
        </p>
      </div>
    </div>
  );
};
