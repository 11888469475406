import { FC } from "react";
import styled from "styled-components";

import Logo from "~/assets/images/logo-with-stroke.svg";

const Container = styled.div`
  width: 100px;
  height: 100px;
`;

const AnimatedLogo = styled(Logo)`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.speech};
  & path {
    stroke-dasharray: 305;
    stroke-dashoffset: 305;
    animation: dash 5s linear infinite;
    animation-delay: 0;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const LoadingIndicator: FC = (props) => {
  return (
    <Container {...props}>
      <AnimatedLogo />
    </Container>
  );
};

export default LoadingIndicator;
