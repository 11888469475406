import type { TypedDocumentNode } from "@apollo/client";
import { gql } from "@apollo/client";

export const UPDATE_AUTHENTICATED_USER: TypedDocumentNode = gql`
  mutation updateAuthenticatedUser(
    $displayName: String!
    $email: String!
    $avatar: String!
  ) {
    updateAuthenticatedUser(
      input: { displayName: $displayName, email: $email, avatar: $avatar }
    ) {
      avatar
      email
      displayName
      _id
      workspaces {
        _id
      }
    }
  }
`;
