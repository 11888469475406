import { useEffect, useRef } from "react";

type IntervalFunction = () => unknown | void;

/**
 * This custom hook is designed to make setInterval safe and easy to work with.
 * Taken from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export default function useInterval(callback: IntervalFunction, delay: number) {
  const savedCallback = useRef<IntervalFunction | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}
