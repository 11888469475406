import { css } from "styled-components";

import Graphik_Bold_woff from "~/assets/fonts/Graphik-Bold-Web.woff";
import Graphik_Bold_woff2 from "~/assets/fonts/Graphik-Bold-Web.woff2";
import Graphik_BoldItalic_woff from "~/assets/fonts/Graphik-BoldItalic-Web.woff";
import Graphik_BoldItalic_woff2 from "~/assets/fonts/Graphik-BoldItalic-Web.woff2";
import Graphik_Medium_woff from "~/assets/fonts/Graphik-Medium-Web.woff";
import Graphik_Medium_woff2 from "~/assets/fonts/Graphik-Medium-Web.woff2";
import Graphik_MediumItalic_woff from "~/assets/fonts/Graphik-MediumItalic-Web.woff";
import Graphik_MediumItalic_woff2 from "~/assets/fonts/Graphik-MediumItalic-Web.woff2";
import Graphik_Regular_woff from "~/assets/fonts/Graphik-Regular-Web.woff";
import Graphik_Regular_woff2 from "~/assets/fonts/Graphik-Regular-Web.woff2";
import Graphik_RegularItalic_woff from "~/assets/fonts/Graphik-RegularItalic-Web.woff";
import Graphik_RegularItalic_woff2 from "~/assets/fonts/Graphik-RegularItalic-Web.woff2";
import Graphik_Semibold_woff from "~/assets/fonts/Graphik-Semibold-Web.woff";
import Graphik_Semibold_woff2 from "~/assets/fonts/Graphik-Semibold-Web.woff2";
import Graphik_SemiboldItalic_woff from "~/assets/fonts/Graphik-SemiboldItalic-Web.woff";
import Graphik_SemiboldItalic_woff2 from "~/assets/fonts/Graphik-SemiboldItalic-Web.woff2";

export const fonts = css`
  @font-face {
    font-family: "Graphik";
    src: url("${Graphik_Bold_woff2}") format("woff2"),
      url("${Graphik_Bold_woff}") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik";
    src: url("${Graphik_Semibold_woff2}") format("woff2"),
      url("${Graphik_Semibold_woff}") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik";
    src: url("${Graphik_SemiboldItalic_woff2}") format("woff2"),
      url("${Graphik_SemiboldItalic_woff}") format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik";
    src: url("${Graphik_Regular_woff2}") format("woff2"),
      url("${Graphik_Regular_woff}") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik";
    src: url("${Graphik_RegularItalic_woff2}") format("woff2"),
      url("${Graphik_RegularItalic_woff}") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik";
    src: url("${Graphik_BoldItalic_woff2}") format("woff2"),
      url("${Graphik_BoldItalic_woff}") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik";
    src: url("${Graphik_Medium_woff2}") format("woff2"),
      url("${Graphik_Medium_woff}") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik";
    src: url("${Graphik_MediumItalic_woff2}") format("woff2"),
      url("${Graphik_MediumItalic_woff}") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
`;
