import { ErrorResponse } from "@apollo/client/link/error";
import { ThemeProvider as Mui5ThemeProvider } from "@mui/material/styles";
import { useRouter } from "next/router";
import { posthog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { ReactNode, useCallback, useEffect } from "react";
import { ThemeProvider } from "styled-components";

import { SupportLink } from "~/components/SupportLink/SupportLink";
import { AnalyticsProvider } from "~/contexts/AnalyticsContext";
import { GlobalLoadingIndicatorProvider } from "~/contexts/GlobalLoadingIndicatorContext";
import { InfoMessagesProvider } from "~/contexts/InfoMessagesContext";
import { UserProvider } from "~/contexts/UserContext";
import GraphQLProvider from "~/graphql/Provider";
import { mui5Theme, themes } from "~/styles/themes";

const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_CLIENT_KEY || "";

/**
 * Let's keep the flying-V of providers in one location
 */
const GlobalProviders = ({ children }: { children?: ReactNode }) => {
  const onGraphQLError = useCallback((errorResponse: ErrorResponse) => {
    // eslint-disable-next-line no-console
    console.log(["GraphQL error", errorResponse]);
  }, []);

  const router = useRouter();

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_APP_POSTHOG_WRITE_KEY, {
      api_host: "https://app.posthog.com",
      loaded: (posthog) => {
        if (
          process.env.NODE_ENV === "development" &&
          !process.env.NEXT_PUBLIC_TRACK_ENABLED
        ) {
          posthog.opt_out_capturing();
        }
      },
    });

    // Track page views
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  });

  return (
    <Mui5ThemeProvider theme={mui5Theme}>
      <PostHogProvider client={posthog}>
        <ThemeProvider theme={themes.light}>
          <InfoMessagesProvider>
            <GraphQLProvider appOnError={onGraphQLError}>
              <UserProvider>
                <div
                  css={`
                    position: fixed;
                    bottom: 100px;
                    right: 2rem;
                    z-index: 10000;
                  `}
                >
                  <SupportLink />
                </div>
                <AnalyticsProvider apiKey={SEGMENT_WRITE_KEY}>
                  <GlobalLoadingIndicatorProvider>
                    {children}
                  </GlobalLoadingIndicatorProvider>
                </AnalyticsProvider>
              </UserProvider>
            </GraphQLProvider>
          </InfoMessagesProvider>
        </ThemeProvider>
      </PostHogProvider>
    </Mui5ThemeProvider>
  );
};

export default GlobalProviders;
