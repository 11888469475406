import { noop } from "lodash";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { css } from "styled-components";

import Snackbar from "~/components/Snackbar";

const InfoMessagesContext = createContext<{
  messages: { [key: string]: ReactNode };
  clearMessages(): void;
  pushMessage(key: string, message: ReactNode): void;
}>({
  messages: {},
  pushMessage: noop,
  clearMessages: noop,
});

export const InfoMessagesProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<{ [key: string]: ReactNode }>({});

  const clearMessages = useCallback(() => {
    setMessages({});
  }, [setMessages]);

  const pushMessage = useCallback(
    (key: string, message: ReactNode) => {
      setMessages((messages) => ({ ...messages, [key]: message }));
    },
    [setMessages]
  );

  return (
    <InfoMessagesContext.Provider
      value={{ messages, clearMessages, pushMessage }}
    >
      {children}
      <Snackbar
        open={!!Object.keys(messages).length}
        onClose={clearMessages}
        variant="info"
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        {Object.values(messages)}
      </Snackbar>
    </InfoMessagesContext.Provider>
  );
};

export const useInfoMessages = () => useContext(InfoMessagesContext);
