import { noop } from "lodash";
import { useCallback, useContext } from "react";
import { useEffect } from "react";
import { createContext, useState } from "react";

import { useApolloNetworkStatus } from "~/graphql/client";
import useInterval from "~/hooks/useInterval";

const GlobalLoadingIndicatorContext = createContext<{
  loading: boolean;
  setLoading(v: boolean): void;
}>({
  loading: true,
  setLoading: noop,
});

export const GlobalLoadingIndicatorProvider: React.FC = ({ children }) => {
  const [internalLoading] = useState<boolean>(false);
  const [displayLoading, setDisplayLoading] = useState<boolean>(false);
  const [showedLoadingAt, setShowedLoadingAt] = useState(0);
  const { numPendingQueries, numPendingMutations } = useApolloNetworkStatus();
  useInterval(() => {
    const timeShowing = Date.now() - showedLoadingAt;
    if (timeShowing > 1500) {
      setDisplayLoading(internalLoading || numPendingQueries > 0);
    }
  }, 100);

  useEffect(() => {
    if (numPendingQueries > 0 || numPendingMutations > 0) {
      setDisplayLoading(true);
      setShowedLoadingAt(Date.now());
    }
  }, [numPendingQueries, numPendingMutations, setDisplayLoading]);

  const setLoading = useCallback(
    (loading: boolean) => {
      setDisplayLoading(loading);
      setShowedLoadingAt(Date.now());
    },
    [setDisplayLoading, setShowedLoadingAt]
  );

  return (
    <GlobalLoadingIndicatorContext.Provider
      value={{ loading: displayLoading, setLoading }}
    >
      {children}
    </GlobalLoadingIndicatorContext.Provider>
  );
};

export const useGlobalLoadingIndicator = () =>
  useContext(GlobalLoadingIndicatorContext);
