import { css } from "styled-components";

import Emoji from "../Emoji";
import { Row } from "../Row";

export const VersionMismatchMessage = () => (
  <Row
    css={`
      align-items: center;
      gap: 0.5rem;
      padding-bottom: 0.125rem;
      p {
        margin: 0;
      }
    `}
  >
    <Emoji symbol="✨" />
    <p>
      A new version of the app is available. Please{" "}
      <a
        css={css`
          color: ${({ theme }) => theme.colors.white};
          font-weight: ${({ theme }) => theme.weights.medium};
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        `}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window.location.reload();
        }}
      >
        refresh the page
      </a>{" "}
      to update.
    </p>
  </Row>
);
