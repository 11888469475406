import { ApolloClient, ApolloProvider } from "@apollo/client";
import { ErrorResponse } from "@apollo/client/link/error";
import { FC, useCallback, useEffect, useState } from "react";

import { VersionMismatchMessage } from "~/components/VersionMismatchMessage";
import WaitRoom from "~/containers/Common/WaitRoom";
import { useInfoMessages } from "~/contexts/InfoMessagesContext";
import { setupClient } from "~/graphql/client";

const useClient = (appOnError: (errorResponse: ErrorResponse) => void) => {
  const [client, setClient] = useState<ApolloClient<unknown>>();
  const { pushMessage } = useInfoMessages();
  const appOnVersionMismatch = useCallback(() => {
    pushMessage("version-mismatch", <VersionMismatchMessage />);
  }, [pushMessage]);

  useEffect(() => {
    setupClient({ appOnError, appOnVersionMismatch }).then((client) =>
      setClient(client)
    );
  }, [appOnError, appOnVersionMismatch]);

  return client;
};

const GraphQLProvider: FC<{
  appOnError: (errorResponse: ErrorResponse) => void;
}> = ({ appOnError: onError, children }) => {
  const client = useClient(onError);

  if (!client) {
    return <WaitRoom />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
