import { gql } from "@apollo/client";

export const USER_FRAGMENT = gql`
  fragment User on User {
    _id
    email
    displayName
    avatar
    joined
    workspaceInvitations {
      _id
      workspace {
        name
        _id
      }
    }
    proposalInvitations {
      linkId
      workspace {
        name
        _id
      }
    }
    notificationPreferences {
      enabled
      type
      workspace {
        _id
      }
    }
    workspaces {
      _id
      name
      cohortId
    }
    guestOfWorkspaces {
      _id
      name
      cohortId
    }
  }
`;

export const USER_WITH_SESSION_FRAGMENT = gql`
  fragment UserWithSession on UserWithSession {
    _id
    email
    displayName
    avatar
    joined
    sessionExpiresAt
    workspaceInvitations {
      _id
      workspaceGuest
      workspace {
        name
        _id
      }
    }
    proposalInvitations {
      linkId
      workspaceGuest
      workspace {
        name
        _id
      }
    }
    notificationPreferences {
      enabled
      type
      workspace {
        _id
      }
    }
    preferences {
      lastUsedEmojiSkinTone
      dontShowWhatHappensWhenIProposeHelp
    }
    featuresSeen
    walletId
    workspaces {
      _id
      name
      cohortId
    }
    guestOfWorkspaces {
      _id
      name
      cohortId
    }
    checklists {
      workspaceId
      completedItems
    }
  }
`;
