export const captureException = (
  exception: Error,
  props?: Record<string, unknown>
) => {
  console.warn(`Captured Exception: ${exception}.
Context: ${JSON.stringify(props)}`);
  return "ok";
};

export const captureMessage = (
  message: string,
  props: Record<string, unknown>
) => {
  console.warn(`Captured Message: ${message}.
Context: ${JSON.stringify(props)}`);
  return "ok";
};
