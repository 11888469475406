import {
  ApolloClient,
  ApolloError,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { orderBy, uniqBy } from "lodash";
import { createNetworkStatusNotifier } from "react-apollo-network-status";

import { AppVersionHeader } from "~/shared/utils/staticConstants";
import { captureException } from "~/utils/captureMessage";
import { hasAuthenticationError } from "~/utils/Errors";

const notifier = createNetworkStatusNotifier();
export const { useApolloNetworkStatus } = notifier;

export const APOLLO_CACHE_PERSIST_KEY = "apollo-cache-persist";
const LocalVersion = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;

export async function setupClient({
  appOnError,
  appOnVersionMismatch,
}: {
  appOnError: (errorResponse: ErrorResponse) => void;
  appOnVersionMismatch: () => void;
}): Promise<ApolloClient<unknown>> {
  const cache = new InMemoryCache({
    typePolicies: {
      BillingSummary: {
        keyFields: ["workspaceId"],
      },
      Query: {
        fields: {
          getPromptResponsesFeed: {
            keyArgs: ["params", ["filterType", "workspaceId"]],
            merge(
              existing = { promptResponses: [], cursorLimit: "" },
              incoming
            ) {
              const promptResponses = orderBy(
                uniqBy(
                  [
                    ...(existing.promptResponses || []),
                    ...(incoming.promptResponses || []),
                  ],
                  "__ref" // __ref is a string with the pattern `${typename}:${ObjectID}`
                ),
                "__ref",
                "desc" // because the ID is a MongoDB ObjectID, this sorts by createdAt
              );

              return {
                promptResponses,
                cursorLimit: incoming.cursorLimit || existing.cursorLimit,
              };
            },
          },
        },
      },
    },
  });

  // this must run before instantiating ApolloClient so that queries run after the cache is synced
  await persistCache({
    key: APOLLO_CACHE_PERSIST_KEY,
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });

  const httpLink = createHttpLink({
    uri: `/api/graphql`,
    async fetch(uri, options) {
      if (options) {
        const result = await fetch(uri, options);

        if (!matchingVersion(result.headers.get(AppVersionHeader))) {
          appOnVersionMismatch();
        }
        return result;
      } else {
        const result = await fetch(uri);

        if (!matchingVersion(result.headers.get(AppVersionHeader))) {
          appOnVersionMismatch();
        }

        return result;
      }
    },
  });

  const errorLink = onError((errorResponse) => {
    appOnError(errorResponse);
    const { graphQLErrors, networkError } = errorResponse;
    graphQLErrors?.forEach(({ message, locations, path }) => {
      captureException(new Error(message), {
        tags: {
          path: JSON.stringify(path),
          operationName: errorResponse.operation.operationName,
        },
      });

      if (!process.env.NEXT_PUBLIC_IS_PRODUCTION) {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        );
      }
    });

    if (networkError) {
      captureException(networkError);
      console.error(`[Network error]: ${networkError}`);
    }

    if (
      hasAuthenticationError({
        error: new ApolloError({ graphQLErrors, networkError }),
      })
    ) {
      if (process.env.NEXT_PUBLIC_IS_PRODUCTION) {
        localStorage.clear();
        window.location.href = "/api/logout";
      } else {
        console.error("Authentication error", graphQLErrors, networkError);
        alert("Auth error " + JSON.stringify(graphQLErrors));
      }
    }
  });

  return new ApolloClient({
    cache,
    link: from([errorLink, notifier.link, httpLink]),
    connectToDevTools: process.env.NODE_ENV !== "production",
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
}

function matchingVersion(appVersion: string | null) {
  return !appVersion || !LocalVersion || appVersion === LocalVersion;
}
