// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};
export const alwaysTrue = () => true;
export const alwaysFalse = () => false;

/**
 * `inflect` is used to provide the correct singular or plural string:
 * 0 apples, 1 apple, 2 apples, etc.
 *
 * I have made this a higher order function with partial application so that an
 * inflection can easily be reused.
 *
 * Example:
 * ```
 * const dayInflection = inflect("day")
 *
 * <p>
 *   My birthday is in {daysTilMyBirthday} {dayInflection(daysTilMyBirthday)}. My friend's birthday
 *   is in {daysTilFriendsBirthday} {dayInflection(daysTilFriendsBirthday)}.
 * </p>
 * ```
 *
 * `dayInflection` has the `singular` and the default assigned `plural` values in
 * its closure. It gives us back a function that takes a quantity and returns
 * the right string, hence why we can reuse the `dayInflection` function over and
 * over. Read more about partial application here: https://kyleshevlin.com/just-enough-fp-partial-application
 */
export const inflect = (singular: string, plural = `${singular}s`) => (
  quantity: number
): string => (quantity === 1 ? singular : plural);

/**
 * `inflectWithCount` is like `inflect` but it also includes the count.
 * Example:
 *  inflect("day")(0) => "days" (no count)
 *  inflectWithCount("day")(1) => "1 day"
 *  inflectWithCount("day")(2) => "2 days"
 */
export const inflectWithCount = (singular: string, plural = `${singular}s`) => (
  quantity: number
): string => `${quantity} ${quantity === 1 ? singular : plural}`;

/**
 *
 */
export const listify = (
  items: Array<string>,
  {
    conjunction = "and",
    oxfordComma = true,
  }: { conjunction?: "and" | "or"; oxfordComma?: boolean } = {}
) => {
  const allButLastItem = items.slice(0, -1);
  const lastItem = items.slice(-1)[0];
  return `${
    allButLastItem.join(", ") +
    (allButLastItem.length > 1 ? (oxfordComma ? ", " : " ") : "")
  } ${conjunction} ${lastItem}`;
};

/**
 * sleep returns a Promise that resolves after at least `ms` milliseconds have elapsed
 */
export const sleep = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

export const groupBy = <K extends string, T>(
  array: Array<T>,
  groupFn: (item: T) => K
): Record<K, Array<T>> => {
  const dictionary: Record<K, Array<T>> = {} as Record<K, Array<T>>;

  array.forEach((item) => {
    const label = groupFn(item);
    if (dictionary[label]) {
      dictionary[label].push(item);
    } else {
      dictionary[label] = [item];
    }
  });
  return dictionary;
};
