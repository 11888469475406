export const dark = {
  abbey: "#444548",
  alto: "#DDDDDD",
  bokara: "#2A2623",
  frenchGray: "#BCBCBD",
  geyser: "#DFE6E8",
  shark: "#1E1F22",
  sharkMedium: "#303134",
  speech005: "#474CDE0D",
  speech50: "#8c8fea",
};
