import { isAfter } from "date-fns";
import { useRouter } from "next/router";
import { createContext, FC, useCallback, useContext } from "react";

import {
  UpdateUserPreferencesInput,
  useMarkFeatureAsSeenByUserMutation,
  useUpdateUserPreferencesMutation,
} from "~/graphql/generated";
import { useAuthenticatedUser } from "~/graphql/hooks/user/useAuthenticatedUser";
import { FeatureNamesType } from "~/server/modules/user/entities/FeatureNames";
import { noop } from "~/utils/common";

import type { Props, UserCtx } from "./types";

const UserContext = createContext<UserCtx>({
  user: {},
  authenticated: false,
  loading: false,
  logout: noop,
  setUserPreferences: async () => undefined,
  markFeatureAsSeen: async () => undefined,
});

export const UserProvider: FC<Props> = (props) => {
  const router = useRouter();
  const { data, loading } = useAuthenticatedUser({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const authenticated =
    !!data && isAfter(new Date(data.sessionExpiresAt), new Date());

  const [saveUserPreferences] = useUpdateUserPreferencesMutation();
  const setUserPreferences = useCallback(
    async (preferences: UpdateUserPreferencesInput) => {
      await saveUserPreferences({
        variables: { preferences },
      });
    },
    [saveUserPreferences]
  );

  const [saveFeaturesSeen] = useMarkFeatureAsSeenByUserMutation();
  const markFeatureAsSeen = useCallback(
    async (featureName: FeatureNamesType) => {
      await saveFeaturesSeen({
        variables: { featureName },
      });
    },
    [saveFeaturesSeen]
  );

  const logout = useCallback(async () => {
    router.push("/api/logout");
  }, [router]);

  return (
    <UserContext.Provider
      value={{
        user: data ?? {},
        authenticated,
        loading,
        logout,
        setUserPreferences,
        markFeatureAsSeen,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
